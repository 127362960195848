import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import '@/assets/styles/style.scss'
import '@/assets/js/scripts.js'

import TheLoader from './components/universal/TheLoader.vue'

createApp(App)
	.use(store)
	.use(router)
	.component("TheLoader", TheLoader)
	.mount("#app")
