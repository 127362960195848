<template>
	<div>
		
	</div>
</template>

<script>
export default {
	name: 'HomeDashboard',
	data(){
		return{

		}
	},
	methods:{

	},
}
</script>