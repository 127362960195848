<template>
	<nav class="nav">
		<router-link class="nav__link" :to="{name: 'Browse'}"><span class="material-icons nav__icon">sports_esports</span></router-link>
		<a @click="toggleMenu" class="nav__link"><span class="material-icons nav__icon">account_circle</span></a>
		<ul v-if="isAuthUser && isUserName" :class="{ 'show': menuOpen }" class="nav__dropdown">
			<li><router-link :to="{name: 'User', params: {  slug: isUserName }}">Profile</router-link></li>
			<li><router-link :to="{name: 'Library', params: {  slug: isUserName }}">My Library</router-link></li>
			<li><router-link :to="{name: 'Wishlist', params: {  slug: isUserName }}">My Wishlist</router-link></li>
			<li><router-link :to="{ name: 'UserSettings' }">Settings</router-link></li>
			<li><a @click="logout">Logout</a></li>
		</ul>
		<ul v-else :class="{ 'show': menuOpen }" class="nav__dropdown">
			<li><router-link :to="{ name: 'UserLogin' }">Login</router-link></li>
			<li><router-link :to="{ name: 'UserRegister' }">Register</router-link></li>
		</ul>

	</nav>
</template>

<script>

import HelperService from '@/services/helperService'
import UserService from '@/services/userService'

export default {
	name: 'TheNav',
	data(){
		return{
			authUser: null,
			
			menuOpen: false,
			username: false,
		}
	},
	computed:{
		isAuthUser(){
			return this.$store.state.user.isLoggedIn
		},

		isUserName(){
			return this.$store.state.user.slug
		}
	},
	async mounted(){
		// try{
		// 	this.authUser = await HelperService.getAuthorizedUser()
		// 	if(this.authUser){
		// 		this.$store.state.user.id = this.authUser.id
		// 		this.$store.state.user.slug = this.authUser.username
		// 		this.$store.state.user.isAuth = true
		// 		this.$store.state.user.isLoggedIn = true
		// 		console.log(this.$store.state.user)
		// 	}

		// } catch(err){
		// 	console.error(err)
		// }
	},
	methods:{
		async logout(){
			let response = await UserService.logout()
			window.location.reload()
			this.$router.replace('/')
		},

		toggleMenu(){
			var navdropdown = document.getElementsByClassName("nav__dropdown")

			if (navdropdown[0].classList.contains('nav__dropdown--show'))
				navdropdown[0].classList.remove('nav__dropdown--show')
			else 
				navdropdown[0].classList.add('nav__dropdown--show')

		}
	}

}
</script>