<template>
	<div>

	</div>
</template>

<script>

import HelperService from '@/services/helperService'

export default {
	name: 'UserProfile',
	data(){
		return{

		}
	},
	async mounted(){

		try{
			//this.user = await HelperService.getAuthorizedUser()
			console.log()
		} catch(err) {
			console.error(err)
		}
		if(this.user)
			this.$router.push(`/${this.user.slug}`)
		else
			 this.$router.push(`/login`)

		
	},
	methods:{

	},

}
</script>