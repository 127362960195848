<template>
	<div class="dashboard__stat-totals_box">
		<div>
			<div class="dashboard__stat-totals_title">{{ title }}</div>
			<div class="dashboard__stat-totals_number">{{ number }}</div>
			<div class="dashboard__stat-percent--bg"><div v-if="percentage" :style="'width:' + percentage" class="dashboard__stat-percent"></div></div>
			<div v-if="percentage" class="dashboard__stat-percent-text">{{percentage}}</div>
		</div>
	</div>
</template>

<script>
export default {
	name:'DashboardStatsBox',
	props: ['title','number','percentage'],
}
</script>