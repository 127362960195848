<template>
	<div class="list-view" style="opacity:0;">

		<QuickAddModalWindow :class="{'modal--show': showModal}" @toggleModal="emittoggleModal" :title="`Add to library`" :subtitle="`${quickAddGame.title}`" :game="quickAddGame" :platforms="quickAddPlatforms" confirmText="Add" cancelText="Cancel"  />
		<GameJournalModal :class="{'modal--show': showJournalModal}" @toggleJournalModal="toggleJournalModal" :title="`Add to library`" :subtitle="`${journalGame.title}`" :game="journalGame" :journal="journal" :event="event" :objIndex="objIndex" :gameJournalExists="gameJournalExists" :confirmText="journalConfirmText" cancelText="Cancel"  />

		<ReviewModal :class="{'modal--show': showReviewModal}" @toggleReviewModal="toggleReviewModal" :title="`Add review`" :subtitle="`${reviewGame.title}`" :game="reviewGame" :review="review" :event="event" :objIndex="objIndex" :gameReviewExists="gameReviewExists" :confirmText="reviewConfirmText" cancelText="Cancel"  />

		<transition-group name="gamecard" tag="div" appear mode="out-in">
			
			<div class="list-view__row" :key="game.id" v-for="(game, index) in gameObj">

				<div class="list-view__cover">
					<div class="list-view__no-cover" v-if="game.coverArt == ''"><router-link :to="{name: 'Game', params:{id: game.id, url: game.slug }}">No Cover</router-link></div>
					<router-link :to="{name: 'Game', params:{id: game.id, url: game.slug}}"><img v-if="game.coverArt != ''" :class="{ 'show': showcover }" class="list-view__image" :src="game.coverArt" alt=""></router-link>
				</div>
				<div class="list-view__item">
					<router-link v-if="game.title" class="list-view__title" :to="{name: 'Game', params:{id: game.id, url: game.slug}}">{{ game.title }}</router-link> 

					<!-- <div class="list-view__avg-rating" :title="`Avg Rating`" v-if="game.avg_rating > 0"><i class="fa-solid fa-star brand-color"></i> {{(game.avg_rating / 20).toFixed(1)}}</div> -->

					<div v-if="game.avg_rating > 0" class="list-view__game-reviews game__reviews">
						<div :title="(game.avg_rating / 20).toFixed(1) > 1 ? `${(game.avg_rating / 20).toFixed(1)} stars` : `${(game.avg_rating / 20).toFixed(1)} star`" class="Stars" :style="`--rating: ${(game.avg_rating / 20).toFixed(1) - 0.075};`"></div> <span class="game-reviews__count">({{game.rating_count.toLocaleString("en-US")}})</span> 
					</div>

					<!-- <div class="list-view__date">{{ formatDate(game.first_release_date) }}</div> -->
					<div title="Your play status" v-if="game.game_status" class="list-view__game-status">
						<span v-if="game.game_status === 'Playing'" class="playing">{{game.game_status}}</span>
						<span v-else-if="game.game_status === 'Played'" class="played">{{game.game_status}}</span>
						<span v-else-if="game.game_status === 'Backlogged'" class="backlogged">{{game.game_status}}</span>
						<span v-else-if="game.game_status === 'Quit'" class="quit">{{game.game_status}}</span>
						<span v-else-if="game.game_status === 'Paused'" class="paused">{{game.game_status}}</span>
						<span v-else-if="game.game_status === '100%'" class="one-hundred">100%</span>
					</div>
					<!-- <span v-if="game.game_status && game.journal_private" title="Journal Hidden" class="material-icons review-visible">visibility_off</span>  -->
					
					<div v-if="game.rating" :title="`Your rating`" class="list-view__library-rating"><i class="fa-solid fa-star brand-color"></i> {{game.rating}}</div> 
					<!-- <span v-if="game.rating && game.review_private" title="Review Hidden" class="material-icons review-visible">visibility_off</span>  -->
				</div>
				<div class="list-view__platforms">
					<div class="pill list-view__pill" :key="platform" v-for="platform in getPlatformNames(game.platforms)">
						{{ platform }}
					</div>
				</div>
				
				<div v-if="isAuthUser" class="list-view__buttons">
					<div v-if="user" class="game__ui list-view__ui">

						<button title="Wishlist" @click="wishlist($event, game)" class="game__wishlist-button list-view__wishlist-button list-view__button">
							<span v-if="game.wished" class="material-icons favorited">favorite</span>
							<span v-else class="material-icons">favorite_border</span>
						</button>

						<button title="Add" @click="addGame($event, game)" class="game__button game__add-button list-view__add-button list-view__button">
							<div class="button__loader-container"><div class="loader"></div></div>
							<div class="button__text"><span class="material-icons">playlist_add</span></div>
						</button>

						<button title="Quick Add" @click="quickAdd($event, game)" class="game__quick-add-button list-view__quick-add-button list-view__button">
							<div class="button__loader-container"><div class="loader"></div></div>
							<div class="button__text"><span class="material-icons">add</span></div>
						</button> 

						<button title="Journal" @click="journalEntry($event, game, index)" class="game__wishlist-button">
							<span v-if="game.game_status" class="material-icons favorited">sports_esports</span>
							<span v-else class="material-icons">sports_esports</span>
						</button>

						<button title="Review" @click="reviewEntry($event, game, index)" class="game__wishlist-button">
								<i v-if="game.rating" class="fa-solid fa-pen-to-square favorited"></i>
								<i v-else class="fa-solid fa-pen-to-square"></i>
						</button>

					</div>
				</div>
			</div>
		</transition-group>

	</div>
</template>

<script>

import HelperService from '@/services/helperService'
import LibraryService from '@/services/libraryService'
import PlatformService from '@/services/platformService'
import WishlistService from '@/services/wishlistService'
import JournalService from '@/services/journalService'
import ReviewService from '@/services/reviewService'


import QuickAddModalWindow from '@/components/universal/QuickAddModal'
import GameJournalModal from '@/components/universal/GameJournalModal'
import ReviewModal from '@/components/universal/ReviewModal'

export default {
	name: "ListView",
	props: ['games', 'platforms', 'showcover'],
	components:{
		QuickAddModalWindow,
		GameJournalModal,
		ReviewModal,
	},
	data(){
		return{
			//user: null,
			quickAddGame: {},
			quickAddPlatforms: {},
			journalGame: {},
			journal: {},
			showModal: false,
			showJournalModal: false,
			gameJournalExists: false,
			journalConfirmText: 'Add',
			event: null,
			isAuthUser: false,
			objIndex: null,

			userRating: null,
			showReviewModal: false,
			reviewGame: {},
			review: {},
			gameReviewExists: false,
			reviewConfirmText: 'Add',
			reviewPrivate: false,

		}
	},
	computed:{
		gameObj(){
			return this.games
		},

		user(){
			return this.$store.state.user
		}
	},
	watch:{
		user(){
			return this.$store.state.user
		}
	},
	async mounted(){
		// this.user = await HelperService.getAuthorizedUser()
		if(this.user.isAuth)
		 	this.isAuthUser = true
		
		// this.userRating = this.games[0].rating
		// this.reviewPrivate = this.games[0].review_private
	},
	methods: {
		titleToURL(title){
			return title.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "-")
		},

		formatDate(date){
			let t = 0
			if(date)
			return new Date(parseInt(date) * 1000)
				// Take the epoch date convert it into a normal date string
				.toDateString()
				// Remove the first 4 characters (day of the week)
				.slice(4)
				// Find the 2nd instance of a space in the string and replace with a comma to format date => Jan 1, 2000
				.replace(/\s/g, match => ++t === 2 ? ', ' : match)
			else
				return ''
		},

		getPlatformNames(platformIDs){
			
			let temp = []
			
			if (this.platforms && platformIDs && this.platforms.length > 0 && platformIDs.length > 0) {

				for(let i = 0; i < platformIDs.length; i++){
					let obj = this.platforms.find(element => element.id === platformIDs[i])
					temp.push(obj.platform)
				}
				return temp
			}
		},


		async quickAdd(event, game){

			this.quickAddGame = game;			
			this.quickAddPlatforms = (await PlatformService.getPlatformAny(`{ ${game.platforms} }`)).data.result
			this.toggleModal();
		},

		async journalEntry($event, game, index){
			this.journalGame = game;
			this.journal = (await JournalService.getAuthJournal(game.id)).data.result
			
			this.gameJournalExists = true
			this.event = $event

			if(this.journal.length === 0){
				this.journal = [{id: null, user_id: null, game_id: null, game_status: 'Played', status_date: null, notes: null}]
				this.gameJournalExists = false
			}

			if(this.gameJournalExists)
				this.journalConfirmText = 'Update'
			else
				this.journalConfirmText = 'Add'

			if(Date.parse(this.journal[0].status_date))
				this.journal[0].status_date = new Date(this.journal[0].status_date).toISOString().split('T')[0]
			else 
				this.journal[0].status_date = null

			this.objIndex = index

			this.toggleJournalModal()
		},

		toggleModal(){
			this.showModal  = !this.showModal
		},

		toggleJournalModal(confirm, event, deleted, objIndex, gameStatus, gameDate, journalPrivate){

			if(confirm)
				event.target.children[0].classList.add("favorited")
			
			if(deleted)
				event.target.children[0].classList.remove("favorited")

			if(confirm || deleted){
				this.gameObj[objIndex].game_status = gameStatus
				this.gameObj[objIndex].journal_private = journalPrivate
			}
			
			this.showJournalModal = !this.showJournalModal
		},

		emittoggleModal(){
			this.toggleModal()
		},

		async wishlist($event, game){
			if(game.wished){
				$event.target.disabled = true
				game.wished = !game.wished
				this.$emit('unWishlist', { id: game.id })
				$event.target.disabled = false
			}
			else{
				$event.target.disabled = true
				game.wished = !game.wished
				let response = await(WishlistService.addWishlist(game))
				$event.target.disabled = false
			}
		},

		async addGame(event, game){
			this.$router.push(`/library/add/${game.id}/${game.slug}`)
		},


		toggleButton(event){
			if(event.target.disabled){
				setTimeout(function () {
					event.target.disabled = false
					event.target.childNodes[0].childNodes[0].classList.remove('loader-show')
					event.target.childNodes[1].classList.remove('button__text--hidden')
				}, 1000);
			} else {
				event.target.disabled = true
				event.target.childNodes[0].childNodes[0].classList.add('loader-show')
				event.target.childNodes[1].classList.add('button__text--hidden')
			}
		},


		async reviewEntry($event, game, index){
			this.reviewGame = game;
			this.review = (await ReviewService.getAuthReview(game.id)).data.result
			
			this.gameReviewExists = true
			this.event = $event

			if(this.review.length === 0){
				this.review = [{id: null, user_id: null, game_id: null, rating: null, review: null, review_private: null}]
				this.gameReviewExists = false
			}

			if(this.gameReviewExists)
				this.reviewConfirmText = 'Update'
			else
				this.reviewConfirmText = 'Add'

			this.objIndex = index

			this.toggleReviewModal()
		},



		toggleReviewModal(confirm, event, deleted, form, objIndex){

			if(confirm)
				event.target.classList.add("favorited")

			if(deleted)
				event.target.classList.remove("favorited")

			if(confirm || deleted){
				this.gameObj[objIndex].rating = form.rating
				this.gameObj[objIndex].review_private = form.privateRatingCheck
			}

			this.showReviewModal = !this.showReviewModal
		},


	},
}
</script>