<template>
	<div class="page">
		<div class="page__content" :class="{ 'fadeIn': mounted }">
			
	
			<div class="page__wrapper" :key="game.id" v-for="game in games">

				<div class="add-form">

					<div :class="{'fadeIn': showheader}" :style="headerArt" id="header-art" class=""></div>
					<div id="fade-art" class=""></div>

					<h2 class="game__title">{{game.title}}</h2>

					<h1 class="add-form__title">Add game to library</h1>

	
					<div class="add-form__col add-form__col--input">

						<form class="add-form__form" @submit.prevent>

							<div class="game-info-block">
								<h3 class="category-title">Game Copy Info</h3>
							</div>

							<div class="purchase-info-block">
								<h3 class="category-title">Ownership Info</h3>
							</div>

							<div class="journal-info-block">
								<div class="tool-tip right">
									<div>Your journal is displayed on every copy of the game you own</div>
									<span class="material-icons tooltip right" data-text="">info</span>
								</div>
								<h3 class="category-title">Game Journal</h3>
							</div>

							<div class="status-block">
								<label for="ownership">Status</label>
								<select v-model="form.ownershipInput" name="ownership" id="ownership">
									<option value="Own">Own</option>
									<option value="Rental">Rental</option>
									<option value="Subscription">Subscription</option>
									<option value="Borrowed">Borrowed</option>
									<option value="Sold">Sold</option>
								</select>
							</div>

							<div class="platform-block">
								<label for="platform">Platform</label>
								<select v-model="form.platformInput" v-if="platforms" name="platform" id="platform">
									<option value="0" disabled selected>Select platform</option>
									<option :value="platform.id" :key="platform.id" v-for="platform in platforms">{{platform.platform}}</option>
								</select>
							</div>

							<div class="region-block">
								<label for="region">Region</label>
								<select v-model="form.regionInput" name="region" id="region">
									<option value="North America">North America</option>
									<option value="Europe">Europe</option>
									<option value="Japan">Japan</option>
									<option value="Australia">Australia</option>
									<option value="New Zealand">New Zealand</option>
									<option value="Asia">Asia</option>
									<option value="Korea">Korea</option>
									<option value="China">China</option>
									<option value="South America">South America</option>
									<option value="Worldwide">Worldwide</option>
									<option value="Other">Other</option>
								</select>
							</div>

							<div class="purchase-block">
								
								<div>
									<label for="purchasedate">Purchase Date</label>
									<input v-model="form.purchaseDate" type="date" name="purchasedate" id="">

									<label for="purchaseprice">Purchase Price</label>
									<input v-model="form.purchasePriceInput" type="text" name="purchaseprice">

									<label for="purchasesource">Purchase Source</label>
									<input v-model="form.purchaseSourceInput" type="text" name="edition">
								</div>

								<transition name="add-form" mode="out-in">
									<div v-if="form.ownershipInput == 'Sold'"> 
										<div class="add-form__sold-block">
											<label for="solddate">Sold Date</label>
											<input v-model="form.soldDate" type="date" name="solddate" id="">
					
											<label for="soldprice">Sold Price</label>
											<input v-model="form.soldPriceInput" type="text" name="soldprice">
					
											<label for="soldsource">Sold Source</label>
											<input v-model="form.soldSourceInput" type="text" name="edition">
										</div>
									</div>
								</transition>	

							</div>

							<div class="type-block">
								<label for="type">Type</label>
								<select v-model="form.typeInput" name="type" id="type">
									<option value="Physical">Physical</option>
									<option value="Digital">Digital</option>
								</select>
							</div>

							<div class="edition-block">
								<label for="edition">Edition</label>
								<input v-model="form.editionInput" type="text" name="edition" placeholder="Greatest Hits, Player's Choice, etc.">
							</div>

							<transition-group name="add-form" mode="out-in">
								<div :key="'checkbox-input'" v-if="form.typeInput == 'Physical'"  class="checkbox-block">
									<div class="add-form__checkbox">
											
										<div class="add-form__checkbox-group">
											<label @click.prevent="clickCheck('sealed')" for="sealed">
											<span :class="{checked: form.sealedCheck}" class="checkbox"></span>Sealed
											<input v-model="form.sealedCheck" type="checkbox" name="sealed" id="sealed">
											</label>
										</div>
		
										<transition>
											<div v-if="!form.sealedCheck">
		
												<div class="add-form__checkbox-group">
													<label @click.prevent="clickCheck('cib')" for="cib">
													<span :class="{checked: form.cibCheck}" class="checkbox"></span>CIB
													<input v-model="form.cibCheck" type="checkbox" name="cib" id="cib">
													</label>
												</div>
		
		
												<div class="add-form__quality">
													<div>
														<div class="add-form__checkbox-group">
															<label @click.prevent="clickCheck('game')" for="media">
															<span :class="{checked: form.mediaCheck}" class="checkbox"></span>Game Media
															<input v-model="form.mediaCheck" type="checkbox" name="media" id="media">
															</label>
														</div>
														<transition name="add-form" mode="out-in">
															<select v-model="form.mediaQualityDropdown" v-if="form.mediaCheck" name="mediaquality" id="mediaquality">
																<option value="6">Mint</option>
																<option value="5">Excellent</option>
																<option value="4">Very Good</option>
																<option value="3">Good</option>
																<option value="2">Okay</option>
																<option value="1">Bad</option>
																<option value="0">Terrible</option>
															</select>
														
	
													
														</transition>
														
													</div>
													<div>
														<div class="add-form__checkbox-group">
															<label @click.prevent="clickCheck('box')" for="box">
															<span :class="{checked: form.boxCheck}" class="checkbox"></span>Box
															<input v-model="form.boxCheck" type="checkbox" name="box" id="box">
															</label>
														</div>
														<transition name="add-form" mode="out-in">
															<select v-model="form.boxQualityDropdown" v-if="form.boxCheck" name="boxquality" id="boxquality">
																<option value="6">Mint</option>
																<option value="5">Excellent</option>
																<option value="4">Very Good</option>
																<option value="3">Good</option>
																<option value="2">Okay</option>
																<option value="1">Bad</option>
																<option value="0">Terrible</option>
															</select>
	
											
														</transition>
													</div>
													<div>
														<div class="add-form__checkbox-group">
															<label @click.prevent="clickCheck('manual')" for="manual">
															<span :class="{checked: form.manualCheck}" class="checkbox"></span>Manual
															<input v-model="form.manualCheck" type="checkbox" name="manual" id="manual">
															</label>
														</div>
														<transition name="add-form" mode="out-in">
															<select v-model="form.manualQualityDropdown" v-if="form.manualCheck" name="manualquality" id="manualquality">
																<option value="6">Mint</option>
																<option value="5">Excellent</option>
																<option value="4">Very Good</option>
																<option value="3">Good</option>
																<option value="2">Okay</option>
																<option value="1">Bad</option>
																<option value="0">Terrible</option>
															</select>
	
														</transition>
													</div>	
												</div>	
		
											</div>
										</transition>
										
									</div>
								</div>
	
								<div :key="'quality-input'" v-if="form.typeInput == 'Physical'"  class="inserts-block">
									<label for="otherinserts">Overall Quality</label>
									<select v-model="form.qualityDropdown" name="quality" id="quality">
										<option value="6">Mint</option>
										<option value="5">Excellent</option>
										<option value="4">Very Good</option>
										<option value="3">Good</option>
										<option value="2">Okay</option>
										<option value="1">Bad</option>
										<option value="0">Terrible</option>
									</select>
	
									<transition name="add-form" mode="out-in">
										<div v-if="!form.sealedCheck">
											<label for="otherinserts">Other Inserts</label>
											<input v-model="form.otherInsertsInput" type="text" name="otherinserts" id="otherinserts" placeholder="Posters, cards, stickers, etc.">
										</div>
									</transition>
								</div>
							
								<div :key="'quality-notes-input'" v-if="form.typeInput == 'Physical'" class="quality-block">
									
									<label for="qualitynotes">Quality Notes</label>
									<textarea v-model="form.qualityNotesInput" name="qualitynotes" id="qualitynotes" rows="4" 
										placeholder="Specific notes about the quality of your copy"></textarea>

									
								</div>

								<div :key="'info-block'" class="info-block">

									<div class="tool-tip right">
										<div>Add tags you can use to filter your games</div>
										<span class="material-icons tooltip right" data-text="">info</span>
									</div>
									
									<label for="tag">Tags</label>
									<input v-model="form.tagsInput" type="text" name="tag" id="tag" placeholder="Tags separated by commas">

									<div class="add-form__checkbox-group">

										<div class="tool-tip right">
											<div>Make your game private so only you see it in your library</div>
											<span class="material-icons tooltip right" data-text="">info</span>
										</div>
										
										<label @click.prevent="clickCheck('hidden')" for="hidden">
										<span :class="{checked: form.hiddenCheck}" class="checkbox"></span>Private
										<input v-model="form.hiddenCheck" type="checkbox" name="hidden" id="hidden">
										</label>
									</div>
								</div>
								
							</transition-group>

							<div class="journal-block">

								<div class="add-form__checkbox">

									

									<div class="add-form__gamestatus">

										<div class="tool-tip right">
											<div>
												<div class="tool-top-divider"><strong>Playing</strong> - currently playing</div>
												<div class="tool-top-divider"><strong>Played</strong> - played and probably won't come back to</div>
												<div class="tool-top-divider"><strong>100%</strong> - done everything you can do</div>
												<div class="tool-top-divider"><strong>Paused</strong> - played, but might play again</div>
												<div class="tool-top-divider"><strong>Backlogged</strong> - haven't played, but plan to</div>
												<div class="tool-top-divider"><strong>Quit</strong> - never plan to play or gave up playing</div>
											</div>
											<span class="material-icons tooltip right" data-text="">info</span>
										</div>
										<label for="">Play Status</label>
										<select style="max-width:200px" v-model="form.gameStatusInput" name="gameStatusInput" id="gameStatusInput">
											<option value="Playing">Playing</option>
											<option value="Played">Played</option>
											<option value="100%">100%</option>
											<option value="Paused">Paused</option>
											<option value="Backlogged">Backlogged</option>
											<option value="Quit">Quit</option>
										</select>
									</div>

								</div>

								<label for="statusDate">Status Date</label>
								<input style="max-width:200px" v-model="form.gameStatusDate" type="date" name="statusDate" id="gameStatusDate">

								<label for="othernotes">Journal Notes</label>
								<textarea v-model="form.otherNotesInput" name="othernotes" id="othernotes"  rows="4"
									placeholder="General comments about the game and not this specific copy"></textarea>

								<div class="tool-tip right">
									<div>Hide your journal from the public</div>
									<span class="material-icons tooltip right" data-text="">info</span>
								</div>
								<label @click.prevent="clickCheck('journalpublic')" for="journalpublic">
									<span :class="{checked: form.journalPrivate}" class="checkbox"></span>Private Journal
									<input v-model="form.journalPrivate" type="checkbox" name="journalpublic" id="journalpublic">
								</label>

							
							
								<div class="add-form__checkbox">

									<h3 class="category-title review">Review</h3>
									<label for="rating">Review Rating</label>
									<div style="clear:both;"></div>
									<fieldset id="rating" class="rating">
										<input v-model="form.rating" type="radio" id="rating-star5" name="rating" value="5" /><label class = "full" for="rating-star5"   title="5 Stars"></label>
										<input v-model="form.rating" type="radio" id="rating-star4half" name="rating" value="4.5" /><label class = "half" for="rating-star4half"   title="4.5 Stars"></label>
										<input v-model="form.rating" type="radio" id="rating-star4" name="rating" value="4" /><label class = "full" for="rating-star4"   title="4 Stars"></label>
										<input v-model="form.rating" type="radio" id="rating-star3half" name="rating" value="3.5" /><label class = "half" for="rating-star3half"   title="3.5 Stars"></label>
										<input v-model="form.rating" type="radio" id="rating-star3" name="rating" value="3" /><label class = "full" for="rating-star3"   title="3 Stars"></label>
										<input v-model="form.rating" type="radio" id="rating-star2half" name="rating" value="2.5" /><label class = "half" for="rating-star2half"   title="2.5 Stars"></label>
										<input v-model="form.rating" type="radio" id="rating-star2" name="rating" value="2" /><label class = "full" for="rating-star2"   title="2 Stars"></label>
										<input v-model="form.rating" type="radio" id="rating-star1half" name="rating" value="1.5" /><label class = "half" for="rating-star1half"   title="1.5 Star"></label>
										<input v-model="form.rating" type="radio" id="rating-star1" name="rating" value="1" /><label class = "full" for="rating-star1"   title="1 Star"></label>
										<input v-model="form.rating" type="radio" id="rating-starhalf" name="rating" value="0.5" /><label class = "half" for="rating-starhalf"   title="0.5 Star"></label>
									</fieldset>

									<div style="clear:both;"></div>

									<label for="ratingcomment">Review Comment</label>
									<textarea v-model="form.ratingCommentInput" name="ratingcomment" id="" rows="4" 
										placeholder="Your thoughts on the game..."></textarea>

									<div class="add-form__checkbox-group">
										
										<div class="tool-tip right">
											<div>Hide your review from the public</div>
											<span class="material-icons tooltip right" data-text="">info</span>
										</div>
										<label @click.prevent="clickCheck('ratingpublic')" for="ratingpublic">
										<span :class="{checked: form.privateRatingCheck}" class="checkbox"></span>Private Review
										<input v-model="form.privateRatingCheck" type="checkbox" name="ratingpublic" id="ratingpublic">
										</label>

									</div>

								</div>

							</div>

							<div class="buttons-block">

								<div :class="{'form__error--show': error.generic}" class="form__error">
									<span class="material-icons">error</span>{{error.generic}}
								</div>

								<button @click="addGame($event)" class="">
									<div class="button__loader-container"><div class="loader"></div></div>
									<div class="button__text">
										<span class="text">Add</span>
									</div>
								</button> 

								<button @click="goBack" class="button__flat">
									<div class="button__loader-container"><div class="loader"></div></div>
									<div class="button__text">
										<span class="text">Cancel</span>
									</div>
								</button> 
							</div>

						</form>
					</div>

				</div>

			</div>
	
		</div>

		<div class="error" v-if="games.length < 1">
			<div class="error__message">Game not found!</div>
		</div>

	</div>
</template>

<script>

// import IgdbService from '@/services/igdbService'
import HelperService from '@/services/helperService'
import GameService from '@/services/gameService'
import PlatformService from '@/services/platformService'
import ArtService from '@/services/artService'
import GenreService from '@/services/genreService'
import ReleaseDateService from '@/services/releasedateService'
import AuthService from '@/services/authService'
import UserService from '@/services/userService'
import ScreenshotService from '@/services/screenshotService'
import LibraryService from '@/services/libraryService'
import JournalService from '@/services/journalService'
import ReviewService from '@/services/reviewService'


export default {
	name: "GameAddToCollection",
	data(){
		return{
			//user:null,
			games:{},
			platforms:[],
			genres:[],
			covers:[],
			releasedates:[],
			releases: [],
			temp:[],
			url: null,
			coverArt: null,
			mounted: false,
			headerArt: null,
			
			form:
			{
				gameid: null,
				manualCheck: false,
				boxCheck: false,
				mediaCheck: false,
				cibCheck: false,
				sealedCheck: false,
				hiddenCheck: false,
				privateRatingCheck: false,
	
				ownershipInput: "Own",
				platformInput: 0,
				regionInput: 'North America',
				typeInput: 'Physical',
	
				editionInput: '',
				otherInsertsInput: '',
				qualityNotesInput: '',
				otherNotesInput: '',
				tagsInput: '',
	
				gameStatusInput: 'Played',
				gameStatusDate: null,
	
				purchaseDate: null,
				purchasePriceInput: null,
				purchaseSourceInput: null,
				soldDate: null,
				soldPriceInput: null,
				soldSourceInput: null,
				
	
				rating: null,
				ratingCommentInput: '',
	
				overallQuality: null,
				qualityDropdown: 3,
				mediaQuality: null,
				mediaQualityDropdown: 3,
				boxQuality: null,
				boxQualityDropdown: 3,
				manualQuality: null,
				manualQualityDropdown: 3,

				gameJournalExists: false,
			},

			error:{
				generic: '',
			},

			showheader: false,
		}
	},
	computed:{
		user(){
			return this.$store.state.user
		}
	},
	watch:{
		user(){
			return this.$store.state.user
		}
	},
	async mounted(){
		//this.user = await HelperService.getAuthorizedUser()
		if(this.user == null) this.$router.push(`/login`)

		this.games = (await GameService.getGame(this.$route.params.id, this.$route.params.url)).data.result
		this.journal = (await JournalService.getJournal(this.$route.params.id, this.user.id)).data.result
		this.review = (await ReviewService.getReview(this.$route.params.id, this.user.id)).data.result
		
		if(this.journal && this.journal.length > 0)
			this.gameJournalExists = true

		if(this.review && this.review.length > 0)
			this.gameReviewExists = true

		if(this.games == undefined || this.games == null || this.games.length < 1)	
			this.$router.push('/404')

		this.form.gameid = this.$route.params.id

		if(this.gameJournalExists){
			this.form.gameStatusInput = this.journal[0].game_status
			this.form.otherNotesInput = this.journal[0].notes
			this.form.journalPrivate = this.journal[0].journal_private

			if(Date.parse(this.journal[0].status_date))
				this.form.gameStatusDate = new Date(this.journal[0].status_date).toISOString().split('T')[0]
			else 
				this.form.gameStatusDate = null
		}

		if(this.gameReviewExists){
			this.form.rating = this.review[0].rating
			this.form.ratingCommentInput = this.review[0].review
			this.form.privateRatingCheck = this.review[0].review_private
		}

		this.temp = `{ ${this.games[0].platforms} }`
		this.platforms = (await PlatformService.getPlatformAny(this.temp)).data.result
		this.mounted = true
		
		this.screenshots = (await ScreenshotService.getScreenshotFromGame(this.games[0].id)).data.result
		
		if(this.screenshots.length > 0){
			let random = Math.floor(Math.random() * this.screenshots.length)
			this.headerArt = `background-image: url(${this.screenshots[random].url.replace("thumb", "screenshot_big").replace('.jpg', '.webp')})`
		}

		this.showheader = true
		
	},
	methods:{
		clickCheck(value){
			if(value == "manual")
				return this.form.manualCheck = !this.form.manualCheck
			if(value == "box")
				return this.form.boxCheck = !this.form.boxCheck
			if(value == "game")
				return this.form.mediaCheck = !this.form.mediaCheck
			if(value == "cib")
				return this.form.cibCheck = !this.form.cibCheck
			if(value == "sealed")
				return this.form.sealedCheck = !this.form.sealedCheck
			if(value == "played")
				return this.form.playedCheck = !this.form.playedCheck
			if(value == "finished")
				return this.form.finishedCheck = !this.form.finishedCheck
			if(value == "backlogged")
				return this.form.backloggedCheck = !this.form.backloggedCheck
			if(value == "neverplaying")
				return this.form.neverPlayingCheck = !this.form.neverPlayingCheck
			if(value == "ratingpublic")
				return this.form.privateRatingCheck = !this.form.privateRatingCheck
			if(value == "hidden")
				return this.form.hiddenCheck = !this.form.hiddenCheck
			if(value == "journalpublic")
				return this.form.journalPrivate = !this.form.journalPrivate

			return null
		},


		async addGame(event){
			
			try{
				this.toggleButton(event)
				let journalResponse = null
				let reviewResponse = null
				let response = (await LibraryService.addLibraryGameFull(this.form)).data.result

				if(this.gameJournalExists)
					journalResponse = (await JournalService.updateJournal(this.form)).data.result
				else
					journalResponse = (await JournalService.addJournal(this.form)).data.result

				
				if(this.gameReviewExists)
					reviewResponse = (await ReviewService.updateReview(this.form)).data.result
				else 
					reviewResponse = (await ReviewService.addReview(this.form)).data.result


				let libraryid = response[0].id
				this.toggleButton(event)
				//this.$router.push(`/library/${libraryid}`)
				this.$router.go(-1)
			} catch(err){
				this.error.generic = `Something went wrong`
				this.toggleButton(event)
			}

		},



		toggleButton(event){
			if(event.target.disabled){
				setTimeout(function () {
					event.target.disabled = false
					event.target.childNodes[0].childNodes[0].classList.remove('loader-show')
					event.target.childNodes[1].classList.remove('button__text--hidden')
				}, 1000);
			} else {
				event.target.disabled = true
				event.target.childNodes[0].childNodes[0].classList.add('loader-show')
				event.target.childNodes[1].classList.add('button__text--hidden')
			}
		},

		goBack(){
			this.$router.go(-1)
		},


	}
}


</script>
