<template>
	<div v-if="mounted" class="compact" style="opacity:0;">

		<QuickAddModalWindow :class="{'modal--show': showModal}" @toggleModal="emittoggleModal" :title="`Add to library`" :subtitle="quickAddGame.title" :game="quickAddGame" :platforms="quickAddPlatforms" confirmText="Add" cancelText="Cancel"  />
		<GameJournalModal :class="{'modal--show': showJournalModal}" @toggleJournalModal="toggleJournalModal" :title="`Add to library`" :subtitle="`${journalGame.title}`" :game="journalGame" :journal="journal" :event="event" :objIndex="objIndex" :gameJournalExists="gameJournalExists" :confirmText="journalConfirmText" cancelText="Cancel"  />

		<ReviewModal :class="{'modal--show': showReviewModal}" @toggleReviewModal="toggleReviewModal" :title="`Add review`" :subtitle="`${reviewGame.title}`" :game="reviewGame" :review="review" :event="event" :objIndex="objIndex" :gameReviewExists="gameReviewExists" :confirmText="reviewConfirmText" cancelText="Cancel"  />

		<transition-group name="gamecard" tag="div" appear>
			<div class="compact__row" :key="game.id" v-for="(game, index) in gameObj">
				
				<div v-if="user.isAuth" class="compact__col compact__col-button">
					<div>
						

						<button @click="openMenu($event, index)" :class="`compact__button compact__menu-button compact__menu-button--show compact__menu-button-${index}`">
							<div class="button__loader-container"><div :class="checking && 'loader-show'" class="loader"></div></div>
							<div class="button__text" :class="checking && 'button__text--hidden'"><span class="material-icons-outlined">more_vert</span></div>
						</button>

						<button @click="closeMenu($event, index)" :class="`compact__button compact__menu-button-close compact__menu-button-close-${index}`">
							<span class="material-icons-outlined">close</span>
						</button>

						<div :class="`compact__menu compact__menu-${index}`">

							<button title="Add" @click="addGame($event, game)" class="compact__button">
								<div class="button__text" :class="checking && 'button__text--hidden'"><span class="material-icons">playlist_add</span> Add</div>
							</button>

							<button title="Quick Add" @click="quickAdd($event, game)" class="compact__button">
								<div class="button__loader-container"><div :class="checking && 'loader-show'" class="loader"></div></div>
								<div class="button__text" :class="checking && 'button__text--hidden'"><span class="material-icons">add</span> Quick Add</div>
							</button> 
	
							<button title="Wishlist" @click="wishlist(game)" class="compact__button compact__wishlist-button">
								<div class="button__text">
									<span v-if="game.wished" class="material-icons favorited-small">favorite</span>
									<span v-else class="material-icons">favorite_border</span> Wishlist
								</div>
							</button>
	
							<button title="Journal" @click="journalEntry($event, game, index)" class="compact__button compact__play-button">
								<div class="button__text">
									<span v-if="game.journals_id" class="material-icons favorited">sports_esports</span>
									<span v-else class="material-icons">sports_esports</span> Journal
								</div>
							</button> 

							<button title="Review" @click="reviewEntry($event, game, index)" class="compact__button compact__review-button">
								<div class="button__text">
									<span v-if="game.rating"><i class="fa-solid fa-pen-to-square favorited"></i> Review</span>
									<span v-else><i class="fa-solid fa-pen-to-square"></i>  Review</span>
								</div>
							</button> 
						</div>


					</div>
				</div>

				<div class="compact__col compact__col-title">
					<div class="compact__title">
						
						<router-link v-if="game.title" 
							:to="{name: 'Game', params:{id: game.id, url: game.slug }}">{{ game.title }}</router-link>
						
						<span v-if="game.wished" class="material-icons favorited-small compact-favorited-small">favorite</span>

						<span v-if="game.journals_id" class="compact-view__game-status">
							<span title="Playing" v-if="game.game_status == 'Playing'" class="playing"></span>
							<span title="Played" v-else-if="game.game_status == 'Played'" class="played"></span>
							<span title="Paused" v-else-if="game.game_status == 'Paused'" class="paused"></span>
							<span title="Backlogged" v-else-if="game.game_status == 'Backlogged'" class="backlogged"></span>
							<span title="100%" v-else-if="game.game_status == '100%'" class="one-hundred"></span>
							<span title="Quit" v-else-if="game.game_status == 'Quit'" class="quit"></span>
						</span>
						
						
						<span v-if="game.rating" :title="`${game.rating} star rating`" class="compact-view__rating"><i class="fa-solid fa-star brand-color"></i> {{game.rating}}</span> 
							<!-- (<span class="compact__date">{{ new Date(parseInt(game.first_release_date) * 1000).getFullYear() }}</span>) -->
					</div>
				</div>

				<div class="compact__col compact__col-rating">
					<span v-if="!isNaN(game.avg_rating) && game.avg_rating > 0"><i class="fa-solid fa-star brand-color"></i> {{(game.avg_rating / 20).toFixed(1)}}</span>
				</div>

				<div class="compact__col compact__col-platforms">
					<div class="compact__platforms">{{ getPlatformNames(game.platforms) }}</div>
				</div>
				
			</div>
		</transition-group>
	</div>
</template>

<script>

import PlatformService from '@/services/platformService'
import HelperService from '@/services/helperService'
import LibraryService from '@/services/libraryService'
import WishlistService from '@/services/wishlistService'
import JournalService from '@/services/journalService'
import ReviewService from '@/services/reviewService'


import QuickAddModalWindow from '@/components/universal/QuickAddModal'
import GameJournalModal from '@/components/universal/GameJournalModal'
import ReviewModal from '@/components/universal/ReviewModal'

export default {
	name: "CompactView",
	props: ['games', 'platforms', 'refresh'],
	components: {
		QuickAddModalWindow,
		GameJournalModal,
		ReviewModal,
	},
	data(){
		return{
			mounted:false,
			show: 'show',
			//user: null,
			checking: false,
			quickAddGame: {},
			quickAddPlatforms: {},
			showModal: false,

			journalGame: {},
			journal: {},
			showJournalModal: false,
			gameJournalExists: false,
			journalConfirmText: 'Add',

			objIndex: null,

			event: null,
			isAuthUser: false,

			userRating: null,
			showReviewModal: false,
			reviewGame: {},
			review: {},
			gameReviewExists: false,
			reviewConfirmText: 'Add',
			reviewPrivate: false,
		}
	},
	computed:{
		gameObj(){
			return this.games
		},

		user(){
			return this.$store.state.user
		}
	},
	watch:{
		user(){
			return this.$store.state.user
		}
	},
	async mounted(){
			
			// this.user = await HelperService.getAuthorizedUser()
			if(this.user.isAuth)
		 		this.isAuthUser = true

			this.mounted = true

			
	},
	methods: { 

		getPlatformNames(platformIDs){
			
			let temp = ''

			if (this.platforms && platformIDs && this.platforms.length > 0 && platformIDs.length > 0) {

				for(let i = 0; i < platformIDs.length; i++){
					//this.temp += this.games[i].id + ','
					let obj = this.platforms.find(element => element.id === platformIDs[i])
					temp += obj.platform + ", "
				}
				return temp.slice(0, -2)
			}

		},

		toggleModal(){
			this.showModal  = !this.showModal
		},

		emittoggleModal(){
			this.toggleModal()
		},

		async addGame(event, game){
			this.$router.push(`/library/add/${game.id}/${game.slug}`)
		},

		async quickAdd(event, game){
			this.quickAddGame = game;			
			this.quickAddPlatforms = (await PlatformService.getPlatformAny(`{ ${game.platforms} }`)).data.result
			this.toggleModal();			
		},

		async wishlist(game){
			if(game.wished){
				game.wished = !game.wished
				this.$emit('unWishlist', { id: game.id })
			}
			else{
				game.wished = !game.wished
				let response = await(WishlistService.addWishlist(game))
			}
		},


		async journalEntry($event, game, index){
			this.journalGame = game;
			this.journal = (await JournalService.getAuthJournal(game.id)).data.result
			this.gameJournalExists = true
			this.event = $event

			if(this.journal.length === 0){
				this.journal = [{id: null, user_id: null, game_id: null, game_status: 'Played', status_date: null, notes: null}]
				this.gameJournalExists = false
			}

			if(this.gameJournalExists)
				this.journalConfirmText = 'Update'
			else
				this.journalConfirmText = 'Add'

			if(Date.parse(this.journal[0].status_date))
				this.journal[0].status_date = new Date(this.journal[0].status_date).toISOString().split('T')[0]
			else 
				this.journal[0].status_date = null

			this.objIndex = index

			this.toggleJournalModal()
		},

		toggleJournalModal(confirm, event, deleted, objIndex, gameStatus, gameDate, journalPrivate){
			
			if(confirm)
				event.target.children[0].children[0].classList.add("favorited")
			
			if(deleted)
				event.target.children[0].children[0].classList.remove("favorited")

			if(confirm || deleted){
				this.gameObj[objIndex].game_status = gameStatus
				this.gameObj[objIndex].journal_private = journalPrivate
			}
				
			this.showJournalModal = !this.showJournalModal
		},


		async reviewEntry($event, game, index){
			this.reviewGame = game;
			this.review = (await ReviewService.getAuthReview(game.id)).data.result
			
			this.gameReviewExists = true
			this.event = $event

			if(this.review.length === 0){
				this.review = [{id: null, user_id: null, game_id: null, rating: null, review: null, review_private: null}]
				this.gameReviewExists = false
			}

			if(this.gameReviewExists)
				this.reviewConfirmText = 'Update'
			else
				this.reviewConfirmText = 'Add'

			this.objIndex = index

			this.toggleReviewModal()
		},


		toggleReviewModal(confirm, event, deleted, form, objIndex){

			if(confirm)
				event.target.classList.add("favorited")

			if(deleted)
				event.target.classList.remove("favorited")

			if(confirm || deleted){
				this.gameObj[objIndex].rating = form.rating
				this.gameObj[objIndex].review_private = form.privateRatingCheck
			}

			this.showReviewModal = !this.showReviewModal
		},




		openMenu($event, index){
			const compactMenu = document.getElementsByClassName(`compact__menu-${index}`)
			compactMenu[0].classList.add('compact__menu--show')

			const compactClose = document.getElementsByClassName(`compact__menu-button-close-${index}`)
			compactClose[0].classList.add('compact__menu-button-close--show')
			compactClose[0].classList.add('compact-menu-open')

			$event.target.classList.remove(`compact__menu-button--show`)
		},

		closeMenu($event, index){
			$event.target.classList.remove(`compact__menu-button-close--show`)
			$event.target.parentElement.classList.remove(`compact__menu-button-close--show`)

			$event.target.classList.remove(`compact-menu-open`)
			$event.target.parentElement.classList.remove(`compact-menu-open`)

			const compactMenuButton = document.getElementsByClassName(`compact__menu-button-${index}`)
			compactMenuButton[0].classList.add(`compact__menu-button--show`)

		},

	}
}
</script>