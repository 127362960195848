<template>
	<div class="page page__block">
		<section class="page__content" :class="{show: mounted}">
			<div class="page__wrapper">
				<div class="article">
					<h1>Login</h1>
					
					<form @submit.prevent>
						<div :class="{'form__error--show': error.generic}" class="form__error">
							<span class="material-icons">error</span>{{error.generic}}
						</div>

						<label for="username">Username</label>
						<input v-model="form_data.username" type="text" name="username" required>
						<div :class="{'form__error--show': error.username}" class="form__error">
							<span class="material-icons">error</span>{{ error.username }}
						</div>

						<label for="password">Password</label>
						<input v-model="form_data.password" type="password" name="password" required>
						<div :class="{'form__error--show': error.password}" class="form__error">
							<span class="material-icons">error</span>{{ error.password }}
						</div>
						
						<button @click="loginUser">
							<div class="button__loader-container"><div :class="checking && 'loader-show'" class="loader"></div></div>
							<div class="button__text" :class="checking && 'button__text--hidden'">Login</div>
						</button> 
						
						<div>Don't have an account? <router-link :to="{name:'UserRegister'}">Register</router-link> now.</div>

						<div><router-link :to="{name:'UserForgot'}">Forgot your password?</router-link></div>
					
						
					</form>
				</div>
			</div>
		</section>
	</div>
</template>


<script>

import UserService from '@/services/userService'
import AuthService from '@/services/authService'
import HelperService from '@/services/helperService'

export default {
	name: 'UserLogin',
	data(){
		return{
			user:{},
			form_data:{
				email: null,
				password: null,
				username: null,
			},
			error:{
				email: null,
				password: null,
				username: null,
				generic: null,
			},
			checking: false,
			loader: 'loader-show',
			attempt: 0,
			mounted: false,
		}
	},
	computed:{
		getUser(){
			return this.$store.state.user.id
		}
	},
	watch:{
		getUser(){
			if(this.$store.state.user.id)
				this.$router.push(`/`)
		}
	},
	async mounted(){
		this.mounted = true 
	},
	methods:{
		async loginUser(e){
			e.target.disabled = false
			this.resetErrors()
			this.checking = true

			if(!(await this.checkFormValid())) return
			
			try{
				e.target.disabled = true
				
				this.user = (await UserService.loginUser(this.form_data)).data.result

				if(this.user.id != null){
					this.$store.state.user.id = this.user.id
					this.$store.state.user.slug = this.user.slug
					this.$store.state.user.isAuth = true
					this.$store.state.user.isLoggedIn = true
					this.$router.go(-1)
					
				} else {
					this.resetErrors()
					this.error.generic = ` Something went wrong`
					e.target.disabled = false
					this.checking = false
				}
					
			} catch(err){
				console.log(err)

				this.resetErrors()
				this.attempt++
				if(this.attempt > 3)
					this.error.password = ` Try resetting your password`
				else
					this.error.password = ` Wrong password`
			}
			
			this.checking = false
			e.target.disabled = false
		},

		async checkFormValid(){

			try{
				if(this.form_data.username){
					let res = (await UserService.checkUsername(this.form_data)).data.result
					if(res.length == 0) {
						this.error.username = ` No user found`
						return false
					}	
				}
			} catch (err){
				console.log(err)
				this.resetErrors()
				this.error.generic = ` Something went wrong`
				return false
			}

			if(!this.form_data.password){
				this.resetErrors()
				this.error.password = ' Please enter password'
				return false
			}
	
			return true
		},

		resetErrors(){
			this.error.username = null
			this.error.password = null
			this.error.generic = null
			this.checking = false
		}

	}
}
</script>