<template>
	<div class="list-view" style="opacity:0;">

		<ModalWindow :class="{'modal--show': showModal}" @toggleModal="emittoggleModal" @selectConfirm="selectConfirm" :title="'Are you sure you want to delete?'" :subtitle="deleteGame" :confirmText="'Delete'" :cancelText="'Cancel'"  />
		<GameJournalModal :class="{'modal--show': showJournalModal}" @toggleJournalModal="toggleJournalModal" :title="`Add to library`" :subtitle="``" :game="journalGame" :journal="journal" :gameid="journalGame.game_id" :event="event" :objIndex="objIndex" :gameJournalExists="gameJournalExists" :confirmText="journalConfirmText" cancelText="Cancel"  />

		<ReviewModal :class="{'modal--show': showReviewModal}" @toggleReviewModal="toggleReviewModal" :title="`Add review`" :subtitle="`${reviewGame.title}`" :game="reviewGame" :review="review" :gameid="reviewGame.game_id" :event="event" :objIndex="objIndex" :gameReviewExists="gameReviewExists" :confirmText="reviewConfirmText" cancelText="Cancel"  />

		<transition-group name="gamecard" tag="div" appear mode="out-in">
			<div class="list-view__row list-view__row--library" :key="game.id" v-for="(game, index) in games">

				<!-- <div class="list-view__open-icon"><router-link :to="{name: 'LibraryGame', params:{id: game.id }}"><span class="material-icons">open_in_new</span> <span class="material-icon-word">Open</span></router-link></div> -->

				<div class="list-view__cover list-view__library-cover">
					<div class="list-view__no-cover" v-if="game.coverArt == ''"><router-link :to="{name: 'LibraryGame', params:{id: game.id, url: titleToURL(game.title)}}">No Cover</router-link></div>
					<router-link :to="{name: 'LibraryGame', params:{id: game.id, url: titleToURL(game.title)}}"><img v-if="game.coverArt != ''" :class="{ 'show': showcover }" class="list-view__image" :src="game.coverArt" alt=""></router-link>
				</div>

				<div class="list-view__title-library-title">
					<router-link v-if="game.title" class="list-view__title" :to="{name: 'LibraryGame', params:{id: game.id, url: titleToURL(game.title)}}">{{ game.title }}</router-link><span v-if="game.rating"></span>

					<!-- <div v-if="game.rating && (!game.review_private || user && user.slug === game.slug)" class="list-view__game-reviews game__reviews">
						<div :title="game.rating > 1 ? `${game.rating} stars` : `${game.rating} star`" class="Stars" :style="`--rating: ${game.rating - 0.075};`"></div>
						<span v-if="user && game.rating && user.slug === game.slug && game.review_private" title="Review Hidden" class="material-icons review-visible">visibility_off</span> 
					</div> -->

					<div v-if="user && game.game_status && user.slug === game.slug" :title="`Play status ${game.game_status}`" class="list-view__game-status">
						<span v-if="game.game_status === 'Playing'" class="playing">{{game.game_status}}</span>
						<span v-else-if="game.game_status === 'Played'" class="played">{{game.game_status}}</span>
						<span v-else-if="game.game_status === 'Backlogged'" class="backlogged">{{game.game_status}}</span>
						<span v-else-if="game.game_status === 'Quit'" class="quit">{{game.game_status}}</span>
						<span v-else-if="game.game_status === 'Paused'" class="paused">{{game.game_status}}</span>
						<span v-else-if="game.game_status === '100%'" class="one-hundred">100%</span>
					</div>
					<div v-else-if="game.game_status && !game.journal_private" :title="`Play status ${game.game_status}`" class="list-view__game-status">
						<span v-if="game.game_status === 'Playing'" class="playing">{{game.game_status}}</span>
						<span v-else-if="game.game_status === 'Played'" class="played">{{game.game_status}}</span>
						<span v-else-if="game.game_status === 'Backlogged'" class="backlogged">{{game.game_status}}</span>
						<span v-else-if="game.game_status === 'Quit'" class="quit">{{game.game_status}}</span>
						<span v-else-if="game.game_status === 'Paused'" class="paused">{{game.game_status}}</span>
						<span v-else-if="game.game_status === '100%'" class="one-hundred">100%</span>
					</div>

					<span v-if="user && user.slug === game.slug && game.game_status && game.journal_private" title="Journal Hidden" class="material-icons review-visible">visibility_off</span> 

					<div v-if="game.rating && (!game.review_private || user && user.slug === game.slug)" :title="`Your rating`" class="list-view__library-rating"><i class="fa-solid fa-star brand-color"></i> {{game.rating}}</div> 
					<span v-if="user && game.rating && user.slug === game.slug && game.review_private" title="Review Hidden" class="material-icons review-visible">visibility_off</span> 
					
				</div>

				
				<div class="list-view__library-controls">
					<div v-if="auth" class="game__ui list-view__ui">
						
						<button title="Edit" class="game__wishlist-button">
							<router-link :to="{name: 'LibraryEditGame', params:{id: game.id }}"><span class="material-icons">edit_note</span></router-link> 
						</button>
						
						<button title="Delete" @click="beginDeleteLibraryGame(game)" class="game__wishlist-button">
							<div :class="game.checking && 'show'" class="list-view__loader"><div class="loader-show loader"></div></div>
							<span class="list-view__library-delete-button"><span class="material-icons">delete_forever</span></span>
						</button>

						<button title="Journal" @click.prevent="journalEntry($event, game, index)" class="game__wishlist-button">
							<span v-if="game.game_status" class="material-icons favorited">sports_esports</span>
							<span v-else class="material-icons">sports_esports</span>
						</button>
						
						<button title="Review" @click="reviewEntry($event, game, index)" class="game__wishlist-button">
								<i v-if="game.rating" class="fa-solid fa-pen-to-square favorited"></i>
								<i v-else class="fa-solid fa-pen-to-square"></i>
						</button>

					</div>
				</div>


				<div class="list-view__data list-view__library-data">
					<div v-if="game.edition" class="list-view__library-attr-row list-view__library-edition">{{game.edition}}</div>

					<div class="list-view__library-attr-row">
						<span class="list-view__library-attr list-view__library-attr-status" v-if="(game.sealed || game.complete) && game.game_type == 'Physical'">
							<span v-if="game.sealed">Sealed</span>
							<span class="cib" v-if="game.complete">CIB</span>
						</span>
					</div>
					

					<div class="list-view__library-attr-row">
	
						<div class="list-view__library-attr" v-if="game.region">
							<span class="list-view__library-attr-title">Region:</span>
							<span class="list-view__library-attr-data">{{game.region}}</span>
						</div>

						<div class="list-view__library-attr" v-if="game.platform">
							<span class="list-view__library-attr-title">Platform:</span>
							<span class="list-view__library-attr-data">{{game.platform}}</span>
						</div>

						
					</div>

					<div class="list-view__library-attr-row">
						
						<div class="list-view__library-attr" v-if="game.game_type">
							<span class="list-view__library-attr-title">Format:</span>
							<span class="list-view__library-attr-data">{{game.game_type}}</span>
						</div>

						<div class="list-view__library-attr" v-if="game.game_type == 'Physical'">
							<span v-if="game.quality >= 0" class="list-view__library-attr-title">Quality:</span>
							<span v-if="game.quality == 0" class="list-view__library-attr-data terrible">Terrible</span>
							<span v-if="game.quality == 1" class="list-view__library-attr-data bad">Bad</span>
							<span v-if="game.quality == 2" class="list-view__library-attr-data okay">Okay</span>
							<span v-if="game.quality == 3" class="list-view__library-attr-data good">Good</span>
							<span v-if="game.quality == 4" class="list-view__library-attr-data very-good">Very Good</span>
							<span v-if="game.quality == 5" class="list-view__library-attr-data excellent">Excellent</span>
							<span v-if="game.quality == 6" class="list-view__library-attr-data mint">Mint <span class="material-icons">diamond</span></span>
						</div>

						

					</div>


					<!-- <div class="list-view__library-attr-row">
						<div class="list-view__library-attr" v-if="game.game_status">
							<span class="list-view__library-attr-title">Status:</span>
							<span class="list-view__library-attr-data"> {{game.game_status}}</span>
						</div>

						<div>{{game.created_on}}</div>
						<div>{{game.purchase_date}}</div>
					
						<div class="list-view__library-attr">{{game.ownership}}</div>
						<div class="list-view__library-attr">{{game.game_id}}</div>
					</div> -->
				</div>

			</div>
		</transition-group>

	</div>
</template>

<script>

import HelperService from '@/services/helperService'
import UserService from '@/services/userService'
import JournalService from '@/services/journalService'
import ReviewService from '@/services/reviewService'

import ModalWindow from '@/components/universal/ModalWindow'
import GameJournalModal from '@/components/universal/GameJournalModal'
import ReviewModal from '@/components/universal/ReviewModal'


export default {
	name: "ListView",
	props: ['games', 'platforms', 'showcover', 'auth'],
	components:{
		ModalWindow,
		GameJournalModal,
		ReviewModal,
	},
	data(){
		return{
			show: 'show',
			//user: null,
			showModal: false,
			deleteGame: '',
			objGame: null,

			journalGame: {},
			journal: {},
			showJournalModal: false,
			gameJournalExists: false,
			journalConfirmText: 'Add',
			event: null,

			objIndex: null,

			userRating: null,
			showReviewModal: false,
			reviewGame: {},
			review: {},
			gameReviewExists: false,
			reviewConfirmText: 'Add',
			reviewPrivate: false,
		}
	},
	computed:{
		gameObj(){
			return this.games
		},

		user(){
			return this.$store.state.user
		}
	},
	watch:{
		user(){
			return this.$store.state.user
		}
	},
	async mounted(){
		//this.user = await HelperService.getAuthorizedUser()
		
	},
	methods: {
		titleToURL(title){
			return title.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "-")
		},


		formatDate(date){
			let t = 0
			if(date)
			return new Date(parseInt(date) * 1000)
				// Take the epoch date convert it into a normal date string
				.toDateString()
				// Remove the first 4 characters (day of the week)
				.slice(4)
				// Find the 2nd instance of a space in the string and replace with a comma to format date => Jan 1, 2000
				.replace(/\s/g, match => ++t === 2 ? ', ' : match)
			else
				return ''
		},

		getPlatformNames(platformIDs){
			
			let temp = []
			
			if (this.platforms && platformIDs && this.platforms.length > 0 && platformIDs.length > 0) {

				for(let i = 0; i < platformIDs.length; i++){
					let obj = this.platforms.find(element => element.id === platformIDs[i])
					temp.push(obj.platform)
				}
				return temp
			}

		},


		deleteLibraryGame(game){
			this.toggleModal()
			game.checking = true
			this.$emit('deleteLibraryGame', { id: game.id })
		},

		beginDeleteLibraryGame(game){
			this.objGame = game
			this.deleteGame = game.title
			this.toggleModal()
		},

		toggleModal(){
			this.showModal  = !this.showModal
		},

		emittoggleModal(){
			this.toggleModal()
		},

		selectConfirm(){
			this.deleteLibraryGame(this.objGame)
		},


		async journalEntry($event, game, index){
			this.journalGame = game;
			this.journal = (await JournalService.getAuthJournal(game.game_id)).data.result
			this.gameJournalExists = true
			this.event = $event

			if(this.journal.length === 0){
				this.journal = [{id: null, user_id: null, game_id: null, game_status: 'Played', status_date: null, notes: null}]
				this.gameJournalExists = false
			}

			if(this.gameJournalExists)
				this.journalConfirmText = 'Update'
			else
				this.journalConfirmText = 'Add'

			if(Date.parse(this.journal[0].status_date))
				this.journal[0].status_date = new Date(this.journal[0].status_date).toISOString().split('T')[0]
			else 
				this.journal[0].status_date = null

			this.objIndex = index

			this.toggleJournalModal()
		},

		toggleJournalModal(confirm, event, deleted, objIndex, gameStatus, gameDate, journalPrivate){

			if(confirm)
				event.target.children[0].classList.add("favorited")
			
			if(deleted)
				event.target.children[0].classList.remove("favorited")

			if(confirm || deleted){
				this.gameObj[objIndex].game_status = gameStatus
				this.gameObj[objIndex].journal_private = journalPrivate
			}
			
			this.showJournalModal = !this.showJournalModal
		},



		async reviewEntry($event, game, index){
			this.reviewGame = game;
			
			this.review = (await ReviewService.getAuthReview(game.game_id)).data.result
			
			this.gameReviewExists = true
			this.event = $event

			if(this.review.length === 0){
				this.review = [{id: null, user_id: null, game_id: null, rating: null, review: null, review_private: null}]
				this.gameReviewExists = false
			}

			if(this.gameReviewExists)
				this.reviewConfirmText = 'Update'
			else
				this.reviewConfirmText = 'Add'

			this.objIndex = index

			this.toggleReviewModal()
		},



		toggleReviewModal(confirm, event, deleted, form, objIndex){

			if(confirm)
				event.target.classList.add("favorited")

			if(deleted)
				event.target.classList.remove("favorited")

			if(confirm || deleted){
				this.gameObj[objIndex].rating = form.rating
				this.gameObj[objIndex].review_private = form.privateRatingCheck
			}

			this.showReviewModal = !this.showReviewModal
		},


	},
}
</script>