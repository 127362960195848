import Home from '@/views/Home.vue'
import About from '@/views/About.vue'
import Contact from '@/views/Contact.vue'
import Credits from '@/views/Credits.vue'
import Privacy from '@/views/Privacy.vue'
import Terms from '@/views/Terms.vue'
import Error404 from '@/views/404.vue'
import NotFound from '@/views/404.vue'


const PageRoutes = [
	{
		path: "/",
		name: "Home",
		component: Home,
	},
	{
		path: "/about",
		name: "About",
		component: About,
		meta: {
			title: 'About - GameStash',
		}
	},
	{
		path: "/contact",
		name: "Contact",
		component: Contact,
		meta: {
			title: 'Contact - GameStash',
		}
	},
	{
		path: "/credits",
		name: "Credits",
		component: Credits,
		meta: {
			title: 'Credits - GameStash',
		}
	},
	{
		path: "/privacy",
		name: "Privacy",
		component: Privacy,
		meta: {
			title: 'Privacy Policy - GameStash',
		}
	},

	{
		path: "/terms",
		name: "Terms",
		component: Terms,
		meta: {
			title: 'Terms and Conditions - GameStash',
		}
	},

	{
		path: "/404",
		name: "404",
		component: Error404,
		meta: {
			title: '404 - GameStash',
		}
	},

	{
		path: "/:path(.*)*",
		name: "NotFound",
		component: NotFound,
		meta: {
			title: '404 - GameStash',
		}
	},
]

export default PageRoutes