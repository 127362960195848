
import Browse from "@/views/Browse/Browse.vue"
import Game from "@/views/Game/Game.vue"

const GameRoutes = [
	{
		path: "/browse",
		name: "Browse",
		component: Browse,
		meta: {
			title: 'GameStash - Video game collecting for serious collectors',
		}
	},
	{
		path: "/game/:id/:url",
		name: "Game",
		component: Game,
		meta: {
			title: 'GameStash - Video game collection and tracking',
		}
	},

]

export default GameRoutes