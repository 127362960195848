<template>
	<div class="page">
		<TheLoader v-if="!mounted"></TheLoader>
		<div class="page__content" :class="{'fadeIn': mounted}">

			<div :class="{'fadeIn': showheader}" :style="headerArt" id="header-art" class=""></div>
			<div id="fade-art" class="user__header-art"></div>

			<div class="page__wrapper dashboard">
				
				<h1 class="game__title dashboard__title">{{username}}</h1>

				<div v-if="userObj.discord || userObj.steam || userObj.twitter" class="dashboard__social-links">
					<ul class="dashboard__social-links-cotainer">
						<li v-if="userObj.discord" @click="copyDiscord" class="dashboard__social-links-item"><i class="fab fa-discord"></i> {{ userObj.discord }} <span id="discord-copy-message" class="discord__copy-message">Copied</span></li>
						<li v-if="userObj.steam" class="dashboard__social-links-item"><a target="_blank" :href="'https://steamcommunity.com/id/' + userObj.steam"><i class="fa-brands fa-steam"></i> {{ userObj.steam }}</a></li>
						<li v-if="userObj.twitter" class="dashboard__social-links-item"><a target="_blank" :href="'https://twitter.com/' + userObj.twitter"><i class="fa-brands fa-twitter"></i> {{ userObj.twitter }}</a></li>
					</ul>
				</div>

				<router-link :to="{ name: 'Library', params: { slug: $route.params.slug  } }" class="button__flat">View Library</router-link>

				<div class="dashboard__stats-container">

					<div v-if="displayStats" class="fadeIn dashboard__stat-totals">

						<DashboardStatBox v-if="gameCount > 0" :title="'Own'" :number="gameCount" :percentage="Math.floor((parseInt(gameCount) / parseInt(gameCount)) * 100) + '%'" />
						<DashboardStatBox v-else :title="'Own'" :number="gameCount" :percentage="'0%'" />
						<DashboardStatBox v-if="gamePlayedCount > 0" :title="'Played'" :number="gamePlayedCount" :percentage="Math.floor((parseInt(gamePlayedCount) / parseInt(gameCount)) * 100) + '%'" />
						<DashboardStatBox v-if="game100PercentCount > 0" :title="'100%'" :number="game100PercentCount" :percentage="Math.floor((parseInt(game100PercentCount) / parseInt(gameCount)) * 100) + '%'" />
						<DashboardStatBox v-if="gamePausedCount > 0" :title="'Paused'" :number="gamePausedCount" :percentage="Math.floor((parseInt(gamePausedCount) / parseInt(gameCount)) * 100) + '%'" />
						<DashboardStatBox v-if="gameBackloggedCount > 0" :title="'Backlogged'" :number="gameBackloggedCount" :percentage="Math.floor((parseInt(gameBackloggedCount) / parseInt(gameCount)) * 100) + '%'" />
						<DashboardStatBox v-if="gameQuitCount > 0" :title="'Quit'" :number="gameQuitCount" :percentage="Math.floor((parseInt(gameQuitCount) / parseInt(gameCount)) * 100) + '%'" />

					</div>

				</div>

				<div class="dashboard__container">
						<DashboardFeed v-if="recent.length > 0 && displayRecent" class="fadeIn" :title="'Recently Added'" :data="recent" :userslug="username" :query="'Recently Added'"/>
						<DashboardFeed v-if ="updated.length > 0 && displayUpdated" class="fadeIn" :title="'Recently Updated'" :data="updated"  :userslug="username" :query="'Last Updated'"/>
						<DashboardFeed v-if="missing.length > 0 && isAuthUser && displayMissing" class="fadeIn" :title="'Missing Data'" :data="missing" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import LibraryService from '@/services/libraryService'

import DashboardFeed from '@/components/dashboard/DashboardFeed.vue'
import DashboardStatBox from '@/components/dashboard/DashboardStatBox.vue'

import HelperService from '@/services/helperService'
import UserService from '@/services/userService'
import DashboardService from '@/services/dashboardService'

export default {
	name:'Dashboard',
	components: {
		DashboardFeed,
		DashboardStatBox,
	},
	data(){
		return{
			userObj:{
				discord: null,
				steam: null,
				twitter: null,
			},
			username: '',
			recent: {},
			updated: {},
			missing: {},
			gameCount: null,
			//isAuthUser: false,
			
			mounted: false,
			showheader: false,
			headerArt: false,

			displayStats: false,
			displayRecent: false,
			displayUpdated: false,
			displayMissing: false,

			userslug: null,

			limit: 5,

			gamePlayedCount: 0,
			game100PercentCount: 0,
			gamePausedCount: 0,
			gameBackloggedCount: 0,
			gameQuitCount: 0,

		}
	},
	computed:{
		isAuthUser(){
			if(this.$store.state.user.id){
				if(this.$store.state.user.slug === this.$route.params.slug)
					return true
			}
			return false
		}
	},
	async mounted(){

		this.user = (await UserService.getUserSlug(this.$route.params.slug)).data.result

		if(Object.keys(this.user).length !== 0)
			this.username = this.user[0].username
		else 
			this.$router.push('/404')

		this.userObj.discord = this.user[0].discord
		this.userObj.steam = this.user[0].steam
		this.userObj.twitter = this.user[0].twitter

		this.gameTotal = (await DashboardService.getTotalGames(this.user[0].id)).data.result
		this.gameCount = this.gameTotal[0].count

		this.gamePlayedCount = (await DashboardService.getPlayedCount(this.user[0].id)).data.result[0].count
		this.game100PercentCount = (await DashboardService.getPercentCount(this.user[0].id)).data.result[0].count;
		this.gamePausedCount = (await DashboardService.getPausedCount(this.user[0].id)).data.result[0].count
		this.gameBackloggedCount = (await DashboardService.getBackloggedCount(this.user[0].id)).data.result[0].count
		this.gameQuitCount = (await DashboardService.getQuitCount(this.user[0].id)).data.result[0].count
		
		this.displayStats = true
		this.mounted = true

		this.recent = (await DashboardService.getUserDashboardRecentLimit(this.limit, this.user[0].id)).data.result
		if(this.recent)
			this.recent = this.getCovers(this.recent)

		this.displayRecent = true


		this.updated = (await DashboardService.getUserDashboardUpdatedLimit(this.limit, this.user[0].id)).data.result
		if(this.updated)
			this.updated = this.getCovers(this.updated)

		this.displayUpdated = true


		this.missing = (await DashboardService.getUserDashboardMissingData(this.limit, this.user[0].id)).data.result
		if(this.missing)
			this.missing = this.getCovers(this.missing)
	
		this.displayMissing = true
		
	},
	methods:{

		getCovers(data){
			for(let i = 0; i < data.length; i++){
				if(!data[i].url)
					data[i].url = '/no-cover.png'
			}
			return data
		},

		copyDiscord(){
			var text = this.userObj.discord;
			navigator.clipboard.writeText(text)
			document.getElementById("discord-copy-message").classList.add('discord__copy-message--show')
			setTimeout( function() {
				document.getElementById("discord-copy-message").classList.remove('discord__copy-message--show');
			}, 1000);
		},

	}
}
</script>