<template>
	<div class="modal">
		
		<div v-if="reviewObj" class="modal__window modal__quick-add">
			<!-- <h3>{{ title }}</h3> -->
			<h4>{{ subtitle }}</h4>
			
			<label for="statusDate">Rating</label>
			<fieldset id="modal-rating" class="rating">
				<input v-model="reviewObj.rating" type="radio" id="model-rating-star5" name="modal-rating" value="5" /><label class = "full" for="model-rating-star5"   title="5 Stars"></label>
				<input v-model="reviewObj.rating" type="radio" id="model-rating-star4half" name="modal-rating" value="4.5" /><label class = "half" for="model-rating-star4half"   title="4.5 Stars"></label>
				<input v-model="reviewObj.rating" type="radio" id="model-rating-star4" name="modal-rating" value="4" /><label class = "full" for="model-rating-star4"   title="4 Stars"></label>
				<input v-model="reviewObj.rating" type="radio" id="model-rating-star3half" name="modal-rating" value="3.5" /><label class = "half" for="model-rating-star3half"   title="3.5 Stars"></label>
				<input v-model="reviewObj.rating" type="radio" id="model-rating-star3" name="modal-rating" value="3" /><label class = "full" for="model-rating-star3"   title="3 Stars"></label>
				<input v-model="reviewObj.rating" type="radio" id="model-rating-star2half" name="modal-rating" value="2.5" /><label class = "half" for="model-rating-star2half"   title="2.5 Stars"></label>
				<input v-model="reviewObj.rating" type="radio" id="model-rating-star2" name="modal-rating" value="2" /><label class = "full" for="model-rating-star2"   title="2 Stars"></label>
				<input v-model="reviewObj.rating" type="radio" id="model-rating-star1half" name="modal-rating" value="1.5" /><label class = "half" for="model-rating-star1half"   title="1.5 Star"></label>
				<input v-model="reviewObj.rating" type="radio" id="model-rating-star1" name="modal-rating" value="1" /><label class = "full" for="model-rating-star1"   title="1 Star"></label>
				<input v-model="reviewObj.rating" type="radio" id="model-rating-starhalf" name="modal-rating" value="0.5" /><label class = "half" for="model-rating-starhalf"   title="0.5 Star"></label>
			</fieldset>
			

			<label for="review">Comments</label>
			<textarea v-model="reviewObj.review" name="review" id="review"  rows="6"
				placeholder="Your thoughts about the game"></textarea>
			
			<label @click.prevent="clickCheck('reviewprivate')" for="reviewprivate">
				<span :class="{checked: reviewObj.review_private}" class="checkbox"></span>Private Review
				<input v-model="reviewObj.review_private" type="checkbox" name="reviewprivate" id="reviewprivate">
			</label>

			<button id="model-confirm-button" @click="selectConfirm($event, game, gameid)">
				<div class="button__loader-container"><div :class="checking && 'loader-show'" class="loader"></div></div>
				<div class="button__text" :class="{'button__text--hidden': checking}">{{ confirmText }}</div>
				</button>
			<button  @click="toggleReviewModal" class="button__flat">{{ cancelText }}</button>

			<div v-if="gameReviewExists" style="text-align:right;  margin:1rem 0 0 0; font-size:0.9rem;">
				<span style="cursor: pointer;" @click="deleteReview(event, gameid)">Delete Review</span>
			</div>

		</div>
		<div @click="toggleJournalModal" class="modal__background"></div>
	</div>
</template>

<script>

import PlatformService from '@/services/platformService'
import LibraryService from '@/services/libraryService'
import ReviewService from '@/services/reviewService'

export default {
	name: 'ReviewModal',
	props:['title', 'subtitle', 'confirmText', 'cancelText', 'game', 'gameid', 'review', 'gameReviewExists', 'event', 'objIndex'],
	
	data(){
		return{
			toggle: true,
			confirm: false,
			deleted: false,
			checking: false,

			form:{
				game_id: null,
				rating: '',
				ratingCommentInput: '',
				privateRatingCheck: false,
			},
			
		}
	},
	computed:{
		reviewObj(){
			return this.review[0]
		},
	},
	async mounted(){
		
	},
	methods:{
		toggleReviewModal(){
			this.$emit('toggleReviewModal', this.confirm, this.event, this.deleted, this.form, this.objIndex,)
		},

		async deleteReview(event, gameid){

			if(this.gameReviewExists){
				this.deleted = true;
				event.target.disabled = true

				let reviewResponse
				if(gameid)
					reviewResponse = (await ReviewService.deleteReview(gameid)).data.result
				else
					reviewResponse = (await ReviewService.deleteReview(this.game.id)).data.result

				this.form.rating = null

				setTimeout(function () {
					event.target.disabled = false
				}, 1000);

				this.toggleReviewModal();
				this.deleted = false
			}

		},

		async selectConfirm(event, game, gameid){
			this.confirm = true
			event.target.disabled = true
			event.target.childNodes[0].childNodes[0].classList.add('loader-show')
			event.target.childNodes[1].classList.add('button__text--hidden')

			if(gameid)
				this.form.gameid = gameid
			else
				this.form.gameid = game.id

			this.form.rating = this.reviewObj.rating 
			this.form.ratingCommentInput = this.reviewObj.review
			this.form.privateRatingCheck = this.reviewObj.review_private

			let reviewResponse = null
			try{
				if(this.gameReviewExists)
					reviewResponse = (await ReviewService.updateReview(this.form)).data.result
				else
					reviewResponse = (await ReviewService.addReview(this.form)).data.result
			} catch(err){
				console.log(err)
			}

			setTimeout(function () {
				event.target.disabled = false
				event.target.childNodes[0].childNodes[0].classList.remove('loader-show')
				event.target.childNodes[1].classList.remove('button__text--hidden')
			}, 1000);

			this.toggleReviewModal();
			this.confirm = false
		},


		clickCheck(value){
			
			if(value == "reviewprivate")
				return this.reviewObj.review_private = !this.reviewObj.review_private

			return null
		},


	}

}
</script>