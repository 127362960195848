<template>
	<div class="page">
		
		<TheLoader v-if="!mounted"></TheLoader>

		<section class="page__content" :class="{show: mounted}">
			<div class="page__wrapper">
				
				<div class="article">
					<h1 class="article__title">About</h1>
					<p><strong>Game<span class="branding">Stash</span></strong> is a game 
					collection tracking website made for serious collectors, made by a 
					<a target="_blank" href="https://twitter.com/jasonsteppdev"><span class="branding">solo developer</span></a>.</p>


					<h3>Why GameStash?</h3>
					<p>Other websites and apps do not have the tools necessary for serious collectors, 
					they are not user friendly, or they charge you money for basic features.</p>
					
					<p>As a collector I wanted something that could do everything I needed with a 
					nice interface and low cost (free). And as a developer I know how to build sites 
					that are fast and friendly.</p>

					<h3>How is it made?</h3>
					<p>All development of the site is done solo during my free 
					time. I use <a target="_blank" href="https://www.igdb.com/"><span class="branding">IGDB</span></a> 
					for all my game data, which is a crowd sourced game database run by Twitch.</p>

					<p><strong>Game<span class="branding">Stash</span></strong> is still in beta so everything you 
					see is a work in progress. Features may change, new features will be added, and you may 
					encounter bugs. But I wanted to get the site out into the public as early as I could to 
					start getting feedback. </p>

					<h3>How to support?</h3>
					<p><a target="_blank" href="https://www.igdb.com/"><span class="branding">IGDB</span></a> 
					makes it easy for the gaming community to update and add new data so anyone 
					can do it. All you need is a Twitch account.</p>

					<p>Help grow the community. Join the 
					<a target="_blank" href="https://discord.gg/bFcywSHm8h"><span class="branding">Discord</span></a>
					and let your friends know. Share your profile on social media and tag us on 
					<a target="_blank" href="https://twitter.com/GameStashApp"><span class="branding">Twitter</span></a> and 
					<a target="_blank" href="https://instagram.com/gamestashapp"><span class="branding">Instagram</span></a>. 
					</p>

					<h3>Contact</h3>
					<p>Any questions, comments, feedback, or bug reports can be sent to:
					<br><a href="mailto:support@gamestash.app"><span class="email branding">support@gamestash.app</span></a></p>
					<p>Or message me directly on 
						<a target="_blank" href="https://twitter.com/GameStashApp"><span class="branding">Twitter</span></a>, 
						<a target="_blank" href="https://instagram.com/gamestashapp"><span class="branding">Instagram</span></a>, or 
						<a target="_blank" href="https://discord.gg/bFcywSHm8h"><span class="branding">Discord</span></a>.</p>


				</div>

			</div>
		</section>

		
		
	</div>
</template>


<script>

export default {
	name: 'About',
	data(){
		return{
			games:{},
			mounted: false,
			error: ''
		}
	},
	async mounted(){
		this.mounted = true
	},
	methods:{
		formatDate(date){
			return date = new Date(date).toLocaleDateString("en-US")
		},

		releaseYear(date){
			return date = new Date(date).getFullYear()
		}
	}
}
</script>