<template>
	<div class="page">
		<div :class="{'show': mounted}" class="page__content">
			<div class="page__wrapper">

				<div :class="{'show': showheader}" :style="backgroundArt" id="header-art" class=""></div>
				<div id="fade-art" class=""></div>

				<h1 class="home__title">
					<div class="brand-color">404</div> 
					Page not found
				</h1>
				

				<div class="error__404">
					<div>Sorry that page does not exist</div>
					<div>Try using the game search to find what you are looking for</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>

import GameService from '@/services/gameService'
import ArtService from '@/services/artService'
import ScreenshotService from '@/services/screenshotService'

export default {
	name: '404',
	data(){
		return{
			games:{},
			screenshots:[],
			backgroundArt: null,
			mounted: false,
			showheader: false,
		}
	},
	async mounted(){
		
		this.games = (await GameService.getGamesPopularSpecific(6)).data.result
		
		this.mounted = true

		
		if (this.games.length > 0) {
			for(let i = 0; i < this.games.length; i++){

				let tempScreenshot = JSON.parse(JSON.stringify(this.games[i].screenshots))
				if(tempScreenshot)
					this.screenshots = this.screenshots.concat(tempScreenshot)
			}
		}

		this.temp = '{'
		if (this.screenshots.length > 0) {
			for(let i = 0; i < this.screenshots.length; i++){
				this.temp += this.screenshots[i] + ','
			}
		}
		this.temp = this.temp.slice(0, -1)
		this.temp += '}'

		if(this.screenshots)
			this.screenshots = (await ScreenshotService.getScreenshotAny(this.temp)).data.result
		
		if(this.screenshots.length > 0){
			this.backgroundArt = `background-image: url(${this.screenshots[Math.floor(Math.random() * this.screenshots.length)].url.replace("thumb", "screenshot_big").replace('.jpg', '.webp')})`
		}

		this.showheader = true

	}
}
</script>