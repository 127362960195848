
import Wishlist from "@/views/Wishlist/Wishlist.vue"

const WishlistRoutes = [
	{
		path: "/:slug/wishlist",
		name: "Wishlist",
		component: Wishlist,
		meta: {
			title: 'Wishlist - GameStash',
		},
	}

]

export default WishlistRoutes