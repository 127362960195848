<template>
	<div class="page">
		<div class="page__content" :class="{ 'fadeIn': mounted }">
			<div class="page_wrapper game">
				<div class="article">
					<h1 class="category-title">Settings</h1>
	
					<label for="email">Email</label>
					<input @blur="checkUser" v-model="form.email" name="email" type="email">

					<div :class="{'form__error--show': error.email}" class="form__error">
						<span class="material-icons">error</span>{{ error.email }}
					</div>
					

					<label for="currentpass">Current Password</label>
					<input v-model="form.password" name="currentpass" type="password">	

					<div :class="{'form__error--show': error.password}" class="form__error">
						<span class="material-icons">error</span>{{ error.password }}
					</div>

					<label for="newpass">New Password</label>
					<input v-model="form.newPassword" name="newpass" type="password">	

					<label for="confirmnewpass">Confirm Password</label>
					<input v-model="form.confirmPassword" name="confirmnewpass" type="password">

					<div :class="{'form__error--show': error.password}" class="form__error">
						<span class="material-icons">error</span>{{ error.password }}
					</div>

					<h2 class="category-title">Socials</h2>
					<label for="discord">Discord</label>
					<input v-model="form.discord" name="discord" type="text">	

					<label for="steam">Steam</label>
					<input v-model="form.steam" name="steam" type="text">	

					<label for="twitter">Twitter</label>
					<input v-model="form.twitter" name="twitter" type="text">	

					<div :class="{'form__error--show': error.currentpass}" class="form__error">
						<span class="material-icons">error</span>{{ error.currentpass }}
					</div>

					<div :class="{'form__error--show': error.password}" class="form__error">
						<span class="material-icons">error</span>{{ error.password }}
					</div>

					<div :class="{'form__error--show': error.email}" class="form__error">
						<span class="material-icons">error</span>{{ error.email }}
					</div>

					<div :class="{'form__error--show': error.generic}" class="form__error">
						<span class="material-icons">error</span>{{ error.generic }}
					</div>

					<button @click="saveForm">
						<div class="button__loader-container"><div :class="{'loader-show': checking}" class="loader"></div></div>
						<div class="button__text" :class="{'button__text--hidden': checking}">Save</div>
					</button>

				</div>


			</div>
		</div>
	</div>
</template>


<script>

import HelperService from '@/services/helperService'
import UserService from '@/services/userService'

export default {
	name: 'UserSettings',
	data(){
		return{
			mounted: false,

			form:{
				email: null,
				password: null,
				newPassword: null,
				confirmPassword: null,

				discord: null,
				steam: null,
				twitter: null,
			},

			profile:null,

			error:{
				email: null,
				username: null,
				password: null,
				generic: null,
				currentpass: null,
			},

			click: false,
			checking: false,
		}
	},
	computed:{
		user(){
			return this.$store.state.user
		},
	},
	async mounted(){
		this.profile = await HelperService.GetUser()

		if(!this.profile)
		 	this.$router.push(`/login`)
		else{
			this.form.email = this.profile[0].email
			this.form.discord = this.profile[0].discord
			this.form.twitter = this.profile[0].twitter 
			this.form.steam = this.profile[0].steam
	
			this.mounted = true
		}

	},

	methods:{
		async saveForm(e){

			this.resetErrors()
			e.target.disabled = false
			this.checking = true
			this.clicked = true

			const userCheck = await(this.checkUser())
			if(!userCheck) return

			if(!this.checkFormValid()) return

			

			try{
				let response = (await UserService.updateSettings(this.form)).data.result
			} catch(err){
				console.error(err)
				this.resetErrors()
				this.error.generic = ' Current password incorrect'
				e.target.disabled = false
				this.checking = false
				this.clicked = false
			}

			e.target.disabled = false
			this.checking = false
			this.clicked = false
		},

		checkFormValid(){
			/* eslint-disable-next-line */
			let regex = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$")

			

			if(!regex.test(this.form.email)){
				this.resetErrors()
				this.error.email = ' Enter a valid email address'
				return false
			} 

			if(this.form.email  && this.form.email != null && this.form.email.length > 255){
				this.resetErrors()
				this.error.email = ' Email length too long'
				return false
			}

			if(this.form.newPassword !== this.form.confirmPassword){
				this.resetErrors()
				this.error.password = ' Passwords do not match'
				return false
			}

			if((this.form.newPassword && this.form.newPassword != null && this.form.newPassword != '') &&
				(this.form.confirmPassword && this.form.confirmPassword != null && this.form.confirmPassword != '')){

					if(!this.form.password || this.form.password == null || this.form.password == ''){
						this.resetErrors()
						this.error.currentpass = ' Enter your current password'
						return false
					}

			}
	
			return true

		},

		async checkUser(){

			try{
				let res = (await UserService.checkUser(this.form)).data.result
				
				if(res.length > 1) {
					this.resetErrors()
					this.error.email = ' Email already in use'
					return false
				} else if(res.length > 0){
					if(res[0].id != this.user.id){
						this.resetErrors()
						this.error.email = ' Email already in use'
						return false
					}
				}
			} catch(err){
				console.log(err)
				this.resetErrors()
				this.error.generic = ' Something went wrong'
			}
			this.resetErrors()

			return true
		},

		resetErrors(){
			this.clicked = false
			this.checking = false
			this.error.email = null
			this.error.password = null
			this.error.generic = null
			this.error.username = null
		},
	},
}
</script>