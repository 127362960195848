import { createStore } from "vuex";

export default createStore({
	state: {
		token: null,
		developers: {},
		publishers: {},
		platforms: {},
		dashboard: {
			recent: {},
			updated: {},
			missing: {},
		},
		user: {
			id: null,
			slug: null,
			isAuth: false,
			isLoggedIn: false,
		}
	},
	mutations: {},
	actions: {},
	modules: {},
});
