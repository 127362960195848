window.onclick = function(event) {
	if (!event.target.matches('.dropdown__container') && 
			!event.target.matches('.dropdown__input') &&
			!event.target.matches('.dropdown__item')) {

		var sharedowns = document.getElementsByClassName("dropdown__container");
		for (let i = 0; i < sharedowns.length; i++) {
			var openSharedown = sharedowns[i];
			if (openSharedown.classList.contains('dropdown__container--show')) {
				openSharedown.classList.remove('dropdown__container--show');
			}
		}
	}

	
		var compactMenu = document.getElementsByClassName("compact__menu")
		for (let i = 0; i < compactMenu.length; i++){		
			if (compactMenu[i].classList.contains("compact__menu--show")) {
				if (!event.target.matches(`.compact__menu-button-${i}`) &&
					!event.target.matches(`.compact__menu`) && 
					!event.target.matches(`.compact__wishlist-button`)) {
					compactMenu[i].classList.remove("compact__menu--show")

					var compactMenuButton = document.getElementsByClassName(`compact__menu-button-${i}`)
					compactMenuButton[0].classList.add(`compact__menu-button--show`)
					
					var compactMenuButtonClose = document.getElementsByClassName(`compact__menu-button-close-${i}`)
					compactMenuButtonClose[0].classList.remove(`compact__menu-button-close--show`)
				}
			}
		}
	
		var gridMenu = document.getElementsByClassName("grid__menu")
		for (let i = 0; i < gridMenu.length; i++){		
			if (gridMenu[i].classList.contains("grid__menu--show")) {
				if (!event.target.matches(`.grid__menu-button-${i}`) &&
					!event.target.matches(`.grid__menu`) && 
					!event.target.matches(`.grid__wishlist-button`)) {
					gridMenu[i].classList.remove("grid__menu--show")

					var gridMenuButton = document.getElementsByClassName(`grid__menu-button-${i}`)
					gridMenuButton[0].classList.add(`grid__menu-button--show`)
					
					var gridMenuButtonClose = document.getElementsByClassName(`grid__menu-button-close-${i}`)
					gridMenuButtonClose[0].classList.remove(`grid__menu-button-close--show`)
				}
			}
		}

	

	

	if (!event.target.matches('.nav__dropdown') &&
		!event.target.matches('.nav__link') && 
		!event.target.matches('.nav') &&
		!event.target.matches('.nav__icon')) {
		var navdropdown = document.getElementsByClassName("nav__dropdown")
		if (navdropdown[0].classList.contains('nav__dropdown--show'))
			navdropdown[0].classList.remove('nav__dropdown--show')
	}

	if (!event.target.matches('.games__sort_dropdown') &&
			!event.target.matches('.games__sort_dropdown-arrow') &&
			!event.target.matches('.games__sort_dropdown-item') && 
			!event.target.matches('.games__limit_dropdown') &&
			!event.target.matches('.games__limit_dropdown-item') && 
			!event.target.matches('.games__limit_dropdown-arrow') ) {
		
		if (document.getElementById('sort-dropdown')) {
			var sortmenu = document.getElementById('sort-dropdown');
			if(sortmenu.classList.contains('games__sort_dropdown--show'))
				sortmenu.classList.remove('games__sort_dropdown--show')
		}

		if (document.getElementById('limit-dropdown')) {
			var pagemenu = document.getElementById('limit-dropdown');
			if(pagemenu.classList.contains('games__limit_dropdown--show'))
				pagemenu.classList.remove('games__limit_dropdown--show')
		}
	}
	
}