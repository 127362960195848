<template>
	<div class="loader-wrapper">
		<div class="loader loader-show loader-big"></div>
	</div>
</template>

<script>
export default {
	name: "TheLoader"
}
</script>