import api from '@/services/api'

class ArtService{
	static async getArt(id) {
		return await api().get(`/art/${id}`)
	}

	static async getArtAny(id) {
		return await api().get(`art/any/${id}`)
	}
}

export default ArtService
