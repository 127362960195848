import api from '@/services/api'

class GenreService{
	static async getGenre(id) {
		return await api().get(`/genre/${id}`)
	}

	static async getGenreAny(id) {
		return await api().get(`genre/any/${id}`)
	}

	static async getGenreAll() {
		return await api().get(`genre/all`)
	}
}

export default GenreService
