import api from '@/services/api'

class MailService{

	static async sendMail(form) {
		return await api().post(`/mail/send`, form)
	}

	static async checkToken(token, id) {
		return await api().get(`/mail/token/${token}/${id}`)
	}

}

export default MailService
