import api from '@/services/api'
import v2 from '@/services/v2'

class AuthService{

	static async getToken(user) {
		return await api().post(`/auth/token`, user)
	}

	static async authorize() {
		return await api().get(`/auth/test`)
	}

	static async authenticateToken() {
		return await api().get('/auth/authenticate')
	}

	// ========================================================================
	
	static async CreateToken(user) {
		return await v2().post('/auth/token', user)
	} 

	static async Authenticate() {
		return await v2().get(`/auth/authenticate`)
	}

	static async AuthorizeUser() {
		return await v2().get(`/auth/authorize`)
	}

	static async RefreshToken() {
		return await v2().get('/auth/refresh')
	}
}

export default AuthService