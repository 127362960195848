<template>

	<div class="dashboard__box">
		<div class="dashboard__feed">
			<div class="dashboard__feed-header">{{title}}</div>
	
			<div class="dashboard__item" v-for="game in data" :key="game.id">
				<div class="dashboard__item-cover">
					<router-link :to="{name: 'LibraryGame', params:{ id: game.id } }"><img :src="game.url" alt=""></router-link>
				</div>
				<router-link class="dashboard__item-title" :to="{name: 'LibraryGame', params:{ id: game.id } }"><span>{{game.title}}</span></router-link>
			</div>
			
	
		</div>
		<div v-if="userslug" class="dashboard__view-more">
			<router-link :to="{ name: 'Library', params: { slug: userslug }, query: { sort: query } }">View More</router-link>
		</div>
	</div>

</template>


<script>
export default {
	name: 'DashboardFeed',
	props: ['title', 'data', 'link', 'query', 'userslug'],
}
</script>