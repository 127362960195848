import axios from 'axios'

export default () => {
	const instance = axios.create({ baseURL: '/api/v2' })
	
	instance.interceptors.request.use(async req => {
		let response = await axios.get(`/api/v2/auth/refresh`)
		return req
	})

	return instance 
}
