<template>
	<div>
		<div @click="updateFilter(); toggleFilter()" id="filter-screen" class="filter-screen"></div>

		<div @click="toggleFilter" class="filter-button">
			<span class="material-icons">tune</span>
		</div>

	</div>
</template>

<script>
export default {
	name: "TheFilterButton",
	data(){
		return{

		}
	},

	methods:{
		updateFilter(){
			this.$emit('updateFilter', true)
		},

		toggleFilter(){
			const filterMenu = document.getElementsByClassName('games__filters')
			if(filterMenu)
				filterMenu[0].classList.toggle('games__filters--show')
			
			const filterScreen = document.getElementById('filter-screen')
			if(filterScreen)
				filterScreen.classList.toggle('filter-screen--show')

			const filters = document.getElementsByClassName('games__filter-group')
			for(let i = 0; i < filters.length; i++){
				if(filters[i].id !== 'platform-filter-search')
					filters[i].classList.remove('games__filter-group--show')
			}

			const searchfilter = document.getElementById('platform-filter-search')
			if(searchfilter)
				searchfilter.classList.toggle('games__filter-group--show')
		}
	},

}
</script>