
import Library from "@/views/Library/Library.vue"
import LibraryGame from "@/views/Library/LibraryGame.vue"
import LibraryAddGame from "@/views/Library/LibraryAddGame.vue"
import LibraryEditGame from "@/views/Library/LibraryEditGame.vue"

const LibraryRoutes = [
	{
		path: "/:slug/library",
		name: "Library",
		component: Library,
		meta: {
			title: 'Library - GameStash',
		},
	},

	{
		path: "/:slug/library/:id",
		name: "LibraryGame",
		component: LibraryGame,
		meta: {
			title: 'Library - GameStash',
		},
	},

	{
		path: "/library/add/:id/:url",
		name: "LibraryAddGame",
		component: LibraryAddGame,
		meta: {
			title: 'Add to my library - GameStash',
		},
	},

	{
		path: "/:slug/library/edit/:id",
		name: "LibraryEditGame",
		component: LibraryEditGame,
		meta: {
			title: 'Edit library - GameStash',
		},
	},

]

export default LibraryRoutes