<template>
	<div>
		<h1 class="home__title"><span class="brand-color">Stash</span> your game collection</h1>
		<div class="home__button">
			<router-link to="/register"><button class="home__sign-up">Sign Up</button></router-link>
			<router-link to="/login"><button class="home__sign-in">Sign In</button></router-link>
		</div>

		<div class="home__features">
			<div class="home__feature-left">
				<ul>
					<li>Game tracking for serious collectors</li>
					<li>Catalog any game for any platform</li>
					<li>Crowd sourced data from IGDB by Twitch</li>
				</ul>
			</div>
			<div class="home__feature-right">
				<ul>
					<li>100% Free and No Ads</li>
					<li>Access your collection from the web</li>
					<li>Made by a collector for collectors</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'HomeNewUser',
	data(){
		return{

		}
	},
	methods:{

	},
}
</script>