<template>
	<div class="pagination__container">
		<ul class="pagination__list">
			<li class="pagination__item pagination__item--nav" v-if="currentPage != 1" @click="gotoPage(1)">First</li>
			<li class="pagination__item pagination__item--nav" v-if="currentPage != 1" @click="gotoPage(currentPage - 1)">Prev</li>
			<li class="pagination__item" :class="{'pagination__item--current': page == currentPage}" :key="page" v-for="page in pageList" @click="gotoPage(page)">{{ page }}</li>
			<li class="pagination__item pagination__item--nav" v-if="currentPage != lastPage" @click="gotoPage(parseInt(currentPage) + 1)">Next</li>
			<li class="pagination__item pagination__item--nav" v-if="currentPage != lastPage" @click="gotoPage(lastPage)">Last</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: "BrowsePaginaton",
	props: ['gameCount', 'pageLimit', 'currentPage'],
	data(){
		return{
			paginationCurrent: 'pagination__item--current',
		}
	},
	mounted(){

	},
	computed:{
		lastPage(){
			return Math.ceil(this.gameCount / this.pageLimit)
		},

		pageList(){
			let pages = []
			let offset = 5;

			for(let i = parseInt(this.currentPage) - offset; i <= parseInt(this.currentPage) + offset; i++){
				if(i > 0 && i <= this.lastPage){
					pages.push(i)
				}
			}
			return pages
		}
	},
	methods:{
		gotoPage(page){
			this.$emit('pageClicked', page)
		}
	},
}
</script>