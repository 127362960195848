<template>
	<div class="page">

		<TheLoader v-if="!mounted"></TheLoader>
		<TheFilterButton/>

		<div class="page__content" :class="mounted && 'show'">
			
			<div :class="{'fadeIn': showheader}" :style="headerArt" id="header-art" class=""></div>
			<div id="fade-art" class="user__header-art"></div>
	
			<div class="page__wrapper">

				<h1 v-if="isAuthUser">My Library</h1>
				<h1 v-else class="game__title user__library-title"><router-link :to="{ name: 'User', slug: username }"><span class="branding">{{username}}'s</span> Library</router-link></h1>

				<div class="games__view-bar">

						<div class="games__sort">
							Sort by <span @click="showSortMenu" class="games__sort_dropdown-arrow"><span class="branding games__sort_dropdown-arrow">{{sortValue}}</span> <span class="material-icons games__sort_dropdown-arrow">arrow_drop_down</span></span>
							<ul id="sort-dropdown" class="games__sort_dropdown">
								<li @click="selectSort('Popular')" class="games__sort_dropdown-item">Popular</li>
								<li @click="selectSort('Recently Added')" class="games__sort_dropdown-item">Recently Added</li>
								<li @click="selectSort('Last Updated')" class="games__sort_dropdown-item">Last Updated</li>
								<li @click="selectSort('Title Asc')" class="games__sort_dropdown-item">Title Asc</li>
								<li @click="selectSort('Title Desc')" class="games__sort_dropdown-item">Title Desc</li>
							</ul>
						</div>

						<div class="games__per-page">
							Per Page <span @click="showLimitMenu" class="games__limit_dropdown-arrow"><span class="branding games__limit_dropdown-arrow">{{limitValue}}</span> <span class="material-icons games__limit_dropdown-arrow">arrow_drop_down</span></span>
							<ul id="limit-dropdown" class="games__limit_dropdown">
								<li @click="selectLimit(25)" class="games__limit_dropdown-item">25</li>
								<li @click="selectLimit(50)" class="games__limit_dropdown-item">50</li>
								<li @click="selectLimit(75)" class="games__limit_dropdown-item">75</li>
								<li @click="selectLimit(100)" class="games__limit_dropdown-item">100</li>
							</ul>
						</div>

						<div class="games__results">
							Results: {{ count }}
						</div>
						

						<div class="games__view-buttons">
							<button title="Compact View" @click="switchView('compact')"><span :class="{selected: compactView}" class="material-icons">view_headline</span></button>
							<button title="List View" @click="switchView('list')"><span :class="{selected: listView}" class="material-icons">view_list</span></button>
							<button title="Grid View" @click="switchView('grid')"><span :class="{selected: gridView}" class="material-icons">view_module</span></button>
						</div>

				</div>

				
		
				<div class="games__viewer">

					

					<div class="games__filters">
						<h3 class="category-title">Filter</h3>

						<button id="filter-update-button" @click="updateFilter(true)">
							<div class="button__loader-container"><div :class="checking && 'loader-show'" class="loader"></div></div>
							<div class="button__text" :class="checking && 'button__text--hidden'">Update</div>
						</button>

						<div @click="toggleFilter('playstatus-filter')" class="games__filters_mobile-button"><span class="material-icons">sports_esports</span></div>
						<div @click="toggleFilter('platform-filter')" class="games__filters_mobile-button"><span class="material-icons">games</span></div>
						<div @click="toggleFilter('developer-filter')" class="games__filters_mobile-button"><span class="material-icons">laptop_chromebook</span></div>
						<div @click="toggleFilter('publisher-filter')" class="games__filters_mobile-button"><span class="material-icons">business</span></div>

						<transition-group name="filter-dropdown" mode="out-in" appear>
							<!-- <BrowseDropdown id="tag-filter" class="games__filter-group" v-if="tags.length > 0" @fieldSelected="updatedTagPillbox" title="Tag" type="tag" :data="tags" :values="pillTagQuery"/> -->
							<BrowseDropdown id="playstatus-filter" class="games__filter-group" v-if="playstatus.length > 0" @fieldSelected="updatedPlaystatusPillbox" title="Playstatus" type="playstatus" :data="playstatus" :values="pillPlaystatusQuery"/>
							<BrowseDropdown id="platform-filter" class="games__filter-group" v-if="platforms.length > 0" @fieldSelected="updatedPlatformPillbox" title="Platform" type="platform" :data="platforms" :values="pillPlatformQuery"/>
							<BrowseDropdown id="developer-filter" class="games__filter-group" v-if="developers.length > 0" @fieldSelected="updatedDeveloperPillbox" title="Developer" type="developer" :data="developers" :values="pillDeveloperQuery"/>
							<BrowseDropdown id="publisher-filter" class="games__filter-group" v-if="publishers.length > 0" @fieldSelected="updatedPublisherPillbox" title="Publisher" type="publisher" :data="publishers" :values="pillPublisherQuery"/>
						</transition-group>
					
					</div>

					<div class="view games__view">

						
						<div class="library__toolbar">
							<div v-if="isAuthUser" class="library__search-bar">
								<TheSearchBar :location="'Library'" :title="' Search your library'"/>
							</div>
							<div v-else-if="!isAuthUser" class="library__search-bar">
								<TheSearchBar :location="'Library'" :title="` Search ${username}'s library`"/>
							</div>

							<div class="library__toolbar-buttons">
								<div @click="downloadCSV()" title="Export library to CSV" class="library__toolbar-button">Export CSV<span class="material-icons">file_download</span></div>
							</div>
						</div>

						<SearchResults @searchRemoved="removeSearch" :searchQuery="searchQuery"/>
			
						<div v-if="gameObject && gridView" class="view__grid" :class="{ disallowclick: !gridView }" >
							<GridView :class="{ show: gridView }" :auth="isAuthUser" :games="gameObject" :showcover="showcovers" @deleteLibraryGame="deleteLibraryGame"></GridView>
						</div>
			
						<div v-else-if="gameObject && listView" class="view__list" :class="{ disallowclick: !listView }" >
							<ListView :class="{ show: listView }" :auth="isAuthUser" :games="gameObject" :platforms="platforms" :showcover="showcovers" @deleteLibraryGame="deleteLibraryGame"></ListView>
						</div>
			
						<div v-else-if="gameObject && compactView" class="view__compact" :class="{ disallowclick: !compactView }" >
							<CompactView :class="{ show: compactView }" :auth="isAuthUser" :games="gameObject" :platforms="platforms" @deleteLibraryGame="deleteLibraryGame"></CompactView>
						</div>
			
						<div style="opacity: 0;" :class="{ show: noGames }" class="view__nothing">Sorry we couldn't find any games</div>
		
					</div>

					
				</div>

				<Pagination style="opacity: 0;" :class="{ show: showcovers }" v-if="count.length > 0 && limitValue && pageValue && !noGames" @pageClicked="updatePage" :gameCount="gameCount" :pageLimit="limitValue" :currentPage="pageValue" />
			</div>

		</div>

	</div>
</template>

<script>

import HelperService from '@/services/helperService'

import LibraryService from '@/services/libraryService'
import GameService from '@/services/gameService'

import GridView from '@/components/library/LibraryGridView'
import ListView from '@/components/library/LibraryListView'
import CompactView from '@/components/library/LibraryCompactView'

import BrowseDropdown from '@/components/browse/BrowseDropdown'
import TheFilterButton from '@/components/universal/TheFilterButton'
import Pagination from '@/components/browse/BrowsePagination'

import ScreenshotService from '@/services/screenshotService'
import PlatformService from '@/services/platformService'
import GenreService from '@/services/genreService'
import CompanyService from '@/services/companyService'

import ModalWindow from '@/components/universal/ModalWindow'
import TheSearchBar from '@/components/universal/TheSearchBar'
import SearchResults from '@/components/search/SearchResults'

import UserService from '@/services/userService'

export default {
	name: "Library",
	components: {
		GridView,
		ListView,
		CompactView,
		BrowseDropdown,
		TheFilterButton,
		Pagination,
		TheSearchBar,
		SearchResults,
	},
	data(){
		return{
			
			//user:{},
			profile:{},
			library:{},
			covers:{},
			objGames: {},
			
			tags:[],
			developers:[],
			platforms:[],
			publishers:[],
			playstatus:[],

			listView: false,
			compactView: false,
			gridView: true,

			selected: 'selected',
			showcovers: false,

			noGames: false,

			sortValue: 'Recently Added',
			limitValue: 25,
			pageValue: 1,
			searchQuery: null,

			count: 0,
			gameCount: null,
			pageCount: null,

			checking: false,
			mounted: false,

			pillbox:[],

			pillPublisher:[],
			pillPublisherQuery:[],

			pillDeveloper:[],
			pillDeveloperQuery:[],

			pillPlatform:[],
			pillPlatformQuery:[],

			pillTag:[],
			pillTagQuery:[],

			pillPlaystatus:[],
			pillPlaystatusQuery:[],

			deleteGame: 'Test Game',
			showModal: false,

			isAuthUser: false,
			username: '',
			headerArt: '',
			showheader: false,
		}
	},
	computed:{
		gameObject(){
			return this.objGames
		},

		user(){
			return this.$store.state.user
		}
	},
	watch:{
		user(){
			return this.$store.state.user
		}
	},
	async mounted(){

		
		//this.user = await HelperService.getAuthorizedUser()
		if(this.user && this.user.slug === this.$route.params.slug)
			this.isAuthUser = true

		this.profile = (await UserService.getUserSlug(this.$route.params.slug)).data.result
		if(this.profile)
			this.username = this.profile[0].username

		let query = this.getQuery()
		
		//this.library = (await LibraryService.getLibrary()).data.result

		//try{
			await this.getGames(query)
			
			//
			//	Define playstatus array
			//

			{
				this.playstatus = [
					{
						id: 'None',
						title: 'None'
					},
					{
						id: 'Playing',
						title: 'Playing'
					},
					{
						id: 'Played',
						title: 'Played'
					},
					{
						id: '100%',
						title: '100%'
					},
					{
						id: 'Paused',
						title: 'Paused'
					},
					{
						id: 'Backlogged',
						title: 'Backlogged'
					},
					{
						id: 'Quit',
						title: 'Quit'
					},
				]
			}
			
			//	
			//	Get Platforms from store or database if they do not exist 
			//	
			{
				if(Object.keys(this.$store.state.platforms).length === 0){
					this.platforms = (await PlatformService.getPlatformsAll()).data.result
					this.$store.state.platforms = this.platforms
				}
				else{
					this.platforms = this.$store.state.platforms
				}
			}

			//	
			//	Get Developers from store or database if they do not exist 
			//	
			{
				if(Object.keys(this.$store.state.developers).length === 0){
					this.developers = (await CompanyService.getDeveloperAll()).data.result
					this.$store.state.developers = this.developers
				}
				else{
					this.developers = this.$store.state.developers
				}
			}

			//	
			//	Get Publishers from store or database if they do not exist 
			//
			{
				if(Object.keys(this.$store.state.publishers).length === 0){
					this.publishers = (await CompanyService.getPublisherAll()).data.result
					this.$store.state.publishers = this.publishers
				}
				else{
					this.publishers = this.$store.state.publishers
				}
			}

			if(this.library && this.library.length > 0){
				let random = Math.floor(Math.random() * this.library.length) - 1
				if(isNaN(random) || random === null || random === undefined || random < 0)
					random = 0

				this.screenshots = (await ScreenshotService.getScreenshotFromGame(this.library[random].game_id)).data.result
				
				if(this.screenshots && this.screenshots.length > 0){
					let random = Math.floor(Math.random() * this.screenshots.length)
					if(random < 0)
						random = 0

					this.headerArt = `background-image: url(${this.screenshots[random].url.replace("thumb", "screenshot_big").replace('.jpg', '.webp')})`
				}
			}

			this.showheader = true

		// } catch(err){
		// 	console.error(err)
		// }

	},
	methods:{

		async downloadCSV(){
			let fullLibrary = (await LibraryService.getFullLibrary()).data.result

			const array = typeof fullLibrary !== 'object' ? JSON.parse(fullLibrary) : fullLibrary;
			let str = `${Object.keys(array[0]).map(value => `"${value}"`).join(",")}` + '\r\n';

			let jsonString =  array.reduce((str, next) => {
				str += `${Object.values(next).map(value => {
					
					if(value == null)
						return `""`
					else
						return `"${value}"`
				
				}).join(",")}` + '\r\n';
				return str;
				}, str);

			var blob = new Blob([jsonString], {type: 'text/csv;charset=utf-8;'})
			var url = URL.createObjectURL(blob)

			var downloadElement = document.createElement('a')
			downloadElement.href = url 
			downloadElement.setAttribute('download', 'export.csv')
			downloadElement.click()

		},

		updatedPublisherPillbox(value){
			this.pillPublisher = value
		},

		updatedDeveloperPillbox(value){
			this.pillDeveloper = value
		},

		updatedPlatformPillbox(value){
			this.pillPlatform = value
		},

		updatedTagPillbox(value){
			this.pillTag = value
		},

		updatedPlaystatusPillbox(value){
			this.pillPlaystatus = value
		},
		
		updateFilter(reset){

			this.checking = true

			let tmpPublishers = []
			for(let i = 0; i < this.pillPublisher.length; i++){
				tmpPublishers.push(this.pillPublisher[i].id)
			}

			let tmpDevelopers = []
			for(let i = 0; i < this.pillDeveloper.length; i++){
				tmpDevelopers.push(this.pillDeveloper[i].id)
			}

			let tmpPlatforms = []
			for(let i = 0; i < this.pillPlatform.length; i++){
				tmpPlatforms.push(this.pillPlatform[i].id)
			}

			let tmpTags = []
			for(let i = 0; i < this.pillTag.length; i++){
				tmpTags.push(this.pillTag[i].id)
			}

			let tmpPlaystatus = []
			for(let i = 0; i < this.pillPlaystatus.length; i++){
				tmpPlaystatus.push(this.pillPlaystatus[i].id)
			}


			let queryPublishers = JSON.stringify(tmpPublishers)
			let queryDevelopers = JSON.stringify(tmpDevelopers)
			let queryPlatforms = JSON.stringify(tmpPlatforms)
			let queryTags = JSON.stringify(tmpTags)
			let queryPlaystatus = JSON.stringify(tmpPlaystatus)

			if(reset)
				this.pageValue = 1

			this.$router.push(
				{ 
					name: 'Library', 
					query: 
					{ 
						publisher: queryPublishers,
						developer: queryDevelopers,
						platform: queryPlatforms,
						playstatus: queryPlaystatus,
						tag: queryTags,
						sort: this.sortValue,
						limit: this.limitValue,
						page: this.pageValue,
						search: this.searchQuery,
					}
				}).catch(()=>{})

			this.checking = false
		},

		switchView(view){
			this.gridView = false
			this.listView = false
			this.compactView = false

			if(view == 'grid')
				this.gridView = true
			
			if(view == 'list')
				this.listView = true

			if(view == 'compact')
				this.compactView = true

			this.setView(view)
		},
		
		getView(){
			let cookies = HelperService.getCookies();

			this.gridView = false
			this.listView = false
			this.compactView = false

			switch(cookies.view){
				case 'grid':
					this.gridView = true
					break;
				case 'list':
					this.listView = true
					break;
				case 'compact':
					this.compactView = true
					break;
				default:
					this.gridView = true
					break;
			
			}
		},

		setView(view){
			document.cookie = `view=${view}; max-age='${31536000}'; expires=${new Date(2147483647 * 1000).toUTCString()}; path=/`
		},

		
		getSorting(){
			let cookies = HelperService.getCookies()
			if(cookies.librarySorting && cookies.librarySorting != 'Avg Rating Asc' 
					&& cookies.librarySorting != 'Avg Rating Desc' 
					&& cookies.librarySorting != 'Upcoming'
					&& cookies.librarySorting != 'New'
					&& cookies.librarySorting != 'Trending')
				this.sortValue = cookies.librarySorting
			else{
				this.sortValue = "Popular"
				this.setSorting()
			}
		},

		setSorting(){
			document.cookie = `librarySorting=${this.sortValue}; max-age='${31536000}'; expires=${new Date(2147483647 * 1000).toUTCString()}; path=/`
		},

		showSortMenu(){
			const sortmenu = document.getElementById("sort-dropdown")
			sortmenu.classList.add('games__sort_dropdown--show')
		},

		selectSort(sort){
			this.sortValue = sort
			this.pageValue = 1
			this.setSorting()
			this.updateFilter(true)
		},


		getLimit(){
			let cookies = HelperService.getCookies()
			if(cookies.libraryLimit)
				this.limitValue = cookies.libraryLimit
			else
				this.limitValue = 25
		},

		setLimit(){
			document.cookie = `libraryLimit=${this.limitValue}; max-age='${31536000}'; expires=${new Date(2147483647 * 1000).toUTCString()}; path=/`
		},

		showLimitMenu(){
			const sortmenu = document.getElementById("limit-dropdown")
			sortmenu.classList.add('games__limit_dropdown--show')
		},


		selectLimit(limit){
			this.limitValue = limit
			this.setLimit()
			this.updateFilter(true)
		},

		toggleFilter(elementID){
			const element = document.getElementById(elementID)
			const remove = document.getElementsByClassName('games__filter-group--show')

			for(let i = 0; i < remove.length; i++){
				remove[i].classList.remove('games__filter-group--show')
			}

			if(element)
				element.classList.toggle('games__filter-group--show')
		},

		updatePage(value){
			this.pageValue = value
			this.updateFilter(false)
		},

		removeSearch(){
			this.searchQuery = null
			this.updateFilter(false)
		},


		async deleteLibraryGame(id){
			try{
				let response = (await LibraryService.deleteLibraryGame(id)).data.result
				let query = this.getQuery()
				this.getGames(query)
			} catch(err) {
				console.error(err)
			}
		},

		getQuery(){
			if(this.$route.query.publisher)
				this.pillPublisherQuery = JSON.parse(this.$route.query.publisher)
			
			if(this.$route.query.developer)
				this.pillDeveloperQuery = JSON.parse(this.$route.query.developer)
			
			if(this.$route.query.platform)
				this.pillPlatformQuery = JSON.parse(this.$route.query.platform)
			
			if(this.$route.query.tag)
				this.pillTagQuery = JSON.parse(this.$route.query.tag)

			if(this.$route.query.playstatus)
				this.pillPlaystatusQuery = JSON.parse(this.$route.query.playstatus)
				
			this.sortValue = this.getSorting()
			this.limitValue = this.getLimit()
			this.pageValue = this.$route.query.page
			this.searchQuery = this.$route.query.search

			this.userSlug = this.$route.params.slug
			
	
			this.getView()
			this.getLimit()
			this.getSorting()
		
			let query = ''
	
			if(this.$route.query.publisher)
				query += `publisher=${JSON.parse(this.$route.query.publisher)}&`
			
			if(this.$route.query.developer)
				query += `developer=${JSON.parse(this.$route.query.developer)}&`

			if(this.$route.query.platform)
				query += `platform=${JSON.parse(this.$route.query.platform)}&`

			if(this.$route.query.playstatus)
				query += `playstatus=${JSON.parse(this.$route.query.playstatus)}&`

			if(this.$route.query.search)
				query += `search=${this.$route.query.search}&`
	
			if(this.$route.query.sort)
				query += `sort=${this.$route.query.sort}&`
			else
				query += `sort=${this.sortValue}&`
			
			if(this.$route.query.limit)
				query += `limit=${this.$route.query.limit}&`
			else
				query += `limit=${this.limitValue}&`
	
			if(this.$route.query.page){
				query += `page=${this.$route.query.page}&`
				this.pageValue = this.$route.query.page
			} else {
				this.pageValue = 1
			}

			return query
		},


		async getGames(query, init = false){

			//this.library = (await LibraryService.getFilter(query)).data.result
			//this.library = (await LibraryService.getUserFilter(query, 3, this.userSlug)).data.result
			this.library = (await LibraryService.getUserFilter(query, this.userSlug)).data.result

			this.count = (await LibraryService.getUserFilterCount(query, this.userSlug)).data.result
		
			this.pageCount = Math.ceil(this.count[0].count / 48)
			this.gameCount = this.count[0].count
			this.count = this.count[0].count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			
			this.mounted = true
	
			this.covers = (await GameService.getGameCovers(this.library, true))
			this.library = (await GameService.setGameCovers(this.library, this.covers, true))

			if(init){
				
				for(let i = 0; i < this.library.length; i++){
					this.library[i].checking = false
				}
			}
		
	
			if(this.library)
				this.objGames = this.library
			else {
				this.noGames = true
				this.objGames = []
			}


			this.showcovers = true
		},


		toggleModal(){
			this.showModal = !this.showModal;
		},

	}
}
</script> 