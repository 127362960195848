import api from '@/services/api'

class LibraryService{

	static async addLibraryGame(data) {
		return await api().post(`/library/quick-add`, data)
	}

	static async addLibraryGameFull(game) {
		return await api().post(`/library/add`, game)
	}

	static async updateLibraryGameFull(game) {
		return await api().patch(`/library/edit`, game)
	}

	static async getLibrary() {
		return await api().get(`/library`)
	}

	static async getFullLibrary() {
		return await api().get(`/library/full`)
	}

	static async getLibraryGame(id, slug) {
		return await api().get(`/library/${slug}/${id}`)
	}

	static async getFilter(query) {
		return await api().get(`/library/filter?${query}`)
	}

	static async getFilterCount(query) {
		return await api().get(`/library/filter/count?${query}`)
	}

	static async deleteLibraryGame(id) {
		return await api().patch(`/library/delete`, id)
	}


	static async getUserLibrary(id, slug) {
		return await api().get(`/library/user/${id}/${slug}`)
	}

	static async getUserLibraryGame(user_id, id) {
		return await api().get(`/library/user/game/${user_id}/${id}`)
	}


	static async getUserFilter(query, slug) {
		return await api().get(`/library/user/filter/${slug}?${query}`)
	}

	static async getUserFilterCount(query,slug) {
		return await api().get(`/library/user/filter/count/${slug}?${query}`)
	}


	static async getRecentLimit(limit) {
		return await api().get(`/library/dashboard/recent/${limit}`)
	}

	static async getUpdatedLimit(limit) {
		return await api().get(`/library/dashboard/updated/${limit}`)
	}

	static async getMissingData(limit) {
		return await api().get(`/library/dashboard/missing/${limit}`)
	}


	static async getRecentLimitUser(limit, userid) {
		return await api().get(`/library/dashboard/recent/${userid}/${limit}`)
	}

	static async getUpdatedLimitUser(limit, userid) {
		return await api().get(`/library/dashboard/updated/${userid}/${limit}`)
	}

}

export default LibraryService