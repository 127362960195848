import api from '@/services/api'

class AltNameService{
	static async getAltName(id) {
		return await api().get(`/altname/${id}`)
	}

	static async getAltNameAny(id) {
		return await api().get(`/altname/any/${id}`)
	}

	static async getAltNameAll() {
		return await api().get(`/altname/all`)
	}
}

export default AltNameService
