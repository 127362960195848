import api from '@/services/api'
import ArtService from '@/services/artService'
import ScreenshotService from '@/services/screenshotService'

class GameService{
	static async getGames() {
		return await api().get('/game')
	}

	static async getFilter(query) {
		return await api().get(`/game/filter?${query}`)
	}

	static async getFilterCount(query) {
		return await api().get(`/game/filter/count?${query}`)
	}

	static async getSearch(query) {
		return await api().get(`/game/search?${query}`)
	}

	static async getSearchLimit(query) {
		return await api().get(`/game/search/limit?${query}`)
	}

	static async getGame(id, url) {
		return await api().get(`/game/${id}/${url}`)
	}

	static async getGameAny(id) {
		return await api().get(`/game/any/${id}`)
	}

	static async getGamesPopular(query) {
		return await api().get(`/game/popular/all/${query}`)
	}

	static async getGamesPopularSpecific(limit) {
		return await api().get(`/game/popular/${limit}`)
	}

	static async getGamesPopularUpcoming(limit) {
		return await api().get(`/game/popular/upcoming/${limit}`)
	}

	static async getGameHyped(limit) {
		return await api().get(`/game/hyped/${limit}`)
	}

	static async getGameSeries(collectionid,gameid) {
		return await api().get(`/game/series/${collectionid}/${gameid}`)
	}

	static async getGameCovers(games, collection = false) {
		let temp = null;

		if (games.length > 0) {
			temp = '{'

			for(let i = 0; i < games.length; i++){
				if (collection)
					temp += games[i].game_id + ','
				else
					temp += games[i].id + ','
			}

			temp = temp.slice(0, -1)
			temp += '}'

			try {
				if(temp != '}')
					return (await ArtService.getArtAny(temp)).data.result		
			} catch (err) {
				console.error(err)
			}
			
		}
		return null
	}

	static async setGameCovers(games, covers, collection = false) {

		if (games.length > 0) {
			for(let i = 0; i < games.length; i++){
				//this.temp += this.games[i].id + ','
				let obj = null
				if(collection)
					obj = covers.find(o => o.gameid === games[i].game_id)
				else
					obj = covers.find(o => o.gameid === games[i].id)
				
				if (obj)
					games[i].coverArt = obj.url.replace('.jpg', '.webp')
				else
					games[i].coverArt = '/no-cover.png'
			}
			return games
		}
		return null
	}

	static async getGameScreenshots(games) {
		let temp = null
		let screenshots = []

		if (games.length > 0) {
			for(let i = 0; i < games.length; i++){
				let tempScreenshot = JSON.parse(JSON.stringify(games[i].screenshots))
				if(tempScreenshot !== null)
					screenshots = screenshots.concat(tempScreenshot)
			}
		}

		temp = '{'
		if (screenshots.length > 0) {
			for(let i = 0; i < screenshots.length; i++){
				temp += screenshots[i] + ','
			}
		}
		temp = temp.slice(0, -1)
		temp += '}'

		try {
			if(screenshots)
				return screenshots = (await ScreenshotService.getScreenshotAny(temp)).data.result	
		} catch (err) {
			console.error(err)
		}

		return null
	}


	// static async addGame(game) {
	// 	console.log(game)
	// 	return await api().post(`/game`, game)
	// }

	// static async updateGame(id, game) {
	// 	return await api().patch(`/game/${id}`, game)
	// }

	// static async deleteGame(id) {
	// 	return await api().delete(`/game/${id}`)
	// }

	// static async addIGDBGame(game) {
	// 	return await api().post(`/game/igdb`, game)	
	// }

	// static async updateIGDBGame(game) {
	// 	console.log(game)
	// 	return await api().post(`/game/igdb/update`, game)	
	// }
}

export default GameService
