<template>
	<div class="page">

		<TheLoader v-if="!mounted"></TheLoader>
		<TheFilterButton/>

		<section class="page__content" :class="mounted && show">

			<!-- <div :class="showheader && show" :style="backgroundArt" id="header-art" class=""></div>-->
			<div id="fade" class=""></div>
	
			<div class="page__wrapper">

				<h1 v-if="user && user.slug == username">My Wishlist</h1>
				<h1 v-else class="game__title user__library-title"><router-link :to="{ name: 'User', slug: username }"><span class="branding">{{username}}'s</span> Wishlist</router-link></h1>
				
				<div class="games__view-bar">

					<div class="games__sort">
						Sort by <span @click="showSortMenu" class="games__sort_dropdown-arrow"><span class="branding games__sort_dropdown-arrow">{{sortValue}}</span> <span class="material-icons games__sort_dropdown-arrow">arrow_drop_down</span></span>
						<ul id="sort-dropdown" class="games__sort_dropdown">
							<li @click="selectSort('Title Asc')" class="games__sort_dropdown-item">Title Asc</li>
							<li @click="selectSort('Title Desc')" class="games__sort_dropdown-item">Title Desc</li>
							<li @click="selectSort('Popular')" class="games__sort_dropdown-item">Popular</li>
							<li @click="selectSort('Trending')" class="games__sort_dropdown-item">Trending</li>
							<li @click="selectSort('New')" class="games__sort_dropdown-item">New</li>
							<li @click="selectSort('Upcoming')" class="games__sort_dropdown-item">Upcoming</li>
						</ul>
					</div>

					<div class="games__per-page">
						Per Page <span @click="showLimitMenu" class="games__limit_dropdown-arrow"><span class="branding games__limit_dropdown-arrow">{{limitValue}}</span> <span class="material-icons games__limit_dropdown-arrow">arrow_drop_down</span></span>
						<ul id="limit-dropdown" class="games__limit_dropdown">
							<li @click="selectLimit(25)" class="games__limit_dropdown-item">25</li>
							<li @click="selectLimit(50)" class="games__limit_dropdown-item">50</li>
							<li @click="selectLimit(75)" class="games__limit_dropdown-item">75</li>
							<li @click="selectLimit(100)" class="games__limit_dropdown-item">100</li>
						</ul>
					</div>

					<div v-if="resultsMounted" class="games__results fadeIn">
						Results: {{ count }}
					</div>
					

					<div class="games__view-buttons">
						<button title="Compact View" @click="switchView('compact')"><span :class="{selected: compactView}" class="material-icons">view_headline</span></button>
						<button title="List View" @click="switchView('list')"><span :class="{selected: listView}" class="material-icons">view_list</span></button>
						<button title="Grid View" @click="switchView('grid')"><span :class="{selected: gridView}" class="material-icons">view_module</span></button>
					</div>

				</div>

				<div class="games__viewer">
					<div class="games__filters">
						<h3 class="category-title">Filter</h3>

						<button id="filter-update-button" @click="updateFilter(true)">
							<div class="button__loader-container"><div :class="checking && 'loader-show'" class="loader"></div></div>
							<div class="button__text" :class="checking && 'button__text--hidden'">Update</div>
						</button>

						<div @click="toggleFilter('platform-filter')" class="games__filters_mobile-button"><span class="material-icons">games</span></div>
						<div @click="toggleFilter('developer-filter')" class="games__filters_mobile-button"><span class="material-icons">laptop_chromebook</span></div>
						<div @click="toggleFilter('publisher-filter')" class="games__filters_mobile-button"><span class="material-icons">business</span></div>

						<transition-group name="filter-dropdown" mode="out-in" appear>
							<BrowseDropdown id="platform-filter" class="games__filter-group" v-if="platforms.length > 0" @fieldSelected="updatedPlatformPillbox" title="Platform" type="platform" :data="platforms" :values="pillPlatformQuery"/>
							<BrowseDropdown id="developer-filter" class="games__filter-group" v-if="developers.length > 0" @fieldSelected="updatedDeveloperPillbox" title="Developer" type="developer" :data="developers" :values="pillDeveloperQuery"/>
							<BrowseDropdown id="publisher-filter" class="games__filter-group" v-if="publishers.length > 0" @fieldSelected="updatedPublisherPillbox" title="Publisher" type="publisher" :data="publishers" :values="pillPublisherQuery"/>
						</transition-group>
					
					</div>

					<div class="view games__view">

						<div v-if="user && user.slug === username" class="library__search-bar">
							<TheSearchBar :location="'Wishlist'" :title="' Search your wishlist'"/>
						</div>
						<div v-else class="library__search-bar">
							<TheSearchBar :location="'Wishlist'" :title="` Search ${username}'s wishlist`"/>
						</div>

						<SearchResults @searchRemoved="removeSearch" :searchQuery="searchQuery"/>

						<div v-if="gameObject && gridView" class="view__grid" :class="{ disallowclick: !gridView }" >
							<GridView :class="{ show: gridView }" :games="gameObject" :showcover="showcovers"></GridView>
						</div>

						<div v-if="gameObject && listView" class="view__list" :class="{ disallowclick: !listView }" >
							<ListView v-if="platforms.length > 0" :class="{ show: listView }" :games="gameObject" :platforms="platforms" :showcover="showcovers" @unWishlist="unWishlist"></ListView>
						</div>

						<div v-if="gameObject && compactView" class="view__compact" :class="{ disallowclick: !compactView }" >
							<CompactView v-if="platforms.length > 0" :class="{ show: compactView }" :games="gameObject" :platforms="platforms" @unWishlist="unWishlist"></CompactView>
						</div>
						
						<div style="opacity: 0;" :class="{ show: noGames }" class="view__nothing">Sorry we couldn't find any games that match what you are looking for</div>

					</div>
				</div>

				<Pagination style="opacity: 0;" :class="{ show: showcovers }" v-if="count.length > 0 && limitValue && pageValue && !noGames" @pageClicked="updatePage" :gameCount="gameCount" :pageLimit="limitValue" :currentPage="pageValue" />


			</div>
	
		</section>
		
	</div>
</template>

<script>


import HelperService from '@/services/helperService'

import GameService from '@/services/gameService'
import PlatformService from '@/services/platformService'
import GenreService from '@/services/genreService'
import CompanyService from '@/services/companyService'
import WishlistService from '@/services/wishlistService'
import UserService from '@/services/userService'


import GridView from '@/components/search/GridView'
import ListView from '@/components/search/ListView'
import CompactView from '@/components/search/CompactView'

import BrowseDropdown from '@/components/browse/BrowseDropdown'
import TheFilterButton from '@/components/universal/TheFilterButton'
import Pagination from '@/components/browse/BrowsePagination'
import SearchResults from '@/components/search/SearchResults'
import TheSearchBar from '@/components/universal/TheSearchBar'

export default {
	name: "Wishlist",
	components: {
		GridView,
		ListView,
		CompactView,
		BrowseDropdown,
		TheFilterButton,
		Pagination,
		SearchResults,
		TheSearchBar,
	},
	data(){
		return{
			games:{},
			count: {},
			gameCount: null,
			pageCount: null,
			objGames:{},
			covers:[],
			screenshots:[],
			platforms:[],
			objPlatforms:[],
			objDevelopers:[],
			
			genres:[],
			developers:[],
			publishers:[],

			pillbox:[],
			pillPublisher:[],
			pillPublisherQuery:[],
			pillDeveloper:[],
			pillDeveloperQuery:[],
			pillPlatform:[],
			pillPlatformQuery:[],


			selectedPlatform: null,
			selectedPlatformID: null,
			searchedID: '',

			selectedDeveloper: null,
			selectedDeveloperID: null,
			searchedDeveloperID: '',

			sortValue: 'Popular',
			limitValue: 25,
			pageValue: 1,
			searchQuery: null,

			mounted: false,
			resultsMounted: false,
			showheader: false,
			showcovers: false,
			backgroundArt: null,
			gridView: true,
			listView: false,
			compactView: false,

			error: '',
			show: 'show',
			selected: 'selected',
			asc: true,
			noGames: false,
			checking: false,

			page: 1,
			limit: 12,

			username: '',
			headerArt: '',

			userSlug: '',
			

		}
	},
	computed:{
		user(){
			return this.$store.state.user
		},

		queryString(){
			return this.$route.query
		},

		gameObject(){
			return this.objGames
		},

		platformsObject(){
			return this.objPlatforms
		},

		developersObject(){
			return this.objDevelopers
		},
	},
	watch:{
		user(){
			return this.$store.state.user
		}
	},
	beforeMount() {
		
	},
	async mounted(){
		try{

			this.profile = (await UserService.getUserSlug(this.$route.params.slug)).data.result
			if(this.profile)
				this.username = this.profile[0].username

			
			let query = this.getQuery()
			try{
				await this.getGames(query)
			} catch(err){
				console.log(err)
			}
			


			// this.screenshots = (await GameService.getGameScreenshots(this.games))
			// if(this.screenshots.length > 0){
			// 	this.backgroundArt = `background-image: url(${this.screenshots[Math.floor(Math.random() * this.screenshots.length)].url.replace("thumb", "screenshot_big")})`
			// }
			// this.showheader=true
			// this.genres = (await GenreService.getGenreAll()).data.result



			//	
			//	Get Platforms from store or database if they do not exist 
			//	
			{
				if(Object.keys(this.$store.state.platforms).length === 0){
					this.platforms = (await PlatformService.getPlatformsAll()).data.result
					this.$store.state.platforms = this.platforms
				}
				else{
					this.platforms = this.$store.state.platforms
				}
			}

			//	
			//	Get Developers from store or database if they do not exist 
			//	
			{
				if(Object.keys(this.$store.state.developers).length === 0){
					this.developers = (await CompanyService.getDeveloperAll()).data.result
					this.$store.state.developers = this.developers
				}
				else{
					this.developers = this.$store.state.developers
				}
			}

			//	
			//	Get Publishers from store or database if they do not exist 
			//
			{
				if(Object.keys(this.$store.state.publishers).length === 0){
					this.publishers = (await CompanyService.getPublisherAll()).data.result
					this.$store.state.publishers = this.publishers
				}
				else{
					this.publishers = this.$store.state.publishers
				}
			}




			
			

			
		} catch(err){
			console.error(err)
		}
		
	},
	methods:{

		updateFilter(reset){

			this.checking = true

			let tmpPublishers = []
			for(let i = 0; i < this.pillPublisher.length; i++){
				tmpPublishers.push(this.pillPublisher[i].id)
			}

			let tmpDevelopers = []
			for(let i = 0; i < this.pillDeveloper.length; i++){
				tmpDevelopers.push(this.pillDeveloper[i].id)
			}

			let tmpPlatforms = []
			for(let i = 0; i < this.pillPlatform.length; i++){
				tmpPlatforms.push(this.pillPlatform[i].id)
			}


			let queryPublishers = JSON.stringify(tmpPublishers)
			let queryDevelopers = JSON.stringify(tmpDevelopers)
			let queryPlatforms = JSON.stringify(tmpPlatforms)

			if(reset)
				this.pageValue = 1

			this.$router.push(
				{ 
					name: 'Wishlist', 
					query: 
					{ 
						publisher: queryPublishers,
						developer: queryDevelopers,
						platform: queryPlatforms,
						sort: this.sortValue,
						limit: this.limitValue,
						page: this.pageValue,
						search: this.searchQuery,
					}
				}).catch(()=>{})

			this.checking = false
		},

		updatedPublisherPillbox(value){
			this.pillPublisher = value
		},

		updatedDeveloperPillbox(value){
			this.pillDeveloper = value
		},

		updatedPlatformPillbox(value){
			this.pillPlatform = value
		},

		updatePage(value){
			this.pageValue = value
			this.updateFilter(false)
		},

		formatDate(date){
			return date = new Date(date).toLocaleDateString("en-US")
		},

		releaseYear(date){
			return date = new Date(date).getFullYear()
		},

		titleToURL(title){
			return title.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "-")
		},

		switchView(view){
			this.gridView = false
			this.listView = false
			this.compactView = false

			if(view == 'grid')
				this.gridView = true
			
			if(view == 'list')
				this.listView = true

			if(view == 'compact')
				this.compactView = true

			this.setView(view)
		},
		

		getView(){
			let cookies = HelperService.getCookies();

			this.gridView = false
			this.listView = false
			this.compactView = false

			switch(cookies.view){
				case 'grid':
					this.gridView = true
					break;
				case 'list':
					this.listView = true
					break;
				case 'compact':
					this.compactView = true
					break;
				default:
					this.gridView = true
					break;
			
			}
		},


		setView(view){
			document.cookie = `view=${view}; max-age='${31536000}'; expires=${new Date(2147483647 * 1000).toUTCString()}; path=/`
		},


		getSorting(){
			let cookies = HelperService.getCookies()
			if(cookies.sorting && cookies.sorting != 'Recently Added' && cookies.sorting != 'Last Updated')
				this.sortValue = cookies.sorting
			else{
				this.sortValue = "Popular"
				this.setSorting()
			}
		},

		setSorting(){
			document.cookie = `sorting=${this.sortValue}; max-age='${31536000}'; expires=${new Date(2147483647 * 1000).toUTCString()}; path=/`
		},

		showSortMenu(){
			const sortmenu = document.getElementById("sort-dropdown")
			sortmenu.classList.add('games__sort_dropdown--show')
		},

		selectSort(sort){
			this.sortValue = sort
			this.pageValue = 1
			this.setSorting()
			this.updateFilter(true)
		},


		getLimit(){
			let cookies = HelperService.getCookies()
			if(cookies.limit)
				this.limitValue = cookies.limit
			else
				this.limitValue = 25
		},

		setLimit(){
			document.cookie = `limit=${this.limitValue}; max-age='${31536000}'; expires=${new Date(2147483647 * 1000).toUTCString()}; path=/`
		},

		showLimitMenu(){
			const sortmenu = document.getElementById("limit-dropdown")
			sortmenu.classList.add('games__limit_dropdown--show')
		},


		selectLimit(limit){
			this.limitValue = limit
			this.setLimit()
			this.updateFilter(true)
		},


		toggleFilter(elementID){
			const element = document.getElementById(elementID)
			const remove = document.getElementsByClassName('games__filter-group--show')

			for(let i = 0; i < remove.length; i++){
				remove[i].classList.remove('games__filter-group--show')
			}

			if(element)
				element.classList.toggle('games__filter-group--show')
		},


		removeSearch(){
			this.searchQuery = null
			this.updateFilter(false)
		},

		async unWishlist(id){
			try{
				let response = (await WishlistService.removeWishlist(id.id)).data.result
				let query = this.getQuery()
				this.getGames(query)
			} catch(err){
				console.log(err)
			}
		},

		getQuery(){
			if(this.$route.query.publisher)
			this.pillPublisherQuery = JSON.parse(this.$route.query.publisher)
		
			if(this.$route.query.developer)
				this.pillDeveloperQuery = JSON.parse(this.$route.query.developer)

			if(this.$route.query.platform)
				this.pillPlatformQuery = JSON.parse(this.$route.query.platform)

			this.sortValue = this.getSorting()
			this.limitValue = this.getLimit()
			this.pageValue = this.$route.query.page
			this.searchQuery = this.$route.query.search

			this.userSlug = this.$route.params.slug

			this.getView()
			this.getLimit()
			this.getSorting()

			let query = ''

			if(this.$route.query.publisher)
				query += `publisher=${JSON.parse(this.$route.query.publisher)}&`
			
			if(this.$route.query.developer)
				query += `developer=${JSON.parse(this.$route.query.developer)}&`

			if(this.$route.query.platform)
				query += `platform=${JSON.parse(this.$route.query.platform)}&`

			if(this.$route.query.search)
				query += `search=${this.$route.query.search}&`

			if(this.$route.query.sort)
				query += `sort=${this.$route.query.sort}&`
			else
				query += `sort=${this.sortValue}&`
			
			if(this.$route.query.limit)
				query += `limit=${this.$route.query.limit}&`
			else
				query += `limit=${this.limitValue}&`

			if(this.$route.query.page){
				query += `page=${this.$route.query.page}&`
				this.pageValue = this.$route.query.page
			} else {
				this.pageValue = 1
			}

			return query
		},

		async getGames(query, init = false){
			

			this.games = (await WishlistService.getFilter(query, this.userSlug)).data.result
			
			this.count = (await WishlistService.getFilterCount(query, this.userSlug)).data.result
			this.pageCount = Math.ceil(this.count[0].count / 48)
			this.gameCount = this.count[0].count
			this.count = this.count[0].count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

			this.resultsMounted = true
			this.mounted = true

			this.covers = (await GameService.getGameCovers(this.games))
			this.games = (await GameService.setGameCovers(this.games, this.covers))

			if(init){
				
				for(let i = 0; i < this.games.length; i++){
					this.games[i].checking = false
				}
			}

			if(this.games)
				this.objGames = this.games
			else {
				this.noGames = true
				this.objGames = []
			}
			
			this.showcovers = true
		}

	}
}
</script>
