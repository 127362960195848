<template>
	<div class="page">

		<section v-if="oldLink" class="page__content" :class="{show: oldLink}">
			<div class="page__wrapper">
				<div class="game" style="text-align:center; align-items:center; font-size:1.1rem;">Password reset link has expired</div>
			</div>
		</section>

		<section class="page__content" :class="{show: mounted}">
			<div class="page__wrapper">
				<div class="article">
					<h1>Change Password</h1>
					
					<form @submit.prevent>
						
						<label for="password">Password</label>
						<input v-model="form_data.password" type="password" name="password" required>

						<label for="confirmPassword">Confirm Password</label>
						<input v-model="form_data.confirm" type="password" name="confirmPassword" required>
						<div :class="{'form__error--show': error.password}" class="form__error">
							<span class="material-icons">error</span> {{ error.password }}
						</div>

						<div :class="{'form__error--show': error.generic}" class="form__error">
							<span class="material-icons">error</span>{{error.generic}}
						</div>


						<button @click="registerUser">
							<div class="button__loader-container"><div :class="checking && loader" class="loader"></div></div>
							<div class="button__text" :class="checking && 'button__text--hidden'">Update</div>
						</button>
						
					</form>
				</div>
			</div>
		</section>

		
	</div>
</template>


<script>

import UserService from '@/services/userService'
import AuthService from '@/services/authService'
import HelperService from '@/services/helperService'
import MailService from '@/services/mailService'

export default {
	name: 'UserReset',
	data(){
		return{
			form_data:{
				id: null,
				token: null,
				password: null,
				confirm: null,
			},
			error:{
				password: null,
				generic: null,
			},
			clicked: false,
			loader: 'loader-show',
			mounted: false,
			checking: false,
			oldLink: false,
			newUser: {}
		}
	},
	computed:{
		user(){
			return this.$store.state.user.id
		},
	},
	watch:{
		user(){
			if(this.$store.state.user.id)
				this.$router.push(`/`)
		}
	},
	async mounted(){
		let tokenCheck = await(this.checkToken())
		if(!tokenCheck)
			this.oldLink = true
		else
			this.mounted = true
	},
	methods:{
		async registerUser(e){

			this.resetErrors()
			e.target.disabled = false
			this.checking = true
			this.clicked = true

			if(!this.checkFormValid()) return
			
			const tokenCheck = await(this.checkToken())
			if(!tokenCheck) return

			
			e.target.disabled = true
			this.form_data.id = this.$route.query.id
			this.form_data.token = this.$route.query.token
			
			this.newUser = (await UserService.updatePassword(this.form_data)).data.result
			
			let response = await AuthService.CreateToken(this.newUser[0])
			if(response){
				this.$store.state.user.isLoggedIn = true
				this.$store.state.user.isAuth = true
				this.$store.state.user.slug = this.newUser[0].slug
				this.$store.state.user.id = this.newUser[0].id
				this.$router.push('/profile')
			}

			this.checking = false
			this.clicked = false
			e.target.disabled = false
		},

		checkFormValid(){

			if(this.form_data.password !== this.form_data.confirm){
				this.resetErrors()
				this.error.password = ' Passwords do not match'
				return false
			}

			if(!this.form_data.password || !this.form_data.confirm){
				this.resetErrors()
				this.error.password = ' Enter your password'
				return false
			} 

			return true
		},

		async checkToken(){

			try{
				let res = (await MailService.checkToken(this.$route.query.token, this.$route.query.id)).data.result
				if(res.length < 1) {
					this.resetErrors()
					this.error.generic = ' Invalid link. Try resetting again.'
					return false
				} else {
					// Get the current Epoch Time in ms - the link creation time in seconds 
					// Check if difference is greater than 10 minutes
					if(((Date.now() / 1000) - res[0].created_on) / 60 > 10){
						this.resetErrors()
						this.error.generic = ' Link expired. Try resetting again.'
						return false
					} else {
						return res
					}
				}
			} catch(err){
				console.log(err)
				this.resetErrors()
				this.error.generic = ' Crashed'
				return false
			}

		},

		resetErrors(){
			this.clicked = false
			this.checking = false
			this.error.email = null
			this.error.password = null
			this.error.generic = null
			this.error.username = null
		},

	}
}
</script>