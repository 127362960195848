<template>
	<div class="modal">
		
		<div v-if="journalObj" class="modal__window modal__quick-add">
			<!-- <h3>{{ title }}</h3> -->
			<h4>{{ subtitle }}</h4>
			
			<label for="statusDate">Play Status</label>
			<select v-model="journalObj.game_status" name="ownership" id="ownership">
				<option value="Playing">Playing</option>
				<option value="Played">Played</option>
				<option value="100%">100%</option>
				<option value="Paused">Paused</option>
				<option value="Backlogged">Backlogged</option>
				<option value="Quit">Quit</option>
			</select>

			<label for="statusDate">Status Date</label>
			<input style="max-width:200px" v-model="journalObj.status_date" type="date" name="statusDate" id="gameStatusDate">

			<label for="othernotes">Comments</label>
			<textarea v-model="journalObj.notes" name="othernotes" id="othernotes"  rows="6"
				placeholder="General comments about the game and not this specific copy"></textarea>
			
			<label @click.prevent="clickCheck('journalpublic')" for="journalpublic">
				<span :class="{checked: journalObj.journal_private}" class="checkbox"></span>Private Journal
				<input v-model="journalObj.journal_private" type="checkbox" name="journalpublic" id="journalpublic">
			</label>

			<button id="model-confirm-button" @click="selectConfirm($event, game, gameid)">
				<div class="button__loader-container"><div :class="checking && 'loader-show'" class="loader"></div></div>
				<div class="button__text" :class="{'button__text--hidden': checking}">{{ confirmText }}</div>
				</button>
			<button  @click="toggleJournalModal" class="button__flat">{{ cancelText }}</button>

			<div v-if="gameJournalExists" style="text-align:right;  margin:1rem 0 0 0; font-size:0.9rem;">
				<span style="cursor: pointer;" @click="deleteJournal(event, gameid)">Delete Journal</span>
			</div>

		</div>
		<div @click="toggleJournalModal" class="modal__background"></div>
	</div>
</template>

<script>

import PlatformService from '@/services/platformService'
import LibraryService from '@/services/libraryService'
import JournalService from '@/services/journalService'

export default {
	name: 'GameJournalModal',
	props:['title', 'subtitle', 'confirmText', 'cancelText', 'game', 'gameid', 'journal', 'gameJournalExists', 'event', 'objIndex'],
	
	data(){
		return{
			toggle: true,
			confirm: false,
			deleted: false,
			checking: false,
			gameStatus: null,
			journalPrivate: null,
			gameDate: null,
			form:{
				gameid: null,
				gameStatusInput: '',
				gameStatusDate: null,
				otherNotesInput: '',
			},
			
		}
	},
	computed:{
		journalObj(){
			return this.journal[0]
		},
	},
	async mounted(){
		
	},
	methods:{
		toggleJournalModal(){
			this.$emit('toggleJournalModal', this.confirm, this.event, this.deleted, 
					this.objIndex, this.gameStatus, this.gameDate, this.journalPrivate, this.journalNotes)
		},

		async deleteJournal(event, gameid){
			
			if(this.gameJournalExists){
				this.deleted = true;
				event.target.disabled = true
				
				let journalResponse
				if(gameid)
					journalResponse = (await JournalService.deleteJournal(gameid)).data.result
				else
					journalResponse = (await JournalService.deleteJournal(this.game.id)).data.result

				this.gameStatus = null
				this.journalPrivate = null

				setTimeout(function () {
					event.target.disabled = false
				}, 1000);

				this.toggleJournalModal();
				this.deleted = false
			}
			

		},

		async selectConfirm(event, game, gameid){
			this.confirm = true
			event.target.disabled = true
			event.target.childNodes[0].childNodes[0].classList.add('loader-show')
			event.target.childNodes[1].classList.add('button__text--hidden')

			if(gameid)
				this.form.gameid = gameid
			else
				this.form.gameid = game.id

			this.form.gameStatusInput = this.journalObj.game_status
			this.form.gameStatusDate = this.journalObj.status_date
			this.form.otherNotesInput = this.journalObj.notes
			this.form.journalPrivate = this.journalObj.journal_private

			let journalResponse = null
			try{

				if(this.gameJournalExists)
					journalResponse = (await JournalService.updateJournal(this.form)).data.result
				else
					journalResponse = (await JournalService.addJournal(this.form)).data.result
			} catch(err){
				console.log(err)
			}

			
			this.gameStatus = this.journalObj.game_status 
			this.gameDate = this.journalObj.status_date
			this.journalPrivate = this.journalObj.journal_private
			this.journalNotes = this.journalObj.notes

			setTimeout(function () {
				event.target.disabled = false
				event.target.childNodes[0].childNodes[0].classList.remove('loader-show')
				event.target.childNodes[1].classList.remove('button__text--hidden')
			}, 1000);

			this.toggleJournalModal();
			this.confirm = false
		},


		clickCheck(value){
			
			if(value == "journalpublic")
				return this.journalObj.journal_private = !this.journalObj.journal_private

			return null
		},


	}

}
</script>