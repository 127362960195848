<template>
	<div style="position:relative" class="header__search">
		<input @keyup.enter="search()" v-model="searchQuery" class="header__search-input" type="search" name="search" :placeholder="'&#xF002;' + title" style="">
		<!-- <input @keyup.enter="search()" @keyup="lookup"  @blur="clearData" v-model="searchQuery" class="header__search-input" type="text" name="search" placeholder="&#xF002; Search for a game" style="">
		<div class="header__search-dropdown">
			<div style="max-height:100px; overflow:hidden;" class="header__dropdown-item" v-for="game in objGames" :key="game.id">
				<div class="header__dropdown-title"><router-link @mousedown.prevent="" @click="clearData" :to="{name: 'Game', params:{id: game.id, url: game.slug}}">{{game.title}}</router-link></div>
			</div>
		</div> -->
	</div>
</template>

<script>

import GameService from '@/services/gameService'

export default {
	name: 'TheSearchBar',
	props: ['location', 'title'],
	data(){
		return{
			games:[],
			objGames:[],
			searchQuery: '',
			message: '',
			showcover: false,
			prevTime: Date.now(),
			nextTime: null,
		}
	},
	methods:{
		search(){
			this.clearData()
			this.$router.push({ name: this.location, 
				query: { 
					search: this.searchQuery,
					publisher: this.$route.query.publisher,
					developer: this.$route.query.developer,
					platform: this.$route.query.platform,
					sort: this.$route.query.sort,
					limit: this.$route.query.limit,
					page: 1,
				}
			})
			this.searchQuery = ''
		},

		async lookup(){
			if(this.searchQuery.length === 3 || (this.searchQuery.length > 3 && this.objGames.length <= 10)){
				await this.getData()
				this.searchFields()
			}		
			else if(this.searchQuery.length === 0)
				this.clearData()
			else
				this.searchFields()
		},

		async getData(){
				this.games = (await GameService.getFilterLimit(`search=${this.searchQuery}`)).data.result
				//this.covers = (await GameService.getGameCovers(this.games))
				//this.games = (await GameService.setGameCovers(this.games, this.covers))
				this.showcover = true
		},

		clearData(){
			this.games = []
			this.objGames = []
		},

		
		searchFields(){
			this.objGames = []

			if(this.searchQuery.length > 2){
				for(let i = 0; i < this.games.length; i++){
					if(this.games[i].title.toLowerCase().includes(this.searchQuery.toLowerCase())){
						this.objGames.push(this.games[i])
					}
				}
			}

			//document.getElementById(this.title + '-dropdown').classList.add('dropdown__container--show')

		},


	}
}
</script>