import api from '@/services/api'

class JournalService{

	static async getJournal(game_id, user_id) {
		return await api().get(`/journals/${game_id}/${user_id}`)
	}

	static async getAuthJournal(game_id) {
		return await api().get(`/journals/auth/${game_id}`)
	}

	static async addJournal(journal) {
		return await api().post(`/journals/add`, journal)
	}

	static async updateJournal(journal) {
		return await api().patch(`/journals/update`, journal)
	}

	static async deleteJournal(game_id) {
		return await api().delete(`/journals/${game_id}`)
	}

}

export default JournalService
