import api from '@/services/api'

class ReviewService{

	static async getReview(game_id, user_id) {
		return await api().get(`/reviews/${game_id}/${user_id}`)
	}

	static async getAuthReview(game_id) {
		return await api().get(`/reviews/auth/${game_id}`)
	}

	static async addReview(review) {
		return await api().post(`/reviews/add`, review)
	}

	static async updateReview(review) {
		return await api().patch(`/reviews/update`, review)
	}

	static async updateReviewRating(review) {
		return await api().patch(`/reviews/update/rating`, review)
	}

	static async deleteReview(game_id) {
		return await api().delete(`/reviews/${game_id}`)
	}

}

export default ReviewService
