<template>
	<div class="page">
		
		<TheLoader v-if="!mounted"></TheLoader>

		<QuickAddModalWindow :class="{'modal--show': showModal}" @toggleModal="emittoggleModal" :title="`Add to library`" :subtitle="quickAddGame.title" :game="quickAddGame" :platforms="quickAddPlatforms" confirmText="Add" cancelText="Cancel"  />
		<GameJournalModal :class="{'modal--show': showJournalModal}" @toggleJournalModal="toggleJournalModal" :title="`Add to library`" :subtitle="`${journalGame.title}`" :game="journalGame" :journal="journal" :event="event" :gameJournalExists="gameJournalExists" :confirmText="journalConfirmText" cancelText="Cancel"  />

		<ReviewModal :class="{'modal--show': showReviewModal}" @toggleReviewModal="toggleReviewModal" :title="`Add review`" :subtitle="`${reviewGame.title}`" :game="reviewGame" :review="review" :event="event" :gameReviewExists="gameReviewExists" :confirmText="reviewConfirmText" cancelText="Cancel"  />

		<div class="page__content" :class="{ 'fadeIn': mounted }">
			
			<div class="game" :key="game.id" v-for="game in games">
				
				<div :class="{'fadeIn': showheader}" :style="headerArt" id="header-art" class=""></div>
				<div id="fade-art" class=""></div>


				<div class="game__grid">
					

					<div class="cover-block">

						

						<div>
							<div :class="{'fadeIn': showcover}" class="game__cover">
								<div v-if="objGameStatus && user" class="list-view__game-status library__game-status game__game-status">
									<span v-if="objGameStatus === 'Playing'" class="playing">{{objGameStatus}}</span>
									<span v-else-if="objGameStatus === 'Played'" class="played">{{objGameStatus}}</span>
									<span v-else-if="objGameStatus === 'Backlogged'" class="backlogged">{{objGameStatus}}</span>
									<span v-else-if="objGameStatus === 'Quit'" class="quit">{{objGameStatus}}</span>
									<span v-else-if="objGameStatus === 'Paused'" class="paused">{{objGameStatus}}</span>
									<span v-else-if="objGameStatus === '100%'" class="one-hundred">100%</span>
								</div>
								<img v-if="game.coverArt" :src="game.coverArt" />
								<div class="game__cover-none" v-if="game.coverArt == '/no-cover.png'">No Cover</div>
							</div>
						</div>

						<div class="game__ui" v-if="user.isAuth">
						
							<div title="Add" class="game__button game__add-button">
								<router-link :to="{name: 'LibraryAddGame', params:{ id: game.id, url: game.slug } }"><span class="material-icons">playlist_add</span></router-link>
							</div>
							
							<button title="Quick Add" @click="quickAdd($event, game)" class="game__quick-add-button">
								<div class="button__loader-container"><div class="loader"></div></div>
								<div class="button__text"><span class="material-icons">add</span></div>
							</button>

							<button title="Wishlist" @click="wishlist($event, game)" class="game__wishlist-button">
								<span v-if="game.wished" class="material-icons favorited">favorite</span>
								<span v-else class="material-icons">favorite_border</span>
							</button>

							<button title="Journal" @click="journalEntry($event, game)" class="game__wishlist-button">
								<span v-if="game.game_status" class="material-icons favorited">sports_esports</span>
								<span v-else class="material-icons">sports_esports</span>
							</button>

							<button title="Review" @click="reviewEntry($event, game)" class="game__wishlist-button">
								<i v-if="userRating" class="fa-solid fa-pen-to-square favorited"></i>
								<i v-else class="fa-solid fa-pen-to-square"></i>
							</button>

						</div>

						<div class="game__reviews" v-if="user.isAuth">
							<fieldset id="rating" class="rating">
								<input @change="updateReview($event, game.id)" v-model="userRating" type="radio" id="rating-star5" name="rating" value="5" /><label class = "full" for="rating-star5"   title="5 Stars"></label>
								<input @change="updateReview($event, game.id)" v-model="userRating" type="radio" id="rating-star4half" name="rating" value="4.5" /><label class = "half" for="rating-star4half"   title="4.5 Stars"></label>
								<input @change="updateReview($event, game.id)" v-model="userRating" type="radio" id="rating-star4" name="rating" value="4" /><label class = "full" for="rating-star4"   title="4 Stars"></label>
								<input @change="updateReview($event, game.id)" v-model="userRating" type="radio" id="rating-star3half" name="rating" value="3.5" /><label class = "half" for="rating-star3half"   title="3.5 Stars"></label>
								<input @change="updateReview($event, game.id)" v-model="userRating" type="radio" id="rating-star3" name="rating" value="3" /><label class = "full" for="rating-star3"   title="3 Stars"></label>
								<input @change="updateReview($event, game.id)" v-model="userRating" type="radio" id="rating-star2half" name="rating" value="2.5" /><label class = "half" for="rating-star2half"   title="2.5 Stars"></label>
								<input @change="updateReview($event, game.id)" v-model="userRating" type="radio" id="rating-star2" name="rating" value="2" /><label class = "full" for="rating-star2"   title="2 Stars"></label>
								<input @change="updateReview($event, game.id)" v-model="userRating" type="radio" id="rating-star1half" name="rating" value="1.5" /><label class = "half" for="rating-star1half"   title="1.5 Star"></label>
								<input @change="updateReview($event, game.id)" v-model="userRating" type="radio" id="rating-star1" name="rating" value="1" /><label class = "full" for="rating-star1"   title="1 Star"></label>
								<input @change="updateReview($event, game.id)" v-model="userRating" type="radio" id="rating-starhalf" name="rating" value="0.5" /><label class = "half" for="rating-starhalf"   title="0.5 Star"></label>
							</fieldset>
						</div>

						<div class="game__ui game__login-message" v-else>
							<router-link :to="{name: 'UserLogin' }">Login</router-link> or <router-link :to="{name: 'UserRegister' }">create an account</router-link> to add games to your library
						</div>

					</div>

					<div class="title-block">
						<h1 class="game__title">{{game.title}}</h1>
					</div>

					

					
					<div class="game__attributes platforms-block">
						<!-- <div v-if="platforms.length > 1" class="game__attributes-title game__attributes-title--block">Platforms</div>
						<div v-else class="game__attributes-title game__attributes-title--block">Platform</div> -->

						<div v-if="game.rating" class="game__average-rating">
							<i class="fa-solid fa-star brand-color"></i> <span class="game__average-rating-star">{{((game.rating / 20) - 0.075).toFixed(1)}}</span> <span v-if="game.rating_count" class="game__average-rating-count">({{game.rating_count.toLocaleString("en-US")}})</span>
						</div>

					</div>

					<div class="left-block">
						<h3 class="category-title">Game Info</h3>

						<div class="game__attribute-box" v-if="publishers">
							<div v-if="publishers.length > 1" class="game__attributes-title">Publishers</div> 
							<div v-else class="game__attributes-title">Publisher</div>
							<div class="game__attributes" :key="publisher.id" v-for="publisher in publishers">
								{{ publisher.title }}
							</div>
						</div>
		
						<div class="game__attribute-box" v-if="developers">
							<div v-if="developers.length > 1" class="game__attributes-title">Developers</div> 
							<div v-else  class="game__attributes-title">Developer</div> 
							<div class="game__attributes" :key="developer.id" v-for="developer in developers">
								{{ developer.title }}
							</div>
						</div>
						
						<div class="game__attribute-box" v-if="releases">
							<div class="tool-tip left">
								<div>
									<div class="game__attributes-releasedate--tool-tip" :key="release.name" v-for="release in releases">
										<strong>{{ release.name }}</strong>
										<div :key="date" v-for="date in release.dates">
											{{ date.date }} - {{date.region}}
										</div>
									</div>
								</div>
								<span class="material-icons tooltip right" data-text="">info</span>
							</div>
							<div v-if="releases.length > 1" class="game__attributes-title">Release Dates</div>
							<div v-else class="game__attributes-title">Release Date</div>
							<div class="game__attributes" :key="release.name" v-for="release in releases">
								<div>{{release.dates[0].date}} - <em>{{ release.name }}</em></div>
							</div>
							
						</div>
						
						<div class="game__attributes game__attribute-box genres-block" v-if="genres.length > 0">
							<div v-if="genres.length > 1" class="game__attributes-title game__attributes-title--block">Genres</div> 
							<div v-else class="game__attributes-title game__attributes-title--block">Genre</div> 
							<div class="game__attributes-attribute" :key="genre.id" v-for="genre in genres">
								{{ genre.genre }}
							</div>
						</div>

					</div>

					<div class="game__content content-block">

						<div v-if="platforms">
							<h3 class="category-title">Platforms</h3>
							<div class="game__attributes-attribute" :key="platform.platform" v-for="platform in platforms">
								{{platform.platform}}
							</div>
						</div>

						<div v-if="game.summary" class="game__summary summary-block">
							<h3 class="category-title">Summary</h3>
							<p style="white-space: pre-line">{{game.summary}}</p>
						</div>
	
						<div v-if="game.storyline" class="game__description description-block">
							<h3 class="category-title">Plot</h3>
							<p style="white-space: pre-line">{{game.storyline}}</p>
						</div>

						
						<div v-if="relatedGames" style="opacity:0;" :class="{'fadeIn': showrelated}">
							<h3 class="category-title">Other Versions</h3>
							<div class="grid__box">
								<div :style="getBackGround(relatedGame.coverArt)" class="game-card game-card--five game-card--five-game-page" :key="relatedGame.id" v-for="relatedGame in relatedGames">
									<div class="game-card__fade game-card__fade--other"></div>
									<router-link class="game-card__title" v-if="relatedGame.title" :to="{name: 'Game', params:{id: relatedGame.id, url: relatedGame.slug}}">{{relatedGame.title}}</router-link>
									<div><router-link class="game-card__home-link" v-if="relatedGame.title" :to="{name: 'Game', params:{id: relatedGame.id, url: relatedGame.slug}}"><img class="game-card__image" :src="relatedGame.coverArt"></router-link></div>			
								</div>
							</div>
						</div>


						<div class="game__screenshot-block"  v-if="screenshots">
							<h3 class="category-title game__screenshot-title"  >Screenshots</h3>
							<div class="game__screenshot-container" v-for="screenshot in screenshots" :key="screenshot.id">
								<img @click="openScreenShotModal(screenshot.url.replace('t_thumb','t_1080p'))" class="game__screenshot-image" :src="screenshot.url.replace('t_thumb','t_screenshot_med')" />
							</div>
						</div>


					</div>

					<div class="right-block">
						<h3 class="category-title">Other Info</h3>

						
						<div class="game__attribute-box" v-if="game.id">
							<div class="game__attributes-title">IGDB ID</div> 
							<div class="game__attributes"><a target="_blank" :href="game.url">{{ game.id }}</a></div>
						</div>

						<div class="game__attribute-box" v-if="websites.length > 0">
							<div class="game__attributes-title">Websites</div>
							<div class="game__attributes-icons">
								<div class="game__attributes game__attributes-icon" v-for="website in websites" :key="website.id">
									<span v-if="website.category == 1"><a  target="_blank" :title="game.title + ' official website' " :href="website.url"><i class="fa-solid fa-arrow-up-right-from-square"></i> Official Website</a></span>
									<span v-if="website.category == 2"><a  target="_blank" :title="game.title + ' on Wikia' " :href="website.url"><i><img src="/icons/wikia.svg" /></i> Wikia</a></span>
									<span v-if="website.category == 3"><a  target="_blank" :title="game.title + ' on Wikipedia' " :href="website.url"><i class="fa-brands fa-wikipedia-w"></i> Wikipedia</a></span>
									<span v-if="website.category == 4"><a  target="_blank" :title="game.title + ' on Facebook' " :href="website.url"><i class="fa-brands fa-facebook"></i> Facebook</a></span>
									<span v-if="website.category == 5"><a  target="_blank" :title="game.title + ' on Twitter' " :href="website.url"><i class="fa-brands fa-twitter"></i> Twitter</a></span>
									<span v-if="website.category == 6"><a  target="_blank" :title="game.title + ' on Twitch' " :href="website.url"><i class="fa-brands fa-twitch"></i> Twitch</a></span>
									<span v-if="website.category == 8"><a  target="_blank" :title="game.title + ' on Instagram' " :href="website.url"><i class="fa-brands fa-instagram"></i> Instagram</a></span>
									<span v-if="website.category == 9"><a  target="_blank" :title="game.title + ' on YouTube' " :href="website.url"><i class="fa-brands fa-youtube"></i> YouTube</a></span>
									<span v-if="website.category == 10"><a target="_blank" :title="game.title + ' on Apple Store' " :href="website.url"><i class="fa-brands fa-apple"></i> Apple Store</a></span>
									<span v-if="website.category == 11"><a target="_blank" :title="game.title + ' on Apple Store' " :href="website.url"><i class="fa-brands fa-apple"></i> Apple Store</a></span>
									<span v-if="website.category == 12"><a target="_blank" :title="game.title + ' on Android' " :href="website.url"><i class="fa-brands fa-android"></i> Android Store</a></span>
									<span v-if="website.category == 13"><a target="_blank" :title="game.title + ' on Steam' " :href="website.url"><i class="fa-brands fa-steam"></i> Steam</a></span>
									<span v-if="website.category == 14"><a target="_blank" :title="game.title + ' on Reddit' " :href="website.url"><i class="fa-brands fa-reddit"></i> Reddit</a></span>
									<span v-if="website.category == 15"><a target="_blank" :title="game.title + ' on itch.io' " :href="website.url"><i class="fa-brands fa-itch-io"></i> itch.io</a></span>
									<span v-if="website.category == 16"><a target="_blank" :title="game.title + ' on Epic Games' " :href="website.url"><i><img src="/icons/epicgames.svg" /></i> Epic Games</a></span>
									<span v-if="website.category == 17"><a target="_blank" :title="game.title + ' on GOG' " :href="website.url"><i><img src="/icons/gog.svg" /></i> GOG</a></span>
									<span v-if="website.category == 18"><a target="_blank" :title="game.title + ' on Discord' " :href="website.url"><i class="fab fa-discord"></i> Discord</a></span>
								</div>
							</div>
						</div>

						

						<div class="game__attributes game__attribute-box altname-block" v-if="altnames.length > 0">
							<div v-if="altnames.length > 1" class="game__attributes-title game__attributes-title--block">Alternate Names</div> 
							<div v-else class="game__attributes-title game__attributes-title--block">Alternate Name</div> 
							<div class="game__attributes-altname" :key="altname.id" v-for="altname in altnames">
								<div>{{ altname.title }} </div>
								<div>({{ altname.note }})</div>
							</div>
						</div>


					</div>

					<div class="center-block">
						<!-- <div v-if="gameSeries.length > 0" style="opacity:0;" :class="showsimilar && show">
							<h3 class="category-title">Game Series</h3>
							<div class="game__related">
								<div class="game__related-container" :key="gameSerie.id" v-for="gameSerie in gameSeries">
									<div class="game__related-fade"></div>
									<router-link class="game__related-title" v-if="gameSerie.title" :to="{name: 'Game', params:{id: gameSerie.id, url: gameSerie.slug}}">{{gameSerie.title}}</router-link>
									<div class="game__related-img"><router-link v-if="gameSerie.title" :to="{name: 'Game', params:{id: gameSerie.id, url: gameSerie.slug}}"><img :src="gameSerie.coverArt"></router-link></div>			
								</div>
							</div>
						</div> -->

					</div>

				</div>
				

				
				<!-- <div class="game__nav">
					<router-link :to="{name:'UpdateGame'}" class="nav__link nav__link blue">Edit</router-link>
					<span v-on:click="deleteGame()" class="trash_can"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash-can" class="svg-inline--fa fa-trash-can" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M32 464C32 490.5 53.5 512 80 512h288c26.5 0 48-21.5 48-48V128H32V464zM304 208C304 199.1 311.1 192 320 192s16 7.125 16 16v224c0 8.875-7.125 16-16 16s-16-7.125-16-16V208zM208 208C208 199.1 215.1 192 224 192s16 7.125 16 16v224c0 8.875-7.125 16-16 16s-16-7.125-16-16V208zM112 208C112 199.1 119.1 192 128 192s16 7.125 16 16v224C144 440.9 136.9 448 128 448s-16-7.125-16-16V208zM432 32H320l-11.58-23.16c-2.709-5.42-8.25-8.844-14.31-8.844H153.9c-6.061 0-11.6 3.424-14.31 8.844L128 32H16c-8.836 0-16 7.162-16 16V80c0 8.836 7.164 16 16 16h416c8.838 0 16-7.164 16-16V48C448 39.16 440.8 32 432 32z"></path></svg></span>
				</div> -->
				
				<!--<div class="game__cover"><img :src="game.coverimage" /></div>-->


			</div>

		</div>

		<!-- <div :class="{ show: warning, allowclick: warning }" class="game__confirm-delete">
			<div class="game__warning-box">
				<div>Are you sure you want to delete?</div>
				<button v-on:click="cancelDeleteGame()">Cancel</button>
				<button id="confirmDeleteButton" v-on:click="confirmDelete()">Delete</button>
			</div>
		</div> -->

		<div class="error" v-if="!games">
			<div class="error__message">Game not found!</div>
		</div>

		<div @click="closeScreenShotModal()" :class="{'game__screenshot-modal-background--show': showScreenShotModal}" class="game__screenshot-modal-background" style=""></div>
		<div @click="closeScreenShotModal()" :class="{'game__screenshot-modal--show': showScreenShotModal}" class="game__screenshot-modal " style="">
			<img class="game__screenshot-modal-image" :src="screenShotModalURL" />
		</div>

	</div>
</template>

<script>

// import IgdbService from '@/services/igdbService'
import HelperService from '@/services/helperService'
import GameService from '@/services/gameService'
import PlatformService from '@/services/platformService'
import ArtService from '@/services/artService'
import GenreService from '@/services/genreService'
import ReleaseDateService from '@/services/releasedateService'
import AuthService from '@/services/authService'
import UserService from '@/services/userService'
import CompanyService from '@/services/companyService'
import ScreenshotService from '@/services/screenshotService'
import AltNameService from '@/services/altnameService'
import WebsiteService from '@/services/websiteService'
import LibraryService from '@/services/libraryService'
import WishlistService from '@/services/wishlistService'
import JournalService from '@/services/journalService'
import ReviewService from '@/services/reviewService'


import QuickAddModalWindow from '@/components/universal/QuickAddModal'
import GameJournalModal from '@/components/universal/GameJournalModal'
import ReviewModal from '@/components/universal/ReviewModal'



export default {
	name: "Game",
		components:{
		QuickAddModalWindow,
		GameJournalModal,
		ReviewModal,
	},
	data(){
		return{
			//user:null,
			response:{},
			games:{},
			gameSeries:[],
			platforms:[],
			genres:[],
			altnames:[],
			covers:[],
			releasedates:[],
			releases: [],
			companies:[],
			publishers:[],
			developers:[],
			screenshots:[],
			relatedGames:[],
			similarGames:[],
			websites:[],
			temp:[],
			igdb: {},
			url: null,
			coverArt: null,
			headerArt: ``,
			mounted: false,
			warning: false,
			show: 'fadeIn',
			show_cover: false,
			imageload: false,
			showcover: false,
			showheader: false,
			showrelated: false,
			showsimilar: false,
			test: [],
			testValue: null,

			quickAddGame: {},
			quickAddPlatforms: {},
			showModal: false,

			journalGame: {},
			journal: {},
			showJournalModal: false,
			gameJournalExists: false,
			journalConfirmText: 'Add',
			event: null,

			objGameStatus: null,

			userRating: null,
			showReviewModal: false,
			reviewGame: {},
			review: {},
			gameReviewExists: false,
			reviewConfirmText: 'Add',

			showScreenShotModal: false,
			screenShotModalURL: ''

		}
	},
	computed:{
		user(){
			return this.$store.state.user
		}
	},
	watch:{
		user(){
			return this.$store.state.user
		}
	},
	async beforeMount(){
		
	},
	async mounted(){
		
		
		//this.user = await HelperService.getAuthorizedUser()
	
		this.games = (await GameService.getGame(this.$route.params.id, this.$route.params.url)).data.result
		this.userRating = this.games[0].user_rating
		this.objGameStatus = this.games[0].game_status

		

		if(this.games == undefined || this.games == null || this.games.length == 0)
			this.$router.push('/404')



		if(this.games[0].cover_url)
			this.games[0].coverArt = this.games[0].cover_url
		else
			this.games[0].coverArt = '/no-cover.png'

		this.showcover = true

		this.mounted = true

		
		this.temp = `{ ${this.games[0].platforms} }`
		this.platforms = (await PlatformService.getPlatformAny(this.temp)).data.result

		this.temp = `{ ${this.games[0].genres} }`
		this.genres = (await GenreService.getGenreAny(this.temp)).data.result

		this.websites = (await WebsiteService.getWebsites(this.games[0].id)).data.result

		this.temp = `{ ${this.games[0].alternate_names} }`
		this.altnames = (await AltNameService.getAltNameAny(this.temp)).data.result

		this.temp = `{ ${this.games[0].release_dates} }`
		this.releasedates = (await ReleaseDateService.getReleaseDateAny(this.temp)).data.result

		this.publishers = (await CompanyService.getPublishers(this.games[0].id)).data.result
		this.developers = (await CompanyService.getDevelopers(this.games[0].id)).data.result

		let platformID = null
		let platformName = null
		let index = 0

		if(this.releasedates != null){
			for(let i = 0; i < this.releasedates.length; i++){

				if(this.releasedates[i].platformid !== platformID){
					platformName = this.platforms.find(x => x.id === this.releasedates[i].platformid).platform
					this.releases.push( { name: platformName, dates:[] })
					platformID = this.releasedates[i].platformid
					index++
				}

				if(this.releasedates[i].platformid === platformID){	
					this.releases[index - 1].dates.push( { date: `${this.releasedates[i].date}`, region: `${this.releasedates[i].region}` } )
				} 
							
			}
		}

		



		// this.covers = (await GameService.getGameCovers(this.games))
		
		// if(this.covers.length > 0)
		// 	this.games = (await GameService.setGameCovers(this.games, this.covers))
		// else
		// 	this.games[0].coverArt = '/no-cover.png'
		

		

		// Build an array of related game IDs and return the related games 
		// --------------------------------------------------------------------
		this.temp = '{'
		this.buildGameArray(this.games[0].bundles)
		this.buildGameArray(this.games[0].standalone_games)
		this.buildGameArray(this.games[0].remasters)
		this.buildGameArray(this.games[0].remakes)
		this.buildGameArray(this.games[0].ports)
		this.buildGameArray(this.games[0].forks)
		this.buildGameArray(this.games[0].expansions)
		this.buildGameArray(this.games[0].expanded_games)
		this.buildGameArray(this.games[0].dlc)
		this.buildGameArray(this.games[0].version_parent)
		this.buildGameArray(this.games[0].parent_game)
		//this.buildGameArray(this.games[0].external_games)
		this.temp = this.temp.slice(0, -1)
		this.temp += '}'

		if(this.temp != '}')
			this.relatedGames = (await GameService.getGameAny(this.temp)).data.result


		this.covers = (await GameService.getGameCovers(this.relatedGames))
		this.relatedGames = (await GameService.setGameCovers(this.relatedGames, this.covers))	
		this.showrelated = true


		this.screenshots = (await ScreenshotService.getScreenshotFromGame(this.games[0].id)).data.result
		
		if(this.screenshots.length > 0){
			let random = Math.floor(Math.random() * this.screenshots.length)
			this.headerArt = `background-image: url(${this.screenshots[random].url.replace("thumb", "screenshot_big").replace('.jpg', '.webp')})`
		}

		this.showheader = true






		// this.gameSeries = (await GameService.getGameSeries(this.games[0].library, this.games[0].id)).data.result

		// this.temp = '{'
		// for(let i = 0; i < this.gameSeries.length; i++){

		// 	if(this.gameSeries[i].coverid == null){
		// 		this.gameSeries[i].coverArt = '//images.igdb.com/igdb/image/upload/t_cover_big/nocover.png'
		// 	} else {
		// 		// this.covers = (await ArtService.getArt(this.relatedGames[i].id)).data.result
		// 		// if(this.covers !== undefined) this.relatedGames[i].coverArt = this.covers[0].url
		// 		this.temp += this.gameSeries[i].id + ','
		// 	}
		// }
		// this.temp = this.temp.slice(0, -1)
		// this.temp += '}'

		// if(this.temp != '}')
		// 	this.covers = (await ArtService.getArtAny(this.temp)).data.result

		// if (this.gameSeries.length > 0 && this.covers.length > 0) {
		// 	for(let i = 0; i < this.gameSeries.length; i++){
		// 		//this.temp += this.games[i].id + ','
		// 		let obj = this.covers.find(o => o.gameid === this.gameSeries[i].id)
		// 		this.gameSeries[i].coverArt = obj.url
		// 	}
		// }

		// this.showsimilar = true






		// this.temp = '{'
		// this.buildGameArray(this.games[0].similar_games)
		// this.temp = this.temp.slice(0, -1)
		// this.temp += '}'

		// if(this.temp != '}')
		// 	this.similarGames = (await GameService.getGameAny(this.temp)).data.result

		// for(let i = 0; i < this.similarGames.length; i++){

		// 	if(this.similarGames[i].coverid == null){
		// 		this.similarGames[i].coverArt = '//images.igdb.com/igdb/image/upload/t_cover_big/nocover.png'
		// 	} else {
		// 		this.covers = (await ArtService.getArt(this.similarGames[i].id)).data.result
		// 		if(this.covers !== undefined) this.similarGames[i].coverArt = this.covers[0].url 
		// 	}

		// }
		// this.showsimilar = true

		
	},
	methods:{
		formatDate(date){
			return date = new Date(date).toLocaleDateString("en-US")
		},



		buildGameArray(gameArray){
			
			if (gameArray && gameArray.length > 0) {
				gameArray.forEach(e => {
					this.temp += e + ','
				});
			} else if (gameArray){
				this.temp += gameArray + ','
			}
		},

		async getRelatedCoverArt(id){
			if(this.games[0].coverid == null){
				return '//images.igdb.com/igdb/image/upload/t_cover_big/nocover.png'
			} else {
				this.covers = (await ArtService.getArt(this.games[0].id)).data.result
				if(this.covers !== undefined) this.coverArt = this.covers[0].url.replace('.jpg', '.webp') 
			}
		},

		pushToArray(){
			this.test.push(this.testValue)
			console.log(this.test)
		},

		removeFromArray(index){
			this.test.splice(index, 1)
			console.log(this.test)
		},
		getBackGround(url){
			return `background-image: url('${url}');`
		},


		async quickAdd(event, game){
			// this.toggleButton(event)
			// let response = await LibraryService.addLibraryGame(game)
			// this.toggleButton(event)

			this.quickAddGame = game;			
			this.quickAddPlatforms = (await PlatformService.getPlatformAny(`{ ${game.platforms} }`)).data.result
			this.toggleModal();
		},

		toggleModal(){
			this.showModal  = !this.showModal
		},

		emittoggleModal(){
			this.toggleModal()
		},

		async wishlist($event, game){
			if(game.wished){
				$event.target.disabled = true
				game.wished = !game.wished
				let response = await(WishlistService.removeWishlist(game.id))
				$event.target.disabled = false
			}
			else{
				$event.target.disabled = true
				game.wished = !game.wished
				let response = await(WishlistService.addWishlist(game))
				$event.target.disabled = false
			}
		},

		async addGame(event, game){
			this.$router.push(`/library/add/${game.id}/${game.slug}`)
		},


		toggleButton(event){
			if(event.target.disabled){
				setTimeout(function () {
					event.target.disabled = false
					event.target.childNodes[0].childNodes[0].classList.remove('loader-show')
					event.target.childNodes[1].classList.remove('button__text--hidden')
				}, 1000);
			} else {
				event.target.disabled = true
				event.target.childNodes[0].childNodes[0].classList.add('loader-show')
				event.target.childNodes[1].classList.add('button__text--hidden')
			}
		},

		async journalEntry($event, game){
			this.journalGame = game;
			this.journal = (await JournalService.getAuthJournal(game.id)).data.result
			this.gameJournalExists = true
			this.event = $event

			if(this.journal.length === 0){
				this.journal = [{id: null, user_id: null, game_id: null, game_status: 'Played', status_date: null, notes: null}]
				this.gameJournalExists = false
			}

			if(this.gameJournalExists)
				this.journalConfirmText = 'Update'
			else
				this.journalConfirmText = 'Add'
			

			if(Date.parse(this.journal[0].status_date))
				this.journal[0].status_date = new Date(this.journal[0].status_date).toISOString().split('T')[0]
			else 
				this.journal[0].status_date = null

			this.toggleJournalModal()
		},

		toggleJournalModal(confirm, event, deleted, objIndex, gameStatus){

			if(confirm)
				event.target.classList.add("favorited")

			if(deleted)
				event.target.classList.remove("favorited")

			if(confirm || deleted)
				this.objGameStatus = gameStatus
			

			this.showJournalModal = !this.showJournalModal
		},



		async updateReview(event, game_id){
			let form = {
				rating: null,
				game_id: null,
			}

			form.rating = this.userRating
			form.game_id = game_id 
			form.gameid = game_id 
			form.ratingCommentInput = null
			form.privateRatingCheck - false

			let review = (await ReviewService.getAuthReview(game_id)).data.result
			
			let response
			if(review.length > 0)
				response = (await ReviewService.updateReviewRating(form)).data.result
			else
				response = (await ReviewService.addReview(form)).data.result


		},



		async reviewEntry($event, game){
			this.reviewGame = game;
			this.review = (await ReviewService.getAuthReview(game.id)).data.result
			
			this.gameReviewExists = true
			this.event = $event

			if(this.review.length === 0){
				this.review = [{id: null, user_id: null, game_id: null, rating: null, review: null, review_private: null}]
				this.gameReviewExists = false
			}

			if(this.gameReviewExists)
				this.reviewConfirmText = 'Update'
			else
				this.reviewConfirmText = 'Add'

			this.toggleReviewModal()
		},



		toggleReviewModal(confirm, event, deleted, form, objIndex){

			if(confirm)
				event.target.classList.add("favorited")

			if(deleted)
				event.target.classList.remove("favorited")

			if(confirm || deleted)
				this.userRating = form.rating
				
			this.showReviewModal = !this.showReviewModal
		},


		openScreenShotModal(screenshot_url) {
			this.screenShotModalURL = screenshot_url;
			this.showScreenShotModal = true;
		},

		closeScreenShotModal() {
			this.showScreenShotModal = false;
		}


	}
}


</script>
