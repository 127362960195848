import api from '@/services/api'

class CompanyService{
	static async getPublishers(id) {
		return await api().get(`/company/publishers/${id}`)
	}

	static async getDevelopers(id) {
		return await api().get(`/company/developers/${id}`)
	}

	static async getDeveloperAll() {
		return await api().get(`/company/developers/all`)
	}

	static async getPublisherAll() {
		return await api().get(`/company/publishers/all`)
	}
}

export default CompanyService
