<template>
	<div class="modal">
		<div class="modal__window">
			<h3>{{ title }}</h3>
			<h4>{{ subtitle }}</h4>
			<button id="model-confirm-button" @click="selectConfirm">{{ confirmText }}</button>
			<button  @click="toggleModal" class="button__flat">{{ cancelText }}</button>
		</div>
		<div @click="toggleModal" class="modal__background"></div>
	</div>
</template>

<script>
export default {
	name: 'ModalWindow',
	props:['title', 'subtitle', 'confirmText', 'cancelText'],
	data(){
		return{
			toggle: true,
			confirm: true,
		}
	},
	methods:{
		toggleModal(){
			this.$emit('toggleModal', this.toggle)
		},

		selectConfirm(){
			this.$emit('selectConfirm', this.confirm)
		},
	}

}
</script>