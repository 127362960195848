import api from '@/services/api'

class DashboardService{

	static async getUserDashboardMissingData(limit, user_id) {
		return await api().get(`/dashboard/missing/${user_id}/${limit}`)
	}

	static async getUserDashboardRecentLimit(limit, userid) {
		return await api().get(`/dashboard/recent/${userid}/${limit}`)
	}

	static async getUserDashboardUpdatedLimit(limit, userid) {
		return await api().get(`/dashboard/updated/${userid}/${limit}`)
	}

	static async getTotalGames(user_id) {
		return await api().get(`/dashboard/total/${user_id}`)
	}

	static async getPlayedCount(user_id) {
		return await api().get(`/dashboard/played/${user_id}`)
	}

	static async getBackloggedCount(user_id) {
		return await api().get(`/dashboard/backlogged/${user_id}`)
	}

	static async getPercentCount(user_id) {
		return await api().get(`/dashboard/percent/${user_id}`)
	}

	static async getQuitCount(user_id) {
		return await api().get(`/dashboard/quit/${user_id}`)
	}

	static async getPausedCount(user_id) {
		return await api().get(`/dashboard/paused/${user_id}`)
	}

}

export default DashboardService