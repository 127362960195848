<template>

	<div class="grid" style="opacity: 0;">

		<ModalWindow :class="{'modal--show': showModal}" @toggleModal="emittoggleModal" @selectConfirm="selectConfirm" :title="'Are you sure you want to delete?'" :subtitle="deleteGame" :confirmText="'Delete'" :cancelText="'Cancel'"  />

		<ReviewModal :class="{'modal--show': showReviewModal}" @toggleReviewModal="toggleReviewModal" :title="`Add review`" :subtitle="`${reviewGame.title}`" :game="reviewGame" :gameid="reviewGame.game_id" :review="review" :event="event" :objIndex="objIndex" :gameReviewExists="gameReviewExists" :confirmText="reviewConfirmText" cancelText="Cancel"  />

		<GameJournalModal :class="{'modal--show': showJournalModal}" @toggleJournalModal="toggleJournalModal" :title="`Add to library`" :subtitle="``" :game="journalGame" :journal="journal" :gameid="journalGame.game_id" :event="event" :objIndex="objIndex" :gameJournalExists="gameJournalExists" :confirmText="journalConfirmText" cancelText="Cancel"  />


		<transition-group class="grid__box" name="gamecard" tag="div" appear>
			
				<div :style="getBackGround(game)" :class="{ noCover: !checkCover(game) }" class="grid__game-card game-card game-card--six" :key="game.id" v-for="(game,index) in games">
					<div class="game-card__fade game-card__fade--hidden"></div>

					<div v-if="auth" class="game-card__menu">
						
						<button @click="openMenu($event, index)" :class="`grid__button grid__menu-button grid__menu-button--show grid__menu-button-${index}`">
							<div class="button__text"><span class="material-icons-outlined">more_vert</span></div>
						</button>
		
						<button @click="closeMenu($event, index)" :class="`grid__button grid__menu-button-close grid__menu-button-close-${index}`">
							<div class="button__text"><span class="material-icons-outlined">close</span></div>
						</button>


						<div :class="`grid__menu grid__menu-${index}`">

							<button title="Edit" @click="editGame($event, game, index)" class="grid__button">
								<div class="button__text">
									<span class="material-icons">edit_note</span> Edit
								</div>
							</button>
		
							<button title="Journal" @click="journalEntry($event, game, index)" class="grid__button grid__play-button">
								<div class="button__text">
									<span v-if="game.game_status" class="material-icons favorited">sports_esports</span>
									<span v-else class="material-icons">sports_esports</span> Journal
								</div>
							</button> 
		
							<button title="Review" @click="reviewEntry($event, game, index)" class="grid__button grid__review-button">
								<div class="button__text">
									<span v-if="game.rating"><i class="fa-solid fa-pen-to-square favorited"></i> Review</span>
									<span v-else><i class="fa-solid fa-pen-to-square"></i>  Review</span>
								</div>
							</button>

							<button title="Delete" @click="beginDeleteLibraryGame(game)" class="grid__button">
								<div class="button__text">
									<span class="material-icons">delete_forever</span> Delete
								</div>
							</button>
						</div>

					</div>
					
					
					<div class="game-card__title game-card__title--hidden">
						<router-link v-if="game.title" :to="{name: 'LibraryGame', params:{id: game.id, url: game.slug}}">{{game.title}}</router-link>						
						<div v-if="game.rating && (!game.review_private || user && user.slug === game.slug)" class="game-card__game-reviews game__reviews">
							<div class="Stars" :style="`--rating: ${game.rating};`"></div>
							<span v-if="user && game.rating && user.slug === game.slug && game.review_private" title="Review Hidden" class="material-icons review-visible">visibility_off</span> 
						</div>
					</div>

					<span v-if="user && game.game_status && user.slug === game.slug" :title="`Play status ${game.game_status}`" class="grid-view__game-status list-view__game-status library__game-status game__game-status">
						<span title="Playing" v-if="game.game_status === 'Playing'" class="playing">{{game.game_status}}</span>
						<span title="Played" v-else-if="game.game_status === 'Played'" class="played">{{game.game_status}}</span>
						<span title="Backlogged" v-else-if="game.game_status === 'Backlogged'" class="backlogged">{{game.game_status}}</span>
						<span title="Quit" v-else-if="game.game_status === 'Quit'" class="quit">{{game.game_status}}</span>
						<span title="Paused" v-else-if="game.game_status === 'Paused'" class="paused">{{game.game_status}}</span>
						<span title="100%" v-else-if="game.game_status === '100%'" class="one-hundred">100%</span>
					</span>
					<span v-else-if="game.game_status && !game.journal_private" :title="`Play status ${game.game_status}`" class="grid-view__game-status list-view__game-status library__game-status game__game-status">
						<span title="Playing" v-if="game.game_status === 'Playing'" class="playing">{{game.game_status}}</span>
						<span title="Played" v-else-if="game.game_status === 'Played'" class="played">{{game.game_status}}</span>
						<span title="Backlogged" v-else-if="game.game_status === 'Backlogged'" class="backlogged">{{game.game_status}}</span>
						<span title="Quit" v-else-if="game.game_status === 'Quit'" class="quit">{{game.game_status}}</span>
						<span title="Paused" v-else-if="game.game_status === 'Paused'" class="paused">{{game.game_status}}</span>
						<span title="100%" v-else-if="game.game_status === '100%'" class="one-hundred">100%</span>
					</span>

					<div>
						<router-link  class="game-card__home-link" v-if="game.title" :to="{name: 'LibraryGame', params:{id: game.id, url: game.slug}}">
							<!-- <img :class="{ show: showcover }" class="game-card__image" :src="game.coverArt"> -->
						</router-link>
					</div>

				</div>
			
		</transition-group>
	</div>

</template>

<script>

import PlatformService from '@/services/platformService'
import HelperService from '@/services/helperService'
import ReviewService from '@/services/reviewService'
import JournalService from '@/services/journalService'


import ModalWindow from '@/components/universal/ModalWindow'
import ReviewModal from '@/components/universal/ReviewModal'
import GameJournalModal from '@/components/universal/GameJournalModal'


export default {
	name: "GridView",
	props: ['games', 'showcover', 'auth'],
		components:{
		ModalWindow,
		ReviewModal,
		GameJournalModal,
	},
	data(){
		return{
			mounted:false,
			show: 'show',

			//user: null,
			sealedCheck: [],
			checking: [],

			showModal: false,
			deleteGame: '',
			objGame: null,

			objIndex: null,
			event: null,

			userRating: null,
			showReviewModal: false,
			reviewGame: {},
			review: {},
			gameReviewExists: false,
			reviewConfirmText: 'Add',
			reviewPrivate: false,

			journalGame: {},
			journal: {},
			showJournalModal: false,
			gameJournalExists: false,
			journalConfirmText: 'Add',
		}
	},
	computed:{
		objPlatforms(){
			return this.platforms
		},

		gameObj(){
			return this.games
		},

		user(){
			return this.$store.state.user
		}
	},
	watch:{
		user(){
			return this.$store.state.user
		}
	},
	async mounted(){
		//this.user = await HelperService.getAuthorizedUser()
	},
	methods:{
		getBackGround(game){
			if(game.coverArt === ''){
				return ``
			} else {
				return `background-image: url('${game.coverArt.replace('.jpg','.webp')}')`
			}
		},

		checkCover(game){
			if(game.coverArt === '/no-cover.png')
				return false
			
			return true
		},

		openMenu($event, index){
			const gridMenu = document.getElementsByClassName(`grid__menu-${index}`)
			gridMenu[0].classList.add('grid__menu--show')

			const gridClose = document.getElementsByClassName(`grid__menu-button-close-${index}`)
			gridClose[0].classList.add('grid__menu-button-close--show')
			gridClose[0].classList.add('grid-menu-open')

			$event.target.classList.remove(`grid__menu-button--show`)
		},

		closeMenu($event, index){
			$event.target.classList.remove(`grid__menu-button-close--show`)
			$event.target.parentElement.classList.remove(`grid__menu-button-close--show`)

			$event.target.classList.remove(`grid-menu-open`)
			$event.target.parentElement.classList.remove(`grid-menu-open`)

			const gridMenuButton = document.getElementsByClassName(`grid__menu-button-${index}`)
			gridMenuButton[0].classList.add(`grid__menu-button--show`)

		},

		deleteLibraryGame(game){
			this.toggleModal()
			game.checking = true
			this.$emit('deleteLibraryGame', { id: game.id })
		},

		beginDeleteLibraryGame(game){
			this.objGame = game
			this.deleteGame = game.title
			this.toggleModal()
		},

		toggleModal(){
			this.showModal  = !this.showModal
		},

		emittoggleModal(){
			this.toggleModal()
		},

		selectConfirm(){
			this.deleteLibraryGame(this.objGame)
		},

		clickCheck(value, index){
			if(value == "manual")
				return this.manualCheck = !this.manualCheck
			if(value == "box")
				return this.boxCheck = !this.boxCheck
			if(value == "game")
				return this.gameCheck = !this.gameCheck
			if(value == "cib")
				return this.cibCheck = !this.cibCheck
			if(value == "sealed")
				return this.sealedCheck[index] = !this.sealedCheck[index]
			if(value == "played")
				return this.playedCheck = !this.playedCheck
			if(value == "finished")
				return this.finishedCheck = !this.finishedCheck
			if(value == "backlogged")
				return this.backloggedCheck = !this.backloggedCheck
			if(value == "neverplaying")
				return this.neverPlayingCheck = !this.neverPlayingCheck
			if(value == "ratingpublic")
				return this.privateRatingCheck = !this.privateRatingCheck

			return null
		},

		editGame($event, game, index){
			this.$router.push({name: 'LibraryEditGame', params:{id: game.id }})
		},

		async reviewEntry($event, game, index){
			this.reviewGame = game;
			this.review = (await ReviewService.getAuthReview(game.game_id)).data.result
			
			this.gameReviewExists = true
			this.event = $event

			if(this.review.length === 0){
				this.review = [{id: null, user_id: null, game_id: null, rating: null, review: null, review_private: null}]
				this.gameReviewExists = false
			}

			if(this.gameReviewExists)
				this.reviewConfirmText = 'Update'
			else
				this.reviewConfirmText = 'Add'

			this.objIndex = index

			this.toggleReviewModal()
		},

		toggleReviewModal(confirm, event, deleted, form, objIndex){

			if(confirm)
				event.target.children[0].children[0].children[0].classList.add("favorited")

			if(deleted)
				event.target.children[0].children[0].children[0].classList.remove("favorited")

			if(confirm || deleted){
				this.gameObj[objIndex].rating = form.rating
				this.gameObj[objIndex].review_private = form.privateRatingCheck
			}

			this.showReviewModal = !this.showReviewModal
		},

		async journalEntry($event, game, index){
			this.journalGame = game;
			this.journal = (await JournalService.getAuthJournal(game.game_id)).data.result
			this.gameJournalExists = true
			this.event = $event

			if(this.journal.length === 0){
				this.journal = [{id: null, user_id: null, game_id: null, game_status: 'Played', status_date: null, notes: null}]
				this.gameJournalExists = false
			}

			if(this.gameJournalExists)
				this.journalConfirmText = 'Update'
			else
				this.journalConfirmText = 'Add'

			if(Date.parse(this.journal[0].status_date))
				this.journal[0].status_date = new Date(this.journal[0].status_date).toISOString().split('T')[0]
			else 
				this.journal[0].status_date = null

			this.objIndex = index

			this.toggleJournalModal()
		},

		toggleJournalModal(confirm, event, deleted, objIndex, gameStatus, gameDate, journalPrivate){

			if(confirm)
				event.target.children[0].children[0].classList.add("favorited")
			
			if(deleted)
				event.target.children[0].children[0].classList.remove("favorited")

			if(confirm || deleted){
				this.gameObj[objIndex].game_status = gameStatus
				this.gameObj[objIndex].journal_private = journalPrivate
			}
			
			this.showJournalModal = !this.showJournalModal
		},
	},
}
</script>