<template>
	
	<div>
		<div id="gradient"></div>
		<div id="wrapper">
			<TheHeader v-if="mounted" />
			<div style="height:58px;"></div>
			<main v-if="mounted">
				<router-view v-slot="{ Component }">
					<transition name="route" mode="out-in">
						<component :key="$route.fullPath" :is="Component"></component>
					</transition>
				</router-view>
			</main> 
		</div>
		<TheFooter v-if="mounted" />
	</div>
	
</template>

<script>
import TheHeader from "@/components/universal/TheHeader.vue";
import TheFooter from "@/components/universal/TheFooter.vue";

import HelperService from '@/services/helperService'

export default {
	name: "App",
	components: {
		TheHeader,
		TheFooter,
	},
	data(){
		return{
			mounted: false,
			authUser: null
		}
	},
	async created() {
		try{
			this.authUser = await HelperService.GetUser()
			if(this.authUser && this.authUser.length > 0){
				this.$store.state.user.id = this.authUser[0].id
				this.$store.state.user.slug = this.authUser[0].username
				this.$store.state.user.isAuth = true
				this.$store.state.user.isLoggedIn = true
				this.mounted = true
			} else {
				this.$store.state.user.id = null
				this.$store.state.user.slug = null
				this.$store.state.user.isAuth = false
				this.$store.state.user.isLoggedIn = false
				this.mounted = true
			}
			

		} catch(err){
			console.error(err)
		}	
	},
	
	async mounted(){
		
	}
}; 
</script>