<template>
	<footer>

		<div class="footer__wrapper">


			<nav class="footer__nav">
				<ul>
					<li><router-link to='/about'>About</router-link></li>
					<!-- <li><router-link to='/credits'>Credits</router-link></li> -->
					<li><router-link to='/terms'>Terms</router-link></li>
					<li><router-link to='/privacy'>Privacy</router-link></li>
				</ul>
				<div class="footer__igdb">
					<a target="_blank" href="https://www.igdb.com/">Powered by <img src="/icons/igdb.svg" /></a>
				</div>
			</nav>

			<div class="footer__socials">
				<a target="_blank" title="Discord" href="https://discord.gg/bFcywSHm8h"><i
						class="fa-brands fa-discord"></i></a>
				<a target="_blank" title="Twitter GameStashApp" href="https://twitter.com/GameStashApp"><i
						class="fa-brands fa-twitter"></i></a>
				<a target="_blank" title="Instagram GameStashApp" href="https://instagram.com/gamestashapp"><i
						class="fa-brands fa-instagram"></i></a>

				<!-- <a target="_blank" title="Patreon GameStashApp" href="https://www.patreon.com/gamestashapp"><i class="fa-brands fa-patreon"></i></a>
				<a target="_blank" title="Ko-Fi GameStashApp" href="https://ko-fi.com/gamestashapp"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 503.4 420.1" xml:space="preserve"><path d="M371.6,74.1c-5.6,0-10.6-0.2-16.2,0h-0.8L42.4,74.6c-9.7,0-17.5,7.8-17.5,17.5v214.1c0,15.8,4.3,32.8,14.4,43.4 c10.8,11.3,27.8,15.7,44.7,15.7l211.8-1.3c32.6,0,57.1-24.9,57.1-57.6l0.4-14.1c5.9,0.2,16.5,0.1,22.1-0.3 c60.9-4.3,106.4-48.9,106.4-108.8S432.6,74.1,371.6,74.1z M267.8,229.2c-23.3,26-46.4,49-74.3,73.4c-1.9,1.7-4.7,1.6-6.6-0.1 c-22.1-19.4-46-38.3-79.7-77.9c-8.3-9.8-18.2-41.9-4.2-63.3c10.4-15.9,31.1-18.5,36.5-18.9c26.9-1.8,48.9,19,50.8,21.2 c1.9-2.5,21-21.2,46.6-21.8c2.8-0.1,24,0.3,37.8,18.1C289.6,179.2,285.9,209.9,267.8,229.2z M380.1,236.9c-9.6,0-24.8-0.1-24.8-0.1 s-0.7-52.1-0.7-52.8l0.6-54.1c0,0,18.7,0,24.8,0c25.1,0,45.5,24.2,45.5,54.1C425.5,213.9,405.3,236.9,380.1,236.9z"/></svg></a> -->
			</div>


			<div class="footer__copy">
				<p>&copy; {{ copyright }}</p>
			</div>

			<div class="footer__version">
				v {{version}}
			</div>

			<div class="footer__credit">
				<div>Made with <span class="footer__cactus">🌵</span></div>
				<div>by a <a target="_blank" href="https://twitter.com/jasonsteppdev">solo developer</a></div>
			</div>

		</div>

	</footer>
</template>

<script>
import { version } from '../../../package'
export default {
	name: 'TheFooter',
	data(){
		return{
			version: version
		}
	},
	computed:{
		copyright() {
			return new Date().getFullYear();
		}
	}
}
</script>