import api from '@/services/api'

class UserService{
 
	static async registerUser(user) {
		return await api().post(`/users/register`, user)
	}

	static async loginUser(user) {
		return await api().post(`/users/login`, user)
	}

	static async getProfile() {
		return await api().post(`/users/profile`)
	}

	static async GetProfile() {
		return await api().post('/users/profile')
	}

	static async checkUser(user) {
		return await api().post(`/users/check`, user)
	}

	static async checkUsername(user) {
		return await api().post(`/users/check-username`, user)
	}

	// static async getUsers() {
	// 	return await api().get('/user')
	// }

	static async getUser(id) {
		return await api().get(`/users/${id}`)
	}

	static async getUserSlug(slug) {
		return await api().get(`/users/slug/${slug}`)
	}

	static async logout() {
		return await api().delete(`/users/logout`)
	}

	static async updateSettings(form) {
		return await api().patch(`/users/update/settings`, form)
	}

	static async updatePassword(form) {
		return await api().patch(`/users/update/password`, form)
	}

	// static async addUser(user) {
	// 	return await api().post(`/user`, user)
	// }

	// static async updateUser(id, user) {
	// 	return await api().patch(`/user/${id}`, user)
	// }

	// static async deleteUser(id) {
	// 	return await api().delete(`/user/${id}`)
	// }
	
}

export default UserService