<template>
	<div class="page">
		
		<TheLoader v-if="!mounted"></TheLoader>

		<section class="page__content" :class="{show: mounted}">
			<div class="page__wrapper">

				<div class="article">
					
					<h1 class="article__title">Credits</h1>
					<p>Special thanks to everyone who supports the site.</p>

					<div class="credits__col2">
						<h3>Supporters</h3>
						<ul>
							<li>tengu</li>
							<li>vanargandhi</li>
							<li>Vitalsigns</li>
							<li>neon.wir</li>
						</ul>
					</div>

					<div class="credits__supporters">
						<!-- <div class="credits__col1">
							<h3>Legendary Supporters</h3>
							<h3>Elite Supporters</h3>
							<h3>Diamond Supporters</h3>
						</div> -->
					</div>

					<p>If you would like to support the site please check out the 
						<a target="_blank" href="https://www.patreon.com/gamestashapp"><span class="branding">Patreon</span></a> or 
						<a target="_blank" href="https://ko-fi.com/gamestashapp"><span class="branding">Ko-Fi</span></a> page. Any donation, 
						one-time or monthly, will be credited here.</p>
				
				</div>

			</div>
		</section>

	</div>
</template>

<script>
export default {
	name: "Credits",
	data(){
		return{
			mounted: false,
		}
	},
	async mounted(){
		this.mounted = true
	},
}
</script>