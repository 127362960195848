import UserRegister from '@/views/User/UserRegister.vue'
import UserLogin from '@/views/User/UserLogin.vue'
import UserProfile from '@/views/User/UserProfile.vue'
import UserSettings from '@/views/User/UserSettings.vue'
import UserForgot from '@/views/User/UserForgot.vue'
import UserReset from '@/views/User/UserReset.vue'
import User from '@/views/User/User.vue'

const UserRoutes = [
	{
		path: '/register',
		name: 'UserRegister',
		component: UserRegister,
		meta: {
			title: 'Register - GameStash',
		},
	},
	{
		path: '/login',
		name: 'UserLogin',
		component: UserLogin,
		meta: {
			title: 'Login - GameStash',
		},
	},

	{
		path: '/settings',
		name: 'UserSettings',
		component: UserSettings,
		meta: {
			title: 'Settings - GameStash',
		},
	},

	{
		path: '/profile',
		name: 'UserProfile',
		component: UserProfile,
		meta: {
			title: 'Profile - GameStash',
		},
	},

	{
		path: '/:slug',
		name: 'User',
		component: User,
		meta: {
			title: 'GameStash - Video game collecting for serious collectors',
		}
	},


	{
		path: '/forgot',
		name: 'UserForgot',
		component: UserForgot,
		meta: {
			title: 'GameStash - Video game collecting for serious collectors',
		}
	},

	{
		path: '/reset',
		name: 'UserReset',
		component: UserReset,
		meta: {
			title: 'GameStash - Video game collecting for serious collectors',
		}
	},
]

export default UserRoutes