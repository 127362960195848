import api from '@/services/api'

class ScreenshotService{
	static async getScreenshotFromGame(gameid) {
		return await api().get(`/screenshot/game/${gameid}`)
	}

	static async getScreenshotAny(id) {
		return await api().get(`/screenshot/any/${id}`)
	}
}

export default ScreenshotService
