<template>
	<div class="page">

		<TheLoader v-if="!mounted"></TheLoader>

		<div class="page__content" :class="{ show: mounted }">
			
			<div class="home">

				<div :class="showheader && show" :style="backgroundArt" id="header-art" class=""></div>
				<div id="fade-art" class=""></div>

				<div v-if="!user">
					<HomeNewUser />
				</div>
				<div v-else>
					<HomeDashboard />
				</div>

				<div class="grid" v-if="games && games.length > 0" style="opacity:0;" :class="showPopular && show">
					<h3 class="home__category-title category-title">Popular Games Right Now</h3>
					<div class="grid__box">
						<div :style="game.cover_url ? getBackGround(game.cover_url) : '/no-cover.png'" class="game-card game-card--six game-card--rounded" :key="game.id" v-for="game in games">
							<div>
								<router-link class="game-card__home-link" v-if="game.title" :to="{name: 'Game', params:{id: game.id, url: game.slug}}">
									<!-- <img :class="showHype && show" class="game-card__image game-card__image--rounded" :src="game.coverArt"> -->
								</router-link>
							</div>			
						</div>
					</div>
				</div>


				<div v-if="upcomingGames && upcomingGames.length > 0" style="opacity:0;" :class="showUpcoming && show">
					<h3 class="home__category-title category-title">Upcoming Games</h3>
					<div class="grid__box">
						<div :style="upcomingGame.cover_url ? getBackGround(upcomingGame.cover_url) : '/no-cover.png'" class="game-card game-card--six game-card--rounded" :key="upcomingGame.id" v-for="upcomingGame in upcomingGames">
							<div>
								<router-link class="game-card__home-link" v-if="upcomingGame.title" :to="{name: 'Game', params:{id: upcomingGame.id, url: upcomingGame.slug}}">
									<!-- <img :class="showUpcoming && show" class="game-card__image game-card__image--rounded" :src="upcomingGame.coverArt"> -->
								</router-link>
							</div>			
						</div>
					</div>
				</div>


				<div v-if="hypedGames && hypedGames.length > 0"  style="opacity:0;" :class="showHype && show">
					<h3 class="home__category-title category-title">Most Anticipated</h3>
					<div class="grid__box">
						<div :style="hypedGame.cover_url ? getBackGround(hypedGame.cover_url) : '/no-cover.png'" class="game-card game-card--six game-card--rounded" :key="hypedGame.id" v-for="hypedGame in hypedGames">
							<div>
								<router-link class="game-card__home-link" v-if="hypedGame.title" :to="{name: 'Game', params:{id: hypedGame.id, url: hypedGame.slug}}">
									<!-- <img :class="showHype && show" class="game-card__image game-card__image--rounded" :src="hypedGame.coverArt"> -->
								</router-link>
							</div>			
						</div>
					</div>
				</div>

			</div>
	
			<div class="error" v-if="!games || games.length < 1">
				<div class="error__message">Something went wrong...</div>
			</div>
		</div>

	</div>
</template>

<script>

import GameService from '@/services/gameService'
import ArtService from '@/services/artService'
import ScreenshotService from '@/services/screenshotService'
import HelperService from '@/services/helperService'

import HomeNewUser from '@/components/home/HomeNewUser.vue'
import HomeDashboard from '@/components/home/HomeDashboard.vue'

export default {
	name: "Games",
	components:{
		HomeNewUser,
		HomeDashboard,
	},
	data(){
		return{
			games:{},
			upcomingGames: [],
			hypedGames:[],
			mounted: false,
			error: '',
			temp: null,
			covers: [],
			screenshots:[],
			backgroundArt: null,
			showheader: false,
			showPopular: false,
			showUpcoming: false,
			showHype: false,
			show: 'show',
			//user: {},
		}
	},
	computed:{
		user(){
			return this.$store.state.user.id
		}
	},
	watch:{
		user(){
			return this.$store.state.user.id
		}
	},
	async mounted(){

		//this.user = await HelperService.GetUser()
		
		this.games = (await GameService.getGamesPopularSpecific(6)).data.result
		this.upcomingGames = (await GameService.getGamesPopularUpcoming(6)).data.result
		this.hypedGames = (await GameService.getGameHyped(6)).data.result
		
		this.mounted = true
		this.showPopular = true
		this.showUpcoming = true
		this.showHype = true


		this.temp = '{'
		if (this.games && this.games.length > 0) {
			for(let i = 0; i < this.games.length; i++){
				this.temp += this.games[i].id + ','

				let tempScreenshot = JSON.parse(JSON.stringify(this.games[i].screenshots))
				if(tempScreenshot)
					this.screenshots = this.screenshots.concat(tempScreenshot)
			}
		}
		this.temp = this.temp.slice(0, -1)
		this.temp += '}'
		

		this.temp = '{'
		if (this.screenshots.length > 0) {
			for(let i = 0; i < this.screenshots.length; i++){
				this.temp += this.screenshots[i] + ','
			}
		}
		this.temp = this.temp.slice(0, -1)
		this.temp += '}'


		if(this.screenshots)
			this.screenshots = (await ScreenshotService.getScreenshotAny(this.temp)).data.result


		if(this.screenshots.length > 0){
			this.backgroundArt = `background-image: url(${this.screenshots[Math.floor(Math.random() * this.screenshots.length)].url.replace("thumb", "screenshot_big").replace('.jpg', '.webp')})`
		} else {
			this.backgroundArt = `background-image: none;`
		}

		this.showheader = true
		
	},
	methods:{
		formatDate(date){
			return date = new Date(date).toLocaleDateString("en-US")
		},

		releaseYear(date){
			return date = new Date(date).getFullYear()
		},

		titleToURL(title){
			return title.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "-")
		},

		getBackGround(url){
			return `background-image: url('${url}')`
		}
	}
}
</script>
