import AuthService from '@/services/authService'
import UserService from '@/services/userService'

class Helper{

	static async getAuthorizedUser() {
		let users, res

		try {
			res = (await AuthService.authenticateToken()).data.result
		} catch (err) {
			return null
		}
		if (res == null) return null
		

		try {
			users = (await UserService.getProfile(res)).data.result
		} catch (err) {
			return null
		}

		if (users == null) return null
		else return users[0]
	}

	static async GetUser() {
		return (await UserService.GetProfile()).data.result
	}

	static titleToURL(title){
		return title.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "-")
	}

	static formatDate(date){
		return date = new Date(date).toLocaleDateString("en-US")
	}

	static formateDateForForm(date) {
		return date = new Date(date).toISOString().split('T')[0]
	}

	static getKeyByValue(object, value) {
		return Object.keys(object).find(key => object[key] === value);
	}


	static dynamicSort(property) {
		var sortOrder = 1;
		if(property[0] === "-") {
			sortOrder = -1;
			property = property.substr(1);
		}
		return function (a,b) {
			/* next line works with strings and numbers, 
			 * and you may want to customize it to your needs
			 */
			var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
			return result * sortOrder;
		}
	}


	static getCookies() {
		let cookies = document.cookie.split(';').reduce(
			(cookies, cookie) => {
				const [name, val] = cookie.split('=').map(c => c.trim())
				cookies[name] = val;
				return cookies
			}, {})
		return cookies
	}

}

export default Helper