<template>
	<div class="page page__block">
		<section class="page__content" :class="{show: mounted}">
			<div class="page__wrapper">

				<transition-group name="add-form" mode="out-in">
					<div v-if="submitted" class="game" style="text-align:center; align-items:center; font-size:1.1rem;">Please check your inbox for a reset link</div>
				</transition-group>

				<div class="article">

					<transition-group name="add-form" mode="out-in">
					<h1 v-if="!submitted">Reset Password</h1>
					
					<form v-if="!submitted" @submit.prevent>
						<div :class="{'form__error--show': error.generic}" class="form__error">
							<span class="material-icons">error</span>{{error.generic}}
						</div>

						<label for="email">Email</label>
						<input @blur="checkUser" v-model="form_data.email" type="email" name="email" required>
						<div :class="{'form__error--show': error.email}" class="form__error">
							<span class="material-icons">error</span>{{ error.email }}
						</div>

						<button @click="sendResetLink">
							<div class="button__loader-container"><div :class="checking && 'loader-show'" class="loader"></div></div>
							<div class="button__text" :class="checking && 'button__text--hidden'">Reset</div>
						</button> 
						
					</form>
					</transition-group>
				</div>
			</div>
		</section>
	</div>
</template>


<script>

import UserService from '@/services/userService'
import AuthService from '@/services/authService'
import HelperService from '@/services/helperService'
import MailService from '@/services/mailService'

export default {
	name: 'UserLogin',
	data(){
		return{
			form_data:{
				email: null,
				id: null,
			},
			error:{
				email: null,
				generic: null,
			},
			checking: false,
			clicked: false,
			loader: 'loader-show',
			attempt: 0,
			mounted: false,
			submitted: false,
		}
	},
	computed:{
		user(){
			return this.$store.state.user.id
		},
	},
	watch:{
		user(){
			if(this.$store.state.user.id)
				this.$router.push(`/`)
		}
	},
	async mounted(){
		this.mounted = true
	},
	methods:{

		async sendResetLink(e){

			this.resetErrors()
			e.target.disabled = true
			this.checking = true
			this.clicked = true


			const userCheck = await(this.checkUser())
			if(!userCheck) return

			this.form_data.id = userCheck
			
			try {
				e.target.disabled = true
				this.checking = true
				this.clicked = true
				let response = (await MailService.sendMail(this.form_data)).data.result 
				if(response){
					this.submitted = true
				}
				
			} catch(err){
				console.log(err)
				this.resetErrors()
				this.error.generic = ` Something went wrong`
				e.target.disabled = false
			}

		},

		async checkUser(){

			let res = (await UserService.checkUser(this.form_data)).data.result
			if(res.length < 1) {
				this.resetErrors()
				this.error.email = ' Email address not found'
				return false
			}	
			
			return res[0].id
		},

		resetErrors(){
			this.error.generic = null
			this.error.email = null
			this.checking = false
		}

	}
}
</script>