<template>
	<div class="page">
		
		<TheLoader v-if="!mounted"></TheLoader>

		<ModalWindow :class="{'modal--show': showModal}" @toggleModal="emittoggleModal" @selectConfirm="selectConfirm" :title="'Are you sure you want to delete?'" :subtitle="deleteGame" :confirmText="'Delete'" :cancelText="'Cancel'"  />
		<GameJournalModal :class="{'modal--show': showJournalModal}" @toggleJournalModal="toggleJournalModal" :title="`Add to library`" :subtitle="``" :game="journalGame" :journal="journalModal" :event="event" :objIndex="objIndex" :gameJournalExists="gameJournalExists" :confirmText="journalConfirmText" cancelText="Cancel"  />

		<ReviewModal :class="{'modal--show': showReviewModal}" @toggleReviewModal="toggleReviewModal" :title="`Add review`" :subtitle="``" :game="reviewGame" :review="reviewModal" :event="event" :objIndex="objIndex" :gameReviewExists="gameReviewExists" :confirmText="reviewConfirmText" cancelText="Cancel"  />

		<div class="page__content" :class="{ show: mounted }">
			
			<div class="game" :key="game.id" v-for="game in games">
				
				<div :class="showheader && show" :style="headerArt" id="header-art" class=""></div>
				<div id="fade-art"  class="user__header-art"></div>

				<h1 v-if="!isAuthUser" class="game__title user__library-title"><router-link :to="{ name: 'Library', slug: username }"><span class="branding">{{username}}'s</span> Library</router-link></h1>


				<div class="game__grid library__grid">
					

					<div class="cover-block library__cover-block">
						<div>
							<div :class="showcover && show" class="game__cover">
								<img v-if="game.coverArt" :src="game.coverArt" />
								<div class="game__cover-none" v-if="game.coverArt == '/no-cover.png'">No Cover</div>
							</div>
						</div>

						
						<div class="game__ui library__ui library__ui-game-link">
							<div><router-link v-if="game.title" :to="{name: 'Game', params:{id: game.game_id, url: game.slug }}">View Game Page</router-link> </div>
						</div>
						
						<div v-if="isAuthUser" class="game__ui">

							<button title="Edit" class="game__wishlist-button">
								<router-link :to="{name: 'LibraryEditGame', params:{id: game.id }}"><span class="material-icons">edit_note</span><span class="material-icon-word library__game-words"></span></router-link> 
							</button>

							<button @click="beginDeleteLibraryGame(game)" title="Delete" class="game__wishlist-button">
								<div :class="game.checking && 'show'" class="list-view__loader"><div class="loader-show loader"></div></div>
								<span  class="list-view__library-delete-button"><span class="material-icons">delete_forever</span><span class="material-icon-word library__game-words"></span></span>
							</button>

							<button title="Journal" @click="journalEntry($event, game, 0)" class="game__wishlist-button">
								<span v-if="game.game_status" class="material-icons favorited">sports_esports</span>
								<span v-else class="material-icons">sports_esports</span>
							</button>

							<button title="Review" @click="reviewEntry($event, game, 0)" class="game__wishlist-button">
								<i v-if="userRating" class="fa-solid fa-pen-to-square favorited"></i>
								<i v-else class="fa-solid fa-pen-to-square"></i>
							</button>

						</div>

						<div v-if="game.hidden" class="library__visible">
							<span class="material-icons">visibility_off</span> Hidden
						</div>

						<div class="game__reviews" v-if="isAuthUser">
							<fieldset id="rating" class="rating">
								<input @change="updateReview($event, game.game_id)" v-model="userRating" type="radio" id="rating-star5" name="rating" value="5" /><label class = "full" for="rating-star5"   title="5 Stars"></label>
								<input @change="updateReview($event, game.game_id)" v-model="userRating" type="radio" id="rating-star4half" name="rating" value="4.5" /><label class = "half" for="rating-star4half"   title="4.5 Stars"></label>
								<input @change="updateReview($event, game.game_id)" v-model="userRating" type="radio" id="rating-star4" name="rating" value="4" /><label class = "full" for="rating-star4"   title="4 Stars"></label>
								<input @change="updateReview($event, game.game_id)" v-model="userRating" type="radio" id="rating-star3half" name="rating" value="3.5" /><label class = "half" for="rating-star3half"   title="3.5 Stars"></label>
								<input @change="updateReview($event, game.game_id)" v-model="userRating" type="radio" id="rating-star3" name="rating" value="3" /><label class = "full" for="rating-star3"   title="3 Stars"></label>
								<input @change="updateReview($event, game.game_id)" v-model="userRating" type="radio" id="rating-star2half" name="rating" value="2.5" /><label class = "half" for="rating-star2half"   title="2.5 Stars"></label>
								<input @change="updateReview($event, game.game_id)" v-model="userRating" type="radio" id="rating-star2" name="rating" value="2" /><label class = "full" for="rating-star2"   title="2 Stars"></label>
								<input @change="updateReview($event, game.game_id)" v-model="userRating" type="radio" id="rating-star1half" name="rating" value="1.5" /><label class = "half" for="rating-star1half"   title="1.5 Star"></label>
								<input @change="updateReview($event, game.game_id)" v-model="userRating" type="radio" id="rating-star1" name="rating" value="1" /><label class = "full" for="rating-star1"   title="1 Star"></label>
								<input @change="updateReview($event, game.game_id)" v-model="userRating" type="radio" id="rating-starhalf" name="rating" value="0.5" /><label class = "half" for="rating-starhalf"   title="0.5 Star"></label>
							</fieldset>
						</div>

						<div class="game__reviews" v-else>
							<div v-if="!game.review_private" class="Stars" :style="`--rating: ${userRating};`"></div>
						</div>
	
					</div>

					<div class="title-block"><h1 class="game__title">{{game.title}}</h1></div>
					

					<div class="game__attributes platforms-block" >
						<div v-if="game.edition" class="library__edition">{{game.edition}}</div>
						<div v-if="game.platform" class="game__attributes-attribute">
							{{game.platform}}
						</div>
						<div v-if="game.region" class="game__attributes-attribute library__pill--transparent">
							{{game.region}}
						</div>
						<div v-if="game.game_type" class="game__attributes-attribute library__pill--transparent">
							{{game.game_type}}
						</div>
					</div>


					<div class="left-block">

						<div v-if="game.ownership || journalStatus">
							<h3 v-if="isAuthUser" class="category-title">My Stats</h3>
							<h3 v-else class="category-title">{{username}}'s Stats</h3>

							<div class="game__attribute-box">
								<div class="game__attributes-title">Ownership</div>
								<div v-if="game.ownership">
									<div>{{game.ownership}}</div>
								</div>
							</div>	

							

							<div class="game__attribute-box">
								<div v-if="isAuthUser && game.game_status || game.game_status && !game.journal_private">
									<div class="game__attributes-title">Play Status</div>
									<div></div>
									<div v-if="game.game_status" class="list-view__game-status">
										<span v-if="game.game_status === 'Playing'" class="playing">{{game.game_status}}</span>
										<span v-else-if="game.game_status === 'Played'" class="played">{{game.game_status}}</span>
										<span v-else-if="game.game_status === 'Backlogged'" class="backlogged">{{game.game_status}}</span>
										<span v-else-if="game.game_status === 'Quit'" class="quit">{{game.game_status}}</span>
										<span v-else-if="game.game_status === 'Paused'" class="paused">{{game.game_status}}</span>
										<span v-else-if="game.game_status === '100%'" class="one-hundred">100%</span>
									</div>
									<span v-if="isAuthUser && game.game_status && game.journal_private" title="Journal Hidden" class="material-icons review-visible">visibility_off</span> 
	
									<div v-if="game.journal_date">
										<span class="library__attr-title">Date:</span>
										<span class="library__attr-data">{{formatDate(game.journal_date)}}</span>
									</div>
	
								</div>
							</div>
						</div>

					</div>

					<div class="game__content content-block">

						<span class="list-view__library-attr library__attr-status" v-if="(game.sealed || game.complete) && game.game_type == 'Physical'">
							<span v-if="game.sealed">Sealed</span>
							<span class="cib" v-if="game.complete">CIB</span>
						</span>

						
						
						<div v-if="game.game_type == 'Physical'">

							<div v-if="game.quality" class="library__quality-header">
								<div class="library__attr-title library__attr-title--main">Overall Quality: </div>
								<span v-if="game.quality == 0" class="library__attr-data library__attr-data--main terrible">Terrible</span>
								<span v-if="game.quality == 1" class="library__attr-data library__attr-data--main bad">Bad</span>
								<span v-if="game.quality == 2" class="library__attr-data library__attr-data--main okay">Okay</span>
								<span v-if="game.quality == 3" class="library__attr-data library__attr-data--main good">Good</span>
								<span v-if="game.quality == 4" class="library__attr-data library__attr-data--main very-good">Very Good</span>
								<span v-if="game.quality == 5" class="library__attr-data library__attr-data--main excellent">Excellent</span>
								<span v-if="game.quality == 6" class="library__attr-data library__attr-data--main mint">Mint <span class="material-icons">diamond</span></span>
							</div>
	
							<div v-if="game.game_media || game.box || game.manual || game.inserts" class="library__quality-wrapper">
								<div class="library__quality">
									<div v-if="game.game_media" class="library__quality-container">
										<div class="add-form__checkbox-group"><span class="checked checkbox library__checkbox"></span>Media</div>
										<div class="list-view__library-attr library__quality-box">
											<span class="library__attr-title">Quality:</span>
											<span v-if="game.game_media_quality == 0" class="library__attr-data terrible">Terrible</span>
											<span v-if="game.game_media_quality == 1" class="library__attr-data bad">Bad</span>
											<span v-if="game.game_media_quality == 2" class="library__attr-data okay">Okay</span>
											<span v-if="game.game_media_quality == 3" class="library__attr-data good">Good</span>
											<span v-if="game.game_media_quality == 4" class="library__attr-data very-good">Very Good</span>
											<span v-if="game.game_media_quality == 5" class="library__attr-data excellent">Excellent</span>
											<span v-if="game.game_media_quality == 6" class="library__attr-data mint">Mint <span class="material-icons">diamond</span></span>
										</div>
									</div>
				
									<div v-if="game.box" class="library__quality-container">
										<div class="add-form__checkbox-group"><span class="checked checkbox library__checkbox"></span>Box</div>
										<div class="list-view__library-attr library__quality-box">
											<span class="library__attr-title">Quality:</span>
											<span v-if="game.box_quality == 0" class="library__attr-data terrible">Terrible</span>
											<span v-if="game.box_quality == 1" class="library__attr-data bad">Bad</span>
											<span v-if="game.box_quality == 2" class="library__attr-data okay">Okay</span>
											<span v-if="game.box_quality == 3" class="library__attr-data good">Good</span>
											<span v-if="game.box_quality == 4" class="library__attr-data very-good">Very Good</span>
											<span v-if="game.box_quality == 5" class="library__attr-data excellent">Excellent</span>
											<span v-if="game.box_quality == 6" class="library__attr-data mint">Mint <span class="material-icons">diamond</span></span>
										</div>
									</div>
				
									<div v-if="game.manual" class="library__quality-container">
										<div class="add-form__checkbox-group"><span class="checked checkbox library__checkbox"></span>Manual</div>
										<div class="list-view__library-attr library__quality-box">
											<span class="library__attr-title">Quality:</span>
											<span v-if="game.manual_quality == 0" class="library__attr-data terrible">Terrible</span>
											<span v-if="game.manual_quality == 1" class="library__attr-data bad">Bad</span>
											<span v-if="game.manual_quality == 2" class="library__attr-data okay">Okay</span>
											<span v-if="game.manual_quality == 3" class="library__attr-data good">Good</span>
											<span v-if="game.manual_quality == 4" class="library__attr-data very-good">Very Good</span>
											<span v-if="game.manual_quality == 5" class="library__attr-data excellent">Excellent</span>
											<span v-if="game.manual_quality == 6" class="library__attr-data mint">Mint <span class="material-icons">diamond</span></span>
										</div>
									</div>
		
								</div>
	
								<div v-if="game.inserts" class="game__attribute-box">
									<div class="game__attributes-title">Inserts: </div>
									<div class="library__attr-data library__attr-data--main terrible">{{game.inserts}}</div>
								</div>
							</div>

							<div v-if="game.quality_notes">
								<h3 class="category-title">Quality Notes</h3>
								<div>{{game.quality_notes}}</div>
							</div>
						</div>

						<div v-if="isAuthUser && journalNotes || journalNotes && !game.journal_private">
							<h3 v-if="isAuthUser" class="category-title">My Journal</h3>
							<h3 v-else class="category-title">{{username}}'s Journal</h3>
							<div>{{journalNotes}}</div>
						</div>

						<div v-if="isAuthUser && reviewReview || !reviewPrivate && reviewReview">
							<h3 class="category-title">Review</h3>

							<div v-if="reviewPrivate"><em>Review is private</em></div>
							<div>
								{{reviewReview}}
							</div>
						
						</div>
					

					</div>

					<div class="right-block">

						

						<h3 v-if="game.purchase_date || game.purchase_price || game.purchase_source || 
							game.sold_date || game.sold_price || game.sold_source" class="category-title">History</h3>

						<div v-if="game.purchase_date || game.purchase_price || game.purchase_source" class="game__attribute-box">
							<div class="game__attributes-title">Purchase</div>
							
							<div v-if="game.purchase_date">
								<span class="library__attr-title">Date:</span>
								<span class="library__attr-data">{{formatDate(game.purchase_date)}}</span>
							</div>

							<div v-if="game.purchase_price">
								<span class="library__attr-title">Price:</span>
								<span class="library__attr-data">{{game.purchase_price}}</span>
							</div>

							<div v-if="game.purchase_source">
								<span class="library__attr-title">Source:</span>
								<span class="library__attr-data">{{game.purchase_source}}</span>
							</div>
							
						</div>

						<div v-if="game.sold_date || game.sold_price || game.sold_source" class="game__attribute-box">
							
							<div class="game__attributes-title">Sale</div>
							<div v-if="game.sold_date">
								<span class="library__attr-title">Date:</span>
								<span class="library__attr-data">{{formatDate(game.sold_date)}}</span>
							</div>

							<div v-if="game.sold_price">
								<span class="library__attr-title">Price:</span>
								<span class="library__attr-data">{{game.sold_price}}</span>
							</div>

							<div v-if="game.sold_source">
								<span class="library__attr-title">Source:</span>
								<span class="library__attr-data">{{game.sold_source}}</span>
							</div>
						</div>

						<h3 class="category-title">Meta Info</h3>

						<div class="game__attribute-box">
							<div class="game__attributes-title">Added</div>
							<div class="library__attr-data">{{formatDate(game.created_on)}}</div>
						</div>

						<div class="game__attribute-box">
							<div class="game__attributes-title">Last Updated</div>
							<div class="library__attr-data">{{formatDate(game.last_update)}}</div>
						</div>

					</div>

					<div class="center-block">
						<div>{{game.tags}}</div>
					</div>

				</div>
				

			</div>

		</div>


		<div class="error" v-if="!games">
			<div class="error__message">Game not found!</div>
		</div>

	</div>
</template>

<script>

import JournalService from '@/services/journalService'
import LibraryService from '@/services/libraryService'
import HelperService from '@/services/helperService'
import GameService from '@/services/gameService'
import ScreenshotService from '@/services/screenshotService'
import UserService from '@/services/userService'
import ReviewService from '@/services/reviewService'

import ModalWindow from '@/components/universal/ModalWindow'
import GameJournalModal from '@/components/universal/GameJournalModal'
import ReviewModal from '@/components/universal/ReviewModal'

export default {
	name: "LibraryGame",
	components:{
		ModalWindow,
		GameJournalModal,
		ReviewModal,
	},

	data(){
		return{
			games:{},
			profile:{},
			objGame:{},
			showcover: false,
			showheader: false,
			mounted: false,
			show: 'show',
			headerArt: null,
			showModal: false,
			deleteGame: '',
			isAuthUser: false,
			username: '',

			journal:{},
			

			journalStatus: null,
			journalNotes: null,
			journalDate: null,
			journalGame:{},
			journalModal: {},

			showJournalModal: false,
			gameJournalExists: false,
			journalConfirmText: 'Add',
			objIndex: null,
			event: null,

			userRating: null,
			showReviewModal: false,
			reviewGame: {},
			review: {},
			gameReviewExists: false,
			reviewConfirmText: 'Add',
			reviewModal: {},
			reviewPrivate: false,
			reviewReview: null,
		}
	},
	computed:{
		gameObj(){
			return this.games
		},

		user(){
			return this.$store.state.user
		}
	},
	watch:{
		user(){
			return this.$store.state.user
		}
	},
	async mounted(){

		//this.user = await HelperService.getAuthorizedUser()
		if(this.user && this.user.slug === this.$route.params.slug)
			this.isAuthUser = true
		
		
		
		this.profile = (await UserService.getUserSlug(this.$route.params.slug)).data.result
		if(this.profile)
			this.username = this.profile[0].username

		this.games = (await LibraryService.getLibraryGame(this.$route.params.id, this.$route.params.slug)).data.result
		if(this.games.length < 1) this.$router.push(`/`)
	
		if(!this.isAuthUser && this.games[0].hidden)
			this.$router.push('/404')

		this.journal = (await JournalService.getJournal(this.games[0].game_id, this.games[0].user_id)).data.result
		if(this.journal && this.journal.length > 0){
			this.journalStatus = this.journal[0].game_status
			this.journalNotes = this.journal[0].notes
			this.journalDate = this.journal[0].status_date
		}
		
		this.review = (await ReviewService.getReview(this.games[0].game_id, this.games[0].user_id)).data.result
		
		if(this.review && this.review.length > 0){
			this.userRating = this.review[0].rating
			this.reviewPrivate = this.review[0].review_private
			this.reviewReview = this.review[0].review
		}

		this.mounted = true

		this.covers = (await GameService.getGameCovers(this.games, true))
		
		if(this.covers.length > 0)
			this.games = (await GameService.setGameCovers(this.games, this.covers, true))
		else
			this.games[0].coverArt = '/no-cover.png'
		

		this.showcover = true


		this.screenshots = (await ScreenshotService.getScreenshotFromGame(this.games[0].game_id)).data.result
		
		if(this.screenshots.length > 0){
			let random = Math.floor(Math.random() * this.screenshots.length)
			this.headerArt = `background-image: url(${this.screenshots[random].url.replace("thumb", "screenshot_big").replace('.jpg', '.webp')})`
		}

		this.showheader = true

		//this.game = this.game[0]
	},
	methods:{
		formatDate(date){
			return date = new Date(date).toLocaleDateString("en-US")
		},

		async deleteLibraryGame(id){
			this.toggleModal()
			try{
				let response = (await LibraryService.deleteLibraryGame({id: id})).data.result
				if(response)
					await this.$router.push(`/${this.$route.params.slug}/library`)
			} catch(err) {
				console.error(err)
			}
		},

		beginDeleteLibraryGame(game){
			this.objGame = game
			this.deleteGame = game.title
			this.toggleModal()
		},

		toggleModal(){
			this.showModal  = !this.showModal
		},

		emittoggleModal(){
			this.toggleModal()
		},

		selectConfirm(){
			this.deleteLibraryGame(this.objGame.id)
		},


		toggleJournalModal(confirm, event, deleted, objIndex, gameStatus, gameDate, journalPrivate, journalNotes){

			if(confirm)
				event.target.children[0].classList.add("favorited")
			
			if(deleted)
				event.target.children[0].classList.remove("favorited")

			if(confirm || deleted){
				this.gameObj[objIndex].game_status = gameStatus
				this.gameObj[objIndex].journal_date = gameDate
				this.gameObj[objIndex].journal_private = journalPrivate
				this.journalNotes = journalNotes
			}
			
			this.showJournalModal = !this.showJournalModal
		},


		async journalEntry($event, game, index){
			this.journalGame = {id: game.game_id};
			this.journalModal = (await JournalService.getJournal(game.game_id, game.user_id)).data.result
			this.gameJournalExists = true
			this.event = $event

			if(this.journalModal.length === 0){
				this.journalModal = [{id: null, user_id: null, game_id: null, game_status: 'Played', status_date: null, notes: null}]
				this.gameJournalExists = false
			}

			if(this.gameJournalExists)
				this.journalConfirmText = 'Update'
			else
				this.journalConfirmText = 'Add'

			if(Date.parse(this.journalModal[0].status_date))
				this.journalModal[0].status_date = new Date(this.journalModal[0].status_date).toISOString().split('T')[0]
			else 
				this.journalModal[0].status_date = null

			this.objIndex = index

			this.toggleJournalModal()
		},


		async reviewEntry($event, game, index){
			
			this.reviewGame = {id: game.game_id};

			this.reviewModal = (await ReviewService.getReview(game.game_id,  game.user_id)).data.result
			this.gameReviewExists = true
			this.event = $event

			if(this.reviewModal.length === 0){
				this.reviewModal = [{id: null, user_id: null, game_id: null, rating: null, review: null, review_private: null}]
				this.gameReviewExists = false
			}

			if(this.gameReviewExists)
				this.reviewConfirmText = 'Update'
			else
				this.reviewConfirmText = 'Add'

			this.objIndex = index

			this.toggleReviewModal()
		},



		toggleReviewModal(confirm, event, deleted, form, objIndex){

			if(confirm)
				event.target.classList.add("favorited")

			if(deleted)
				event.target.classList.remove("favorited")

			if(confirm || deleted){
				this.userRating = form.rating
				this.reviewPrivate = form.privateRatingCheck
				this.reviewReview = form.ratingCommentInput
			}
				
			this.showReviewModal = !this.showReviewModal
		},



		async updateReview(event, game_id){
			let form = {
				rating: null,
				game_id: null,
			}

			form.rating = this.userRating
			form.game_id = game_id 
			form.gameid = game_id 
			form.ratingCommentInput = null
			form.privateRatingCheck - false

			let review = (await ReviewService.getAuthReview(game_id)).data.result
			
			let response
			if(review.length > 0)
				response = (await ReviewService.updateReviewRating(form)).data.result
			else
				response = (await ReviewService.addReview(form)).data.result

		},


	},
}
</script>