import axios from 'axios'

export default () => {
	const instance = axios.create({ baseURL: '/api/v1' })

	let urls = [
			/users\/(.)/,
			/reviews\/(.)/,
			/journals\/(.)/,
			/library\/(.)/,
			/wishlists\/(.)/,
			/dashboard\/missing\/(.)/,
	]
	
	instance.interceptors.request.use(async req => {
		for (let i = 0; i < urls.length; i++){
			if (req.url.match(urls[i])) {
				let response = await axios.get(`/api/v1/auth/refresh`)
			}
		}
		return req
	})

	return instance 
}
