<template>
	<div>

		<label :for="title">{{title}}</label>
		<div class="dropdown">
			<input :id="title + '-dropdown__input'" class="dropdown__input" v-model="searchedField" @focus="searchFields" @input="searchFields" @keydown="searchFields" @keyup="addButtonListeners" :name="title" type="text" placeholder="&#xF002;" style="font-family:Arial, FontAwesome">
			
			<div v-if="type === 'developer'" tabindex="0" :id="title + '-dropdown'" class="dropdown__container">
				<div class="dropdown__item" :class="'dropdown__item_' + type" tabindex="0" :id="'developer-' + developer.id" v-for="developer in dataObject" :key="developer.id" :value="developer.title" @mousedown.prevent="" @mouseup="selectField" >{{developer.title}}</div>
			</div>
			<div v-else-if="type === 'publisher'" tabindex="0" :id="title + '-dropdown'" class="dropdown__container">
				<div class="dropdown__item" :class="'dropdown__item_' + type" tabindex="0" :id="'publisher-' + publisher.id" v-for="publisher in dataObject" :key="publisher.id" :value="publisher.title" @mousedown.prevent="" @mouseup="selectField" >{{publisher.title}}</div>
			</div>
			<div v-else-if="type === 'platform'" tabindex="0" :id="title + '-dropdown'" class="dropdown__container">
				<div class="dropdown__item" :class="'dropdown__item_' + type" tabindex="0" :id="'platform-' + platform.id" v-for="platform in dataObject" :key="platform.id" :value="platform.platform" @mousedown.prevent="" @mouseup="selectField" >{{platform.platform}}</div>
			</div>
			<div v-else-if="type === 'tag'" tabindex="0" :id="title + '-dropdown'" class="dropdown__container">
				<div class="dropdown__item" :class="'dropdown__item_' + type" tabindex="0" :id="'tag-' + tag.id" v-for="tag in dataObject" :key="tag.id" :value="tag.title" @mousedown.prevent="" @mouseup="selectField" >{{tag.title}}</div>
			</div>
			<div v-else-if="type === 'playstatus'" tabindex="0" :id="title + '-dropdown'" class="dropdown__container">
				<div class="dropdown__item" :class="'dropdown__item_' + type" tabindex="0" :id="'playstatus-' + playstatus.id" v-for="playstatus in dataObject" :key="playstatus.id" :value="playstatus.title" @mousedown.prevent="" @mouseup="selectField" >{{playstatus.title}}</div>
			</div>
	
			<div class="dropdown__pill-container">
				<transition-group name="pillbox" mode="out-in">
					<div class="dropdown__pill" @click="removePill" v-for="pill in pillbox" :key="pill.id" :data-pill-id="pill.id">
						<div class="dropdown__pill-content">
							<div v-if="type === 'platform'">{{pill.platform}}</div>
							<div v-else>{{pill.title}}</div>
							<div>&times;</div>
						</div>
					</div>
				</transition-group>
			</div>
		</div>
	
	</div>

</template>


<script>
export default {
	name: "BrowseDropdown",
	props: ['title', 'data', 'type', 'values'],
	data(){
		return{
			pillbox: [],
			objData: [],
			searchedField: '',
			selectedField: null,
			mounted: false,
			listItems: null,
			dropdownInputs: null,
			dropdownContainers: null,
			listItemsID: [],
		}
	},
	mounted(){
		

		if(this.values)
			this.init()

		this.mounted = true

		this.dropdownInputs = document.querySelectorAll('.dropdown__input')
		this.dropdownContainers = document.querySelectorAll('.dropdown__container')

		this.dropdownInputs.forEach(input => {
			input.addEventListener("keydown", e=>{
				if(["ArrowUp","ArrowDown","ArrowLeft","ArrowRight"].indexOf(e.code) > -1) {
					e.preventDefault();
				}
				switch(e.key){
					case "ArrowDown":
						this.focusNextListItem("ArrowDown");
						return
					case "ArrowUp":
						this.focusNextListItem("ArrowUp");
						return
					case "Escape":
						this.hideDropdown();
						return
					default:
						return
				}
			})
		})

		this.dropdownContainers.forEach(input => {
			input.addEventListener("keydown", e=>{
				if(["Space","ArrowUp","ArrowDown","ArrowLeft","ArrowRight"].indexOf(e.code) > -1) {
					e.preventDefault();
				}
				switch(e.key){
					case "ArrowDown":
						this.focusNextListItem("ArrowDown");
						return
					case "ArrowUp":
						this.focusNextListItem("ArrowUp");
						return
					case "Escape":
						this.hideDropdown();
						return
					default:
						return
				}
			})
		})


	},
	computed:{
		dataObject(){
			return this.objData
		},

		dataValues(){
			return this.data
		},
	},
	methods:{
		init(){

			for(let i = 0; i < this.values.length; i++){
				
				let obj = null;

				obj = this.data.find(o => o.id == this.values[i])
				
				if(this.pillbox.length > 0){
					if(!this.pillbox.find(pill => pill.id == obj.id))
						this.pillbox.push(obj)
					
				} else {
					this.pillbox.push(obj)
				}
				
			}

			this.$emit('fieldSelected', this.pillbox)

		},

		getID(){
			let obj = null;
			if(this.type === 'platform')
				obj = this.data.find(o => o.platform === this.selectedField)
			else
				obj = this.data.find(o => o.title === this.selectedField)

			if(this.pillbox.length > 0){
				if(!this.pillbox.find(pill => pill.id === obj.id))
					this.pillbox.push(obj)
			} else {
				this.pillbox.push(obj)
			}

			this.selectedField = obj.id

			this.$emit('fieldSelected', this.pillbox)
		},

		searchFields(){
			this.objData = []
			
			if(this.type === 'playstatus'){
				
				for(let i = 0; i < this.data.length; i++){
					if(this.data[i].title.toLowerCase().includes(this.searchedField.toLowerCase())){
						this.objData.push(this.data[i])
					}
				}
				
			} else {
				if(this.searchedField.length > 1){
					for(let i = 0; i < this.data.length; i++){
						
						if(this.type === 'platform'){
							
							if(this.data[i].platform.toLowerCase().includes(this.searchedField.toLowerCase())){
								this.objData.push(this.data[i])
							}
							
						} else {
							
							if(this.data[i].title.toLowerCase().startsWith(this.searchedField.toLowerCase().charAt(0))){
								
								if(this.data[i].title.toLowerCase().includes(this.searchedField.toLowerCase())){
									
									this.objData.push(this.data[i])
								}
							}
						}
					}
				}
			}

			document.getElementById(this.title + '-dropdown').classList.add('dropdown__container--show')

		},

		hideDropdown(){
			document.getElementById(this.title + '-dropdown').classList.remove('dropdown__container--show')
			let el = document.querySelector( ':focus' );
			if( el ) el.blur();
			this.listItemsID = []
		},

		selectField($event){
			this.selectedField = $event.target.innerHTML
			this.getID()
			this.hideDropdown();
		},

		selectFieldEnter(e){
			this.selectedField = e.target.innerHTML
			this.getID()
			this.hideDropdown();
		},

		removePill($event){
			this.pillbox =  this.pillbox.filter(obj => obj.id != $event.target.getAttribute("data-pill-id"))
			this.$emit('fieldSelected', this.pillbox)
		},

		addButtonListeners(){
			this.listItemsID = []
			this.listItems = document.querySelectorAll('.dropdown__item_' + this.type)
			this.listItems.forEach(item => { this.listItemsID.push(item.id)})

			this.listItems.forEach(item => {

				item.addEventListener("keypress", e =>{
					e.preventDefault()
					switch(e.key){
						case "ArrowDown": //Down arrow key
							this.focusNextListItem("ArrowDown");
							return
						case "ArrowUp": //Up arrow key
							this.focusNextListItem("ArrowUp");
							return
						default:
							return
					}
				})

				item.addEventListener("keydown", e =>{
					e.preventDefault()
					switch(e.key){
						case "Enter": //Enter key
							this.selectFieldEnter(e)
							return
						case "Escape":
							this.hideDropdown();
							return
						default:
							return
					}
				})

			})

			const activeElementId = document.activeElement.id;
		},


		focusNextListItem(direction) {
			const activeElementId = document.activeElement.id;

			if (activeElementId === "Publisher-dropdown__input" || 
					activeElementId === "Developer-dropdown__input" ||
					activeElementId === "Platform-dropdown__input" || 
					activeElementId === "Playstatus-dropdown__input" || 
					activeElementId === "Publisher-dropdown" ||
					activeElementId === "Developer-dropdown" ||
					activeElementId === "Platform-dropdown" ||
					activeElementId === "Playstatus-dropdown" ) {
				document.querySelector(`#${this.listItemsID[0]}`).focus();
			} else {
				const currentActiveElementIndex = this.listItemsID.indexOf(activeElementId);
				if (direction === "ArrowDown") {
				const currentActiveElementIsNotLastItem = currentActiveElementIndex < this.listItemsID.length - 1;
				if (currentActiveElementIsNotLastItem) {
					const nextListItemId = this.listItemsID[currentActiveElementIndex + 1];
					document.querySelector(`#${nextListItemId}`).focus();
				}
				} else if (direction === "ArrowUp") {
				const currentActiveElementIsNotFirstItem = currentActiveElementIndex > 0;
				if (currentActiveElementIsNotFirstItem) {
					const nextListItemId = this.listItemsID[currentActiveElementIndex - 1];
					document.querySelector(`#${nextListItemId}`).focus();
				}
				}
			}
		}


	},
}
</script>