import api from '@/services/api'

class PlatformService{
	static async getPlatform(id) {
		return await api().get(`/platform/${id}`)
	}

	static async getPlatformAny(id) {
		return await api().get(`platform/any/${id}`)
	}

	static async getPlatformsAll() {
		return await api().get(`platform/all`)
	}
}

export default PlatformService
