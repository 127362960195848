<template>
	<div class="page">
		<section class="page__content" :class="{show: mounted}">
			<div class="page__wrapper">
				<div class="article">
					<h1>Create an account</h1>
					
					<form @submit.prevent>
						<div :class="{'form__error--show': error.generic}" class="form__error">
							<span class="material-icons">error</span>{{error.generic}}
						</div>


						<label for="username">Username</label>
						<input @blur="checkUsername" v-model="form_data.username" type="username" name="username" required>
						<div class="form__error" :class="{'form__error--show': error.username, 'shake': error.username}">
							<span class="material-icons">error</span> {{ error.username }}
						</div>

						<label for="email">Email</label>
						<input @blur="checkUser" v-model="form_data.email" type="email" name="email" required>
						<div class="form__error" :class="{'form__error--show': error.email, 'shake': error.email}">
							<span class="material-icons">error</span> {{ error.email }}
						</div>

						<label for="password">Password</label>
						<input v-model="form_data.password" type="password" name="password" required>


						<label for="confirmPassword">Confirm Password</label>
						<input v-model="form_data.confirm" type="password" name="confirmPassword" required>
						<div :class="{'form__error--show': error.password}" class="form__error">
							<span class="material-icons">error</span> {{ error.password }}
						</div>


						<button @click="registerUser">
							<div class="button__loader-container"><div :class="checking && loader" class="loader"></div></div>
							<div class="button__text" :class="checking && 'button__text--hidden'">Sign Up</div>
						</button>

						<div>Already have an account? <router-link :to="{name:'UserLogin'}">Login</router-link> instead.</div>
						
					</form>
				</div>
			</div>
		</section>
	</div>
</template>


<script>

import UserService from '@/services/userService'
import AuthService from '@/services/authService'
import HelperService from '@/services/helperService'

export default {
	name: 'UserRegister',
	data(){
		return{
			form_data:{
				email: null,
				password: null,
				confirm: null,
				username: null,
			},
			error:{
				email: null,
				username: null,
				password: null,
				generic: null,
			},
			clicked: false,
			loader: 'loader-show',
			mounted: false,
			checking: false,
			newUser: {}
		}
	},
	computed:{
		user(){
			return this.$store.state.user.id
		},
	},
	watch:{
		user(){
			if(this.$store.state.user.id)
				this.$router.push(`/`)
		}
	},
	async mounted(){
		this.mounted = true
	},
	methods:{
		async registerUser(e){

			this.resetErrors()
			e.target.disabled = false
			this.checking = true
			this.clicked = true

			if(!this.checkFormValid()) return
			
			const usernameCheck = await(this.checkUsername())
			if(!usernameCheck) return
			
			const userCheck = await(this.checkUser())
			if(!userCheck) return

			try{
				e.target.disabled = true
				this.newUser = (await UserService.registerUser(this.form_data)).data.result
				
				//let response = await AuthService.getToken(this.user[0])
				let response = await AuthService.CreateToken(this.newUser[0])
				if(response){
					this.$store.state.user.isLoggedIn = true
					this.$store.state.user.isAuth = true
					this.$store.state.user.slug = this.newUser[0].slug
					this.$store.state.user.id = this.newUser[0].id
					this.$router.go(-1)
				}

			} catch(err){
				console.log(err)
				this.resetErrors()
				this.error.generic = ` Something went wrong`
				e.target.disabled = false
			}

			e.target.disabled = false
		},

		checkFormValid(){
			/* eslint-disable-next-line */
			let regex = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$")
			
			if(this.form_data.username == null || this.form_data.username.length == 0){
				this.resetErrors()
				this.error.username = ' Enter a username'
				return false
			}

			if(this.form_data.username && this.form_data.username != null && this.form_data.username.length > 25){
				this.resetErrors()
				this.error.username = ' Username can only be 25 characters long'
				return false
			}

			let usernameregex = /[^A-Za-z0-9\-.]/g
			if(this.form_data.username && this.form_data.username != null && usernameregex.test(this.form_data.username)){
				this.resetErrors()
				this.error.username = ' Usernames can only contain letters (a-z), numbers (0-9), periods (.), and hyphens (-).'
				return false
			}

			if(!regex.test(this.form_data.email)){
				this.resetErrors()
				this.error.email = ' Enter a valid email address'
				return false
			} 

			if(this.form_data.email  && this.form_data.email != null && this.form_data.email.length > 255){
				this.resetErrors()
				this.error.email = ' Email length too long'
				return false
			}
			
			if(this.form_data.password !== this.form_data.confirm){
				this.resetErrors()
				this.error.password = ' Passwords do not match'
				return false
			}

			if(!this.form_data.password || !this.form_data.confirm){
				this.resetErrors()
				this.error.password = ' Enter your password'
				return false
			} 

			return true
		},

		async checkUser(){

			try{
				let res = (await UserService.checkUser(this.form_data)).data.result
				if(res.length > 0) {
					this.resetErrors()
					this.error.email = ' Email already in use'
					return false
				}	
			} catch(err){
				console.log(err)
				this.resetErrors()
				this.error.generic = ' Something went wrong'
			}

			return true
		},

		async checkUsername(){

			try{
				let res = (await UserService.checkUsername(this.form_data)).data.result
				if(res.length > 0) {
					this.resetErrors()
					this.error.username = ' Username already taken'
					return false
				}
			} catch(err){
				console.log(err)
				this.resetErrors()
				this.error.generic = ' Something went wrong'
			}

			return true
		},

		resetErrors(){
			this.clicked = false
			this.checking = false
			this.error.email = null
			this.error.password = null
			this.error.generic = null
			this.error.username = null
		},

	}
}
</script>