<template>
	<div v-if="searchQuery" class="search-results">
		Displaying results for: 
		
		<div @click="removeQuery" class="dropdown__pill">
			<div class="dropdown__pill-content">
				<div>{{searchQuery}}</div>
				<div>×</div>
			</div>
		</div>
		
	</div>

</template>

<script>
export default {
	name: "SearchResults",
	props: ['searchQuery'],
	methods:{
		removeQuery(){
			this.$emit('searchRemoved', null)
		}
	},
}
</script>