<template>
	<div>
		<TheLoader v-if="!mounted"></TheLoader>

		<section class="page__content" :class="{show: mounted}">
			<div class="page__wrapper">
				
				<div class="article">
					<h1 class="article__title">Privacy Policy</h1>
					<h3>GameStash's PRIVACY POLICY</h3>
					<p>
						Our Privacy Policy describes how your personal data is collected, 
						used, and shared when you visit GameStash (https://www.gamestash.app).
					</p>

					<h3>WHAT PERSONAL INFORMATION WE COLLECT</h3>
					<p><strong>Information that you directly provide to GameStash</strong></p>

					<p>
						When you register an account:
					</p>
					
					<ul class="privacy__list">
						<li>Email address</li>
					</ul>

					<p><strong>Information that GameStash automatically collects</strong></p>

					<p>
						When you visit GameStash, we automatically collect certain information 
						about your device, including:
					</p>

					<ul class="privacy__list">
						<li>Information about your web browser</li>
						<li>IP address</li>
						<li>Time zone</li>
						<li>Cookies installed on your device</li>
					</ul>

					<p>
						Additionally, as you browse GameStash, we collect information about 
						the individual web pages that you view, what websites or 
						search terms referred you to GameStash, and information about how you 
						interact with GameStash. We refer to this automatically-collected data 
						as “Device Information.”
					</p>

					<p>We collect Device Information using the following technologies:</p>

					<ul class="privacy__list">
						<li>
							“Cookies” are data files that are placed on your device or computer and 
							often include an anonymous unique identifier. For more information about 
							cookies, and how to disable cookies, visit http://www.allaboutcookies.org
						</li>
	
						<li>
							“Log files” track actions occurring on GameStash, and collect data including 
							your IP address, browser type, Internet service provider, referring/exit 
							pages, and date/time stamps
						</li>
	
						<li>
							“Web beacons,” “tags,” and “pixels” are electronic files used to record 
							information about how you browse GameStash
						</li>
					</ul>

					<p>
						When we talk about “Personal Information” in this Privacy Policy, we are 
						talking both about Device Information and Account Information.
					</p>

					<h3>HOW WE USE YOUR PERSONAL INFORMATION</h3>
					<p>
						We use the Account Information that we collect to:
					</p>

					<ul class="privacy__list">
						<li>Communicate with you via email</li>
					</ul>

					<p>
						We use the Device Information that we collect to help us screen for potential
						 risk and fraud (in particular, your IP address), and more generally to improve 
						 and optimize GameStash (for example, by generating analytics about how our 
						 customers browse and interact with GameStash, and to assess the success of our 
						 marketing and advertising campaigns).
					</p>

					<h3>SHARING YOUR PERSONAL INFORMATION</h3>
					<p>
						We share your Personal Information with third parties to help us use your 
						Personal Information, as described above.
					</p>

					<p>https://www.google.com/intl/en/policies/privacy/</p>
					<p>You can also opt-out of Google Analytics here:</p>

					<p>https://tools.google.com/dlpage/gaoptout</p>
					<p>
						As described above, we use your Personal Information to provide you with targeted 
						advertisements or marketing communications we believe may be of interest to you.
					</p>

					<p>
						For more information about how targeted advertising works, you can visit the 
						Network Advertising Initiative's (“NAI”) educational page at 
						http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.
					</p>

					<p>You can opt out of targeted advertising by visiting the below websites:</p>

					<p>https://www.google.com/settings/ads/anonymous</p>

					<p>
						Additionally, you can opt out of some of these services by visiting the Digital 
						Advertising Alliance's opt-out portal at: http://optout.aboutads.info/.
					</p>

					<p>
						Finally, we may also share your Personal Information to comply with applicable 
						laws and regulations, to respond to a subpoena, search warrant or other lawful 
						request for information we receive, or to otherwise protect our rights.
					</p>

					<h3>DO NOT TRACK</h3>

					<p>
						Please note that we do not alter GameStash's data collection and use practices 
						when we see a Do Not Track signal from your browser.
					</p>

					<h3>YOUR RIGHTS</h3>
					<p>
						If you are a European resident, you have the right to access personal information 
						we hold about you and to ask that your personal information be corrected, 
						updated, or deleted. If you would like to exercise this right, please contact 
						us through the contact information below.
					</p>

					<p>
						Additionally, if you are a European resident we note that we are processing your 
						information in order to fulfill contracts we might have with you, or otherwise to pursue our legitimate 
						business interests listed above. Additionally, please note that your information 
						will be transferred outside of Europe, including to the United States.
					</p>

					<h3>CHANGES</h3>
					<p>
						We may update this privacy policy from time to time in order to reflect, for 
						example, changes to our practices or for other operational, legal or regulatory 
						reasons.
					</p>

					<h3>DATA RETENTION</h3>
					When you create an account through GameStash, we will maintain your Account Information 
					for our records unless and until you ask us to delete this information.

					<h3>CONTACT US</h3>
					<p>
						For more information about our privacy practices, if you have questions, or if you 
						would like to make a complaint, please contact us by e-mail at support@gamestash.app
					</p>
				</div>

			</div>
		</section>
	</div>
</template>

<script>
export default {
	name: "PrivacyPolicy",
	data(){
		return{
			mounted: false,
		}
	},
	async mounted(){
		this.mounted = true
	},
}
</script>