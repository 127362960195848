import api from '@/services/api'

class ReleaseDateService{
	static async getReleaseDate(id) {
		return await api().get(`/releasedate/${id}`)
	}

	static async getReleaseDateAny(id) {
		return await api().get(`releasedate/any/${id}`)
	}
}

export default ReleaseDateService
