<template>

	<div class="grid" style="opacity: 0;">

		<QuickAddModalWindow :class="{'modal--show': showModal}" @toggleModal="emittoggleModal" :title="`Add to library`" :subtitle="`${quickAddGame.title}`" :game="quickAddGame" :platforms="quickAddPlatforms" confirmText="Add" cancelText="Cancel"  />
		<GameJournalModal :class="{'modal--show': showJournalModal}" @toggleJournalModal="toggleJournalModal" :title="`Add to library`" :subtitle="`${journalGame.title}`" :game="journalGame" :journal="journal" :event="event" :objIndex="objIndex" :gameJournalExists="gameJournalExists" :confirmText="journalConfirmText" cancelText="Cancel"  />

		<ReviewModal :class="{'modal--show': showReviewModal}" @toggleReviewModal="toggleReviewModal" :title="`Add review`" :subtitle="`${reviewGame.title}`" :game="reviewGame" :review="review" :event="event" :objIndex="objIndex" :gameReviewExists="gameReviewExists" :confirmText="reviewConfirmText" cancelText="Cancel"  />


		<transition-group class="grid__box" name="gamecard" tag="div" appear>
			
				<div :style="getBackGround(game)" :class="{ noCover: !checkCover(game) }" class="grid__game-card game-card game-card--six" :key="game.id" v-for="(game,index) in games">
					
					<div v-if="game.game_status" class="grid-view__game-status list-view__game-status library__game-status game__game-status">
						<span v-if="game.game_status === 'Playing'" class="playing">{{game.game_status}}</span>
						<span v-else-if="game.game_status === 'Played'" class="played">{{game.game_status}}</span>
						<span v-else-if="game.game_status === 'Backlogged'" class="backlogged">{{game.game_status}}</span>
						<span v-else-if="game.game_status === 'Quit'" class="quit">{{game.game_status}}</span>
						<span v-else-if="game.game_status === 'Paused'" class="paused">{{game.game_status}}</span>
						<span v-else-if="game.game_status === '100%'" class="one-hundred">100%</span>
					</div>

					<div class="game-card__fade game-card__fade--hidden"></div>

					<div v-if="isAuthUser" class="game-card__menu">
						
						<button @click="openMenu($event, index)" :class="`grid__button grid__menu-button grid__menu-button--show grid__menu-button-${index}`">
							<div class="button__text"><span class="material-icons-outlined">more_vert</span></div>
						</button>
		
						<button @click="closeMenu($event, index)" :class="`grid__button grid__menu-button-close grid__menu-button-close-${index}`">
							<div class="button__text"><span class="material-icons-outlined">close</span></div>
						</button>


						<div :class="`grid__menu grid__menu-${index}`">

							<button title="Add" @click="addGame($event, game)" class="grid__button">
								<div class="button__text">
									<span class="material-icons">playlist_add</span> Add
								</div>
							</button>

							<button title="Quick Add" @click="quickAdd($event, game)" class="grid__button">
								<div class="button__text">
									<span class="material-icons">add</span> Quick Add
								</div>
							</button> 

							<button title="Wishlist" @click="wishlist($event, game)" class="grid__button grid__wishlist-button">
								<div class="button__text">
									<span v-if="game.wished" class="material-icons favorited">favorite</span>
									<span v-else class="material-icons">favorite_border</span> Wishlist
								</div>
							</button>
		
							<button title="Journal" @click="journalEntry($event, game, index)" class="grid__button grid__play-button">
								<div class="button__text">
									<span v-if="game.game_status" class="material-icons favorited">sports_esports</span>
									<span v-else class="material-icons">sports_esports</span> Journal
								</div>
							</button> 
		
							<button title="Review" @click="reviewEntry($event, game, index)" class="grid__button grid__review-button">
								<div class="button__text">
									<span v-if="game.rating"><i class="fa-solid fa-pen-to-square favorited"></i> Review</span>
									<span v-else><i class="fa-solid fa-pen-to-square"></i>  Review</span>
								</div>
							</button>

					
						</div>

					</div>

					<router-link class="game-card__title game-card__title--hidden" v-if="game.title" :to="{name: 'Game', params:{id: game.id, url: game.slug}}">{{game.title}}</router-link>

					<div v-if="false && game.avg_rating" class="game-card__game-reviews game__reviews">
						<div class="Stars" :style="`--rating: ${(game.avg_rating / 20).toFixed(1)};`"></div>
					</div>

					<div>
						<router-link class="game-card__home-link" v-if="game.title" :to="{name: 'Game', params:{id: game.id, url: game.slug}}">
							<!-- <img :class="{ show: showcover }" class="game-card__image" :src="game.coverArt"> -->
						</router-link>
					</div>			
				</div>
			
		</transition-group>
	</div>

</template>

<script>

import HelperService from '@/services/helperService'
import LibraryService from '@/services/libraryService'
import PlatformService from '@/services/platformService'
import WishlistService from '@/services/wishlistService'
import JournalService from '@/services/journalService'
import ReviewService from '@/services/reviewService'

import QuickAddModalWindow from '@/components/universal/QuickAddModal'
import GameJournalModal from '@/components/universal/GameJournalModal'
import ReviewModal from '@/components/universal/ReviewModal'


export default {
	name: "GridView",
	props: ['games',  'platforms', 'showcover'],
	components:{
		QuickAddModalWindow,
		GameJournalModal,
		ReviewModal,
	},
	data(){
		return{
			show: 'show',

			//user: null,
			quickAddGame: {},
			quickAddPlatforms: {},
			journalGame: {},
			journal: {},
			showModal: false,
			showJournalModal: false,
			gameJournalExists: false,
			journalConfirmText: 'Add',
			event: null,
			isAuthUser: false,
			objIndex: null,

			userRating: null,
			showReviewModal: false,
			reviewGame: {},
			review: {},
			gameReviewExists: false,
			reviewConfirmText: 'Add',
			reviewPrivate: false,

		}
	},
	computed:{
		gameObj(){
			return this.games
		},

		user(){
			return this.$store.state.user
		}
	},
	watch:{
		user(){
			return this.$store.state.user
		}
	},
	async mounted(){
		// this.user = await HelperService.getAuthorizedUser()
		if(this.user.isAuth)
		 	this.isAuthUser = true
	},
	methods:{
		getBackGround(game){
			if(game.coverArt === ''){
				return ``
			} else {
				return `background-image: url('${game.coverArt.replace('.jpg','.webp')}')`
			}
		},

		checkCover(game){
			if(game.coverArt === '/no-cover.png')
				return false
			
			return true
		},

		async quickAdd(event, game){
			this.quickAddGame = game;			
			this.quickAddPlatforms = (await PlatformService.getPlatformAny(`{ ${game.platforms} }`)).data.result
			this.toggleModal();
		},

		async journalEntry($event, game, index){
			this.journalGame = game;
			this.journal = (await JournalService.getAuthJournal(game.id)).data.result
			
			this.gameJournalExists = true
			this.event = $event

			if(this.journal.length === 0){
				this.journal = [{id: null, user_id: null, game_id: null, game_status: 'Played', status_date: null, notes: null}]
				this.gameJournalExists = false
			}

			if(this.gameJournalExists)
				this.journalConfirmText = 'Update'
			else
				this.journalConfirmText = 'Add'

			if(Date.parse(this.journal[0].status_date))
				this.journal[0].status_date = new Date(this.journal[0].status_date).toISOString().split('T')[0]
			else 
				this.journal[0].status_date = null

			this.objIndex = index

			this.toggleJournalModal()
		},

		toggleModal(){
			this.showModal  = !this.showModal
		},

		toggleJournalModal(confirm, event, deleted, objIndex, gameStatus, gameDate, journalPrivate){

			if(confirm)
				event.target.children[0].classList.add("favorited")
			
			if(deleted)
				event.target.children[0].classList.remove("favorited")

			if(confirm || deleted){
				this.gameObj[objIndex].game_status = gameStatus
				this.gameObj[objIndex].journal_private = journalPrivate
			}
			
			this.showJournalModal = !this.showJournalModal
		},

		emittoggleModal(){
			this.toggleModal()
		},

		async wishlist($event, game){
			if(game.wished){
				$event.target.disabled = true
				game.wished = !game.wished
				this.$emit('unWishlist', { id: game.id })
				$event.target.disabled = false
			}
			else{
				$event.target.disabled = true
				game.wished = !game.wished
				let response = await(WishlistService.addWishlist(game))
				$event.target.disabled = false
			}
		},

		async addGame(event, game){
			this.$router.push(`/library/add/${game.id}/${game.slug}`)
		},

		async reviewEntry($event, game, index){
			this.reviewGame = game;
			this.review = (await ReviewService.getAuthReview(game.id)).data.result
			
			this.gameReviewExists = true
			this.event = $event

			if(this.review.length === 0){
				this.review = [{id: null, user_id: null, game_id: null, rating: null, review: null, review_private: null}]
				this.gameReviewExists = false
			}

			if(this.gameReviewExists)
				this.reviewConfirmText = 'Update'
			else
				this.reviewConfirmText = 'Add'

			this.objIndex = index

			this.toggleReviewModal()
		},



		toggleReviewModal(confirm, event, deleted, form, objIndex){

			if(confirm)
				event.target.classList.add("favorited")

			if(deleted)
				event.target.classList.remove("favorited")

			if(confirm || deleted){
				this.gameObj[objIndex].rating = form.rating
				this.gameObj[objIndex].review_private = form.privateRatingCheck
			}

			this.showReviewModal = !this.showReviewModal
		},

		openMenu($event, index){
			const gridMenu = document.getElementsByClassName(`grid__menu-${index}`)
			gridMenu[0].classList.add('grid__menu--show')

			const gridClose = document.getElementsByClassName(`grid__menu-button-close-${index}`)
			gridClose[0].classList.add('grid__menu-button-close--show')
			gridClose[0].classList.add('grid-menu-open')

			$event.target.classList.remove(`grid__menu-button--show`)
		},

		closeMenu($event, index){
			$event.target.classList.remove(`grid__menu-button-close--show`)
			$event.target.parentElement.classList.remove(`grid__menu-button-close--show`)

			$event.target.classList.remove(`grid-menu-open`)
			$event.target.parentElement.classList.remove(`grid-menu-open`)

			const gridMenuButton = document.getElementsByClassName(`grid__menu-button-${index}`)
			gridMenuButton[0].classList.add(`grid__menu-button--show`)

		},

	},
}
</script>