import api from '@/services/api'

class WishlistService{
	static async getWishlist(game_id) {
		return await api().get(`/wishlists/${game_id}`)
	}

	static async getWishlists() {
		return await api().get(`/wishlists`)
	}

	static async addWishlist(game) {
		return await api().post(`/wishlists`, game)
	}

	static async removeWishlist(game) {
		return await api().delete(`/wishlists/${game}`)
	}

	static async getFilter(query, slug) {
		return await api().get(`/wishlists/filter/${slug}?${query}`)
	}

	static async getFilterCount(query, slug) {
		return await api().get(`/wishlists/filter/count/${slug}?${query}`)
	}
}

export default WishlistService
