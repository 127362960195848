import { createRouter, createWebHistory } from "vue-router"

import PageRoutes from './pageRoutes'
import GameRoutes from './gameRoutes'
import UserRoutes from './userRoutes'
import LibraryRoutes from './libraryRoutes'
import WishlistRoutes from './wishlistRoutes'

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes: [
		...GameRoutes,
		...LibraryRoutes,
		...WishlistRoutes,
		...PageRoutes,
		...UserRoutes
	],
	scrollBehavior() {
		document.getElementById("app").scrollIntoView({behavior: "smooth"});
	},
})

router.beforeEach((to, from, next) => {
	if(to.meta.title)
		document.title = `${to.meta.title}`
	else
		document.title = `GameStash - Best way to track your video game collection`
	
	if(to.meta.description)
		document.querySelector('meta[name="description"]').content = `${to.meta.description}`
	else
		document.querySelector('meta[name="description"]').content = `Keep track of your video game collection, catalog any game for any console and platform. The best way to track and catalog your video game library.`
	
	next()

})

export default router
