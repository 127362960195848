<template>
	<div class="modal">
		<div class="modal__window modal__quick-add">
			<!-- <h3>{{ title }}</h3> -->
			<h4>{{ subtitle }}</h4>
			
			<label for="ownership">Status</label>
			<select v-model="form.ownershipInput" name="ownership" id="ownership">
				<option value="Own">Own</option>
				<option value="Rental">Rental</option>
				<option value="Subscription">Subscription</option>
				<option value="Borrowed">Borrowed</option>
				<option value="Sold">Sold</option>
			</select>

			<label for="type">Type</label>
			<select v-model="form.typeInput" name="type" id="type">
				<option value="Physical">Physical</option>
				<option value="Digital">Digital</option>
			</select>

			<label for="platform">Platform</label>
			<select v-model="form.platformInput" v-if="platforms" name="platform" id="platform">
				<option value="0" disabled selected>Select platform</option>
				<option :value="platform.id" :key="platform.id" v-for="platform in platforms">{{platform.platform}}</option>
			</select>

			<label for="region">Region</label>
			<select v-model="form.regionInput" name="region" id="region">
				<option value="North America">North America</option>
				<option value="Europe">Europe</option>
				<option value="Japan">Japan</option>
				<option value="Australia">Australia</option>
				<option value="New Zealand">New Zealand</option>
				<option value="Asia">Asia</option>
				<option value="Korea">Korea</option>
				<option value="China">China</option>
				<option value="South America">South America</option>
				<option value="Worldwide">Worldwide</option>
				<option value="Other">Other</option>
			</select>

			<button id="model-confirm-button" @click="selectConfirm($event, game)">
				<div class="button__loader-container"><div :class="checking && 'loader-show'" class="loader"></div></div>
				<div class="button__text" :class="{'button__text--hidden': checking}">{{ confirmText }}</div>
				</button>
			<button  @click="toggleModal" class="button__flat">{{ cancelText }}</button>
		</div>
		<div @click="toggleModal" class="modal__background"></div>
	</div>
</template>

<script>

import PlatformService from '@/services/platformService'
import LibraryService from '@/services/libraryService'

export default {
	name: 'QuickAddModal',
	props:['title', 'subtitle', 'confirmText', 'cancelText', 'game', 'platforms'],
	
	data(){
		return{
			toggle: true,
			confirm: true,
			checking: false,
			form:{
				ownershipInput: 'Own',
				typeInput: 'Physical',
				platformInput: 0,
				regionInput: 'North America',

			}
		}
	},
	async mounted(){
		
	},
	methods:{
		toggleModal(){
			this.$emit('toggleModal', this.toggle)
		},

		async selectConfirm(event, game){
			event.target.disabled = true
			event.target.childNodes[0].childNodes[0].classList.add('loader-show')
			event.target.childNodes[1].classList.add('button__text--hidden')

			let data = { game: game, form: this.form }
			let response = await LibraryService.addLibraryGame(data)

			setTimeout(function () {
				event.target.disabled = false
				event.target.childNodes[0].childNodes[0].classList.remove('loader-show')
				event.target.childNodes[1].classList.remove('button__text--hidden')
			}, 1000);

			this.toggleModal();
		},
	}

}
</script>